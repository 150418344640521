import React from "react";
import InputMask from "react-input-mask";
import { TextField } from "@mui/material";

export default function TextFieldMask({
  mask,
  value,
  onChange,
  onBlur,
  isDisable = false,
  ...props
}) {
  return (
    <InputMask
      mask={mask}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={isDisable}
    >
      {() => {
        return <TextField {...props} />;
      }}
    </InputMask>
  );
}
