import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useConfigSiteForm } from "../../hooks/pages/useConfigSiteForm";
import ConfigSiteFormData from "./_partials/ConfigSiteFormData";

export default function ConfigSiteEdit() {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    handleColor,
    handleImage,
    imgInstitucionalUrl,
    logoMarcaUrl,
    logoRodapeUrl,
    favIconUrl,
    removeImage
  } = useConfigSiteForm();

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Cadastrar/Editar Configurações do Site"
          subtitle="Bem vindo ao editar configurações do site"
        />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <ConfigSiteFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                handleColor={handleColor}
                handleImage={handleImage}
                imgInstitucionalUrl={imgInstitucionalUrl}
                logoMarcaUrl={logoMarcaUrl}
                logoRodapeUrl={logoRodapeUrl}
                favIconUrl={favIconUrl}
                removeImage={removeImage}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
