import { Box, Card, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../../components/TableComponent";
import SimpleFilterComponent from "../../components/SimpleFilterComponent";
import { useTeam } from "../../hooks/pages/useTeam";
import TeamCreate from "./TeamCreate";
import PaginationComponent from "../../components/PaginationComponent";
import ModalComponent from "../../components/ModalComponent";
import useUserStore from "../../store/userStore";

export default function TeamList() {
  const { colors } = useThemeColor();
  const { permissions } = useUserStore(state => state);
  const {
    results,
    term,
    setTerm,
    handleSearch,
    open,
    handleOpen,
    team,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete
  } = useTeam();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Equipe")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="Times" subtitle="Bem vindo a lista de times" />
      <Card
        sx={{
          backgroundColor: colors.primary[400]
        }}
      >
        <SimpleFilterComponent
          onFilter={permission && permission?.Consultar ? handleSearch : false}
          search={term}
          setSearch={setTerm}
          onAdd={permission && permission?.Inserir ? () => handleOpen() : false}
        />
      </Card>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results?.data || []}
          head={["ID", "NOME"]}
          columns={["Id", "Nome"]}
          handleDelete={
            permission && permission?.Excluir ? item => handleShow(item) : false
          }
          handleEdit={
            permission && permission?.Atualizar
              ? item => handleEdit(item)
              : false
          }
          handleShow={item => handleEdit(item)}
        />

        {results?.total > 0 && (
          <PaginationComponent
            count={results.total}
            page={results.current_page}
            handleLimit={getList}
            handlePage={getList}
          />
        )}
      </Box>

      {open && (
        <TeamCreate
          open={open}
          team={team}
          setOpen={() => handleOpen(false)}
          getList={getList}
        />
      )}

      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          title="Deletar Registro"
          maxWidth={"sm"}
          handleOpen={handleClose}
          handleClick={handleDelete}
          buttonOkColor="redAccent"
        >
          <Typography color="textPrimary" variant="h4">
            Tem certeza que deseja excluir? Esta ação não poderá ser revertida.
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
}
