import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { usePeopleForm } from "../../hooks/pages/usePeopleForm";
import PeopleFormData from "./_partials/PeopleFormData";
import useUserStore from "../../store/userStore";

export default function PeopleCreate() {
  const { permissions } = useUserStore(state => state);
  const { initialValues, validationSchema, onSubmit, estados, handleCep } =
    usePeopleForm();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Pessoa")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Pessoa" subtitle="Bem vindo ao criar pessoa" />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <PeopleFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                estados={estados}
                handleCep={handleCep}
                permission={permission}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
