import { useContext } from "react";
import { Box, Hidden, IconButton } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { ColorModeContext } from "../../../theme";
import { useThemeColor } from "../../../hooks/useThemeColor";
import useSidebarStore from "../../../store/sidebar";
import { useLogout } from "../../../hooks/pages/useLogout";

export default function Topbar() {
  const { visible, setVisible } = useSidebarStore(state => state);
  const { theme } = useThemeColor();
  const colorMode = useContext(ColorModeContext);
  const { handleLogout } = useLogout();

  return (
    <Box display="flex" justifyContent="end" p={2}>
      {/* ICONS */}
      <Box display="flex">
        <Hidden mdUp>
          <IconButton onClick={() => setVisible(!visible)}>
            <MenuOutlinedIcon />
          </IconButton>
        </Hidden>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
