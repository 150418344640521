import React, { useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import ImovelFormData from "./Tabs/ImovelFormData";
import PublicityList from "./Tabs/PublicityList";
import PhotosList from "./Tabs/PhotosList";
import OwnerList from "./Tabs/OwnerList";
import StepperContainer from "./StepperContainer";
import useUserStore from "../../store/userStore";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function ImovelCreate() {
  const { permissions } = useUserStore(state => state);
  const [value, setValue] = useState(0);

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Imovel")[0] ?? null;

  const handleStep = newValue => {
    setValue(newValue);
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Imóvel" subtitle="Bem vindo ao criar imóvel" />
      </Box>
      <Box sx={{ width: "100%" }}>
        <StepperContainer value={value} handleStep={handleStep} />
        <TabPanel value={value} index={0}>
          <ImovelFormData handleStep={handleStep} permission={permission} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PublicityList permission={permission} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OwnerList permission={permission} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PhotosList permission={permission} />
        </TabPanel>
      </Box>
    </Box>
  );
}
