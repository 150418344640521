import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useLogout() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await api.post("/auth/logout");

      enqueueSnackbar("Logout realizado com sucesso", {
        variant: "success"
      });

      sessionStorage.removeItem("authUser");

      setTimeout(() => {
        navigate("/");
      }, 500);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    handleLogout
  };
}
