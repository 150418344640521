import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useThemeColor } from "../../hooks/useThemeColor";

export default function SimpleCollapse({ title, expanded = false, children }) {
  const { colors } = useThemeColor();
  const [exp, setExp] = useState(expanded);

  return (
    <Box backgroundColor={colors.primary[400]}>
      <Accordion
        sx={{ backgroundColor: colors.primary[400] }}
        expanded={exp}
        onChange={() => setExp(!exp)}
      >
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="collapse-content"
          id="collapse-header"
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
}
