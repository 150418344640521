import { create } from "zustand";

const useLoadingStore = create(set => ({
  loadingTable: false,
  loadingCep: false,
  loadingUpload: false,
  loadingDelete: false,
  loadingDefault: false,
  setLoadingTable: status => {
    set({ loadingTable: status });
  },
  setLoadingCep: status => {
    set({ loadingCep: status });
  },
  setLoadingUpload: status => {
    set({ loadingUpload: status });
  },
  setLoadingDelete: status => {
    set({ loadingDelete: status });
  },
  setLoadingDefault: status => {
    set({ loadingDefault: status });
  }
}));

export default useLoadingStore;
