import { useEffect, useState } from "react";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { errorsMessage, parsePageProfileToArray } from "../../helpers";
import { api } from "../../services/api";

export function useProfileForm({ profile, getProfiles, handleClose }) {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    getPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPages = async () => {
    try {
      const { data } = await api.get("/paginas", {
        params: {
          paginate: 0
        }
      });

      setPages(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const initialValues = {
    id: profile?.Id || "",
    Nome: profile?.Nome || "",
    Admin: profile?.Admin || false,
    profilePages:
      profile?.perfilpagina && profile?.perfilpagina?.length > 0
        ? parsePageProfileToArray(profile?.perfilpagina)
        : []
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Informe o nome"),
    Admin: Yup.bool(),
    profilePages: Yup.array()
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id ? `/perfis/${id}` : `/perfis`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getProfiles();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleCheckbox = (event, values, field, setValues) => {
    const items = [...values[field]];
    if (event.target.checked) {
      items.push(event.target.value);
    } else {
      const id = values[field].indexOf(String(event.target.value));
      items.splice(id, 1);
    }
    setValues(field, items);
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    handleCheckbox,
    pages
  };
}
