import {
  Button,
  Grid,
  TextField,
  Box,
  ListItem,
  Chip,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useThemeColor } from "../../../../hooks/useThemeColor";
import SimpleCollapse from "../../../../components/SimpleCollapse";
import GroupRadioComponent from "../../../../components/GroupRadioComponent";
import SelectInputSimple from "../../../../components/SelectInputSimple";
import ReactSelectAsync from "../../../../components/ReactSelectAsync";
import { useImovelFilter } from "../../../../hooks/pages/useImovelFilter";
import TextFieldMoney from "../../../../components/TextFieldMoney";

export default function ImovelFilter({
  onFilter,
  onAdd,
  idtipoimovel,
  idtransacao,
  condominiofechado,
  idtopografia,
  valorinicial,
  valorfinal,
  areaconstruidainicial,
  areaconstruidafinal,
  areaterrenoinicial,
  areaterrenofinal,
  areautilinicial,
  areautilfinal,
  dormitorios,
  suites,
  vagas,
  banheiros,
  areagalpaoinicial,
  areagalpaofinal,
  areaescritorioinicial,
  areaescritoriofinal,
  areafabrilinicial,
  valormetroinicial,
  valormetrofinal,
  setIdtipoimovel,
  setIdtransacao,
  setCondominiofechado,
  setIdtopografia,
  setValorinicial,
  setValorfinal,
  setAreaconstruidainicial,
  setAreaconstruidafinal,
  setAreaterrenoinicial,
  setAreaterrenofinal,
  setAreautilinicial,
  setAreautilfinal,
  setDormitorios,
  setSuites,
  setVagas,
  setBanheiros,
  setAreagalpaoinicial,
  setAreagalpaofinal,
  setAreaescritorioinicial,
  setAreaescritoriofinal,
  setAreafabrilinicial,
  setValormetroinicial,
  setValormetrofinal,
  areafabrilfinal,
  setAreafabrilfinal,
  bairros,
  handleDeleteItem,
  onChangeSpeaker,
  handleSelectSpeaker,
  idFilial,
  setIdFilial,
  somenteMatriz,
  setSomenteMatriz,
  referencia,
  setReferencia,
  grupo,
  setGrupo,
  IdUnidade,
  setIdUnidade,
  IdObra,
  setIdObra,
  IdSituacao,
  setIdSituacao,
  permuta,
  setPermuta,
  Mcmv,
  setMcmv,
  setEmptyValues,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
  oferta,
  setOferta,
  IdAssinatura,
  setIdAssinatura,
  comFoto,
  semFoto,
  setComFoto,
  setSemFoto,
  banner,
  setBanner,
  destaque,
  setDestaque,
  superDestaque,
  setSuperDestaque,
  lancamento,
  setLancamento
}) {
  const { colors } = useThemeColor();
  const { tiposImovel, filiais, gruposImovel, unidades, assinaturas } =
    useImovelFilter();

  return (
    <Grid container spacing={2} style={{ padding: 16 }}>
      <Grid item md={12} xs={12}>
        <ReactSelectAsync
          handleChange={handleSelectSpeaker}
          handleInputChange={onChangeSpeaker}
          placeholder={"Pesquisar bairros"}
          value={null}
        />
      </Grid>
      <Box
        sx={{
          display: "flex"
        }}
      >
        {bairros.map(item => (
          <ListItem key={item.key}>
            <Chip label={item.name} onDelete={() => handleDeleteItem(item)} />
          </ListItem>
        ))}
      </Box>

      <Grid item md={12} style={{ padding: 0 }}>
        <SimpleCollapse
          title={
            <Button
              sx={{
                backgroundColor: colors.primary[900],
                color: colors.grey[100],
                margin: "0 1rem"
              }}
            >
              Mais filtros
            </Button>
          }
        >
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <SelectInputSimple
                label="Grupo"
                labelId={"grupo"}
                name={"grupo"}
                value={grupo}
                onChange={event => setGrupo(event.target.value)}
                options={gruposImovel}
                classHelp={{ paddingTop: 0 }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <SelectInputSimple
                label="Tipo do Imóvel"
                labelId={"idtipoimovel"}
                name={"idtipoimovel"}
                value={idtipoimovel}
                onChange={event => setIdtipoimovel(event.target.value)}
                options={tiposImovel}
                classHelp={{ paddingTop: 0 }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <SelectInputSimple
                label="Finalidade"
                labelId={"idtransacao"}
                name={"idtransacao"}
                value={idtransacao}
                onChange={event => setIdtransacao(event.target.value)}
                options={[
                  { Id: "1", Nome: "Alugar" },
                  { Id: "2", Nome: "Vender" },
                  { Id: "4", Nome: "Temporada" }
                ]}
                classHelp={{ paddingTop: 0 }}
              />
            </Grid>

            {unidades?.length > 0 && (
              <Grid item md={3} xs={12}>
                <SelectInputSimple
                  label="Empreendimento"
                  labelId={"IdUnidade"}
                  name={"IdUnidade"}
                  value={IdUnidade}
                  onChange={event => setIdUnidade(event.target.value)}
                  options={unidades}
                  classHelp={{ paddingTop: 0 }}
                />
              </Grid>
            )}

            <Grid item md={3} xs={12}>
              <SelectInputSimple
                label="Obra"
                labelId={"IdObra"}
                name={"IdObra"}
                value={IdObra}
                onChange={event => setIdObra(event.target.value)}
                options={[
                  { Id: "1", Nome: "Pronta" },
                  { Id: "2", Nome: "Construção" },
                  { Id: "3", Nome: "Parada" },
                  { Id: "4", Nome: "Na Planta" }
                ]}
                classHelp={{ paddingTop: 0 }}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <SelectInputSimple
                label="Situação do Imóvel"
                labelId={"IdSituacao"}
                name={"IdSituacao"}
                value={IdSituacao}
                onChange={event => setIdSituacao(event.target.value)}
                options={[
                  { Id: "1", Nome: "Ativo" },
                  { Id: "2", Nome: "Alugado" },
                  { Id: "3", Nome: "Vendido" },
                  { Id: "4", Nome: "Administrado" },
                  { Id: "5", Nome: "Suspenso" }
                ]}
                classHelp={{ paddingTop: 0 }}
              />
            </Grid>

            {["Terreno"].includes(String(grupo)) && (
              <Grid item md={3} sm={12}>
                <SelectInputSimple
                  label="Topografia"
                  labelId={"idtopografia"}
                  name={"idtopografia"}
                  value={idtopografia}
                  onChange={event => setIdtopografia(event.target.value)}
                  options={[
                    { Id: "0", Nome: "Aclive" },
                    { Id: "1", Nome: "Declive" },
                    { Id: "2", Nome: "Plano" }
                  ]}
                  classHelp={{ paddingTop: 0 }}
                />
              </Grid>
            )}
            <Grid item md={2} sm={12}>
              <TextFieldMoney
                fullWidth
                name="valorinicial"
                value={valorinicial}
                placeholder={"Preço Mínimo"}
                onChange={event => setValorinicial(event)}
              />
            </Grid>
            <Grid item md={2} sm={12}>
              <TextFieldMoney
                fullWidth
                name="valorfinal"
                value={valorfinal}
                placeholder={"Preço Máximo"}
                onChange={event => setValorfinal(event)}
              />
            </Grid>
            {["Residencial", "Rural", "Galpao"].includes(String(grupo)) && (
              <>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areaconstruidainicial}
                    name={"areaconstruidainicial"}
                    onChange={event =>
                      setAreaconstruidainicial(event.target.value)
                    }
                    placeholder={"Área de Construída Mínimo"}
                    fullWidth
                  />
                </Grid>

                <Grid item md={2} sm={12}>
                  <TextField
                    value={areaconstruidafinal}
                    name={"areaconstruidafinal"}
                    onChange={event =>
                      setAreaconstruidafinal(event.target.value)
                    }
                    placeholder={"Área de Construída Máximo"}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {["Residencial", "Comercial", "Rural", "Terreno"].includes(
              idtipoimovel
            ) && (
              <>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areaterrenoinicial}
                    name={"areaterrenoinicial"}
                    onChange={event =>
                      setAreaterrenoinicial(event.target.value)
                    }
                    placeholder={"Área de Terreno Mínimo"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areaterrenofinal}
                    name={"areaterrenofinal"}
                    onChange={event => setAreaterrenofinal(event.target.value)}
                    placeholder={"Área de Terreno Máximo"}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {["Residencial", "Comercial"].includes(String(grupo)) && (
              <>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areautilinicial}
                    name={"areautilinicial"}
                    onChange={event => setAreautilinicial(event.target.value)}
                    placeholder={"Área Útil Mínimo"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areautilfinal}
                    name={"areautilfinal"}
                    onChange={event => setAreautilfinal(event.target.value)}
                    placeholder={"Área Útil Máximo"}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {["Galpao"].includes(String(grupo)) && (
              <>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areagalpaoinicial}
                    name={"areagalpaoinicial"}
                    onChange={event => setAreagalpaoinicial(event.target.value)}
                    placeholder={"Área de Galpão Mínimo"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areagalpaofinal}
                    name={"areagalpaofinal"}
                    onChange={event => setAreagalpaofinal(event.target.value)}
                    placeholder={"Área de Galpão Máximo"}
                    fullWidth
                  />
                </Grid>

                <Grid item md={2} sm={12}>
                  <TextField
                    value={areaescritorioinicial}
                    name={"areaescritorioinicial"}
                    onChange={event =>
                      setAreaescritorioinicial(event.target.value)
                    }
                    placeholder={"Área de Escritório Mínimo"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areaescritoriofinal}
                    name={"areaescritoriofinal"}
                    onChange={event =>
                      setAreaescritoriofinal(event.target.value)
                    }
                    placeholder={"Área de Escritório Máximo"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areafabrilinicial}
                    name={"areafabrilinicial"}
                    onChange={event => setAreafabrilinicial(event.target.value)}
                    placeholder={"Área de Fábril Mínimo"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={areafabrilfinal}
                    name={"areafabrilfinal"}
                    onChange={event => setAreafabrilfinal(event.target.value)}
                    placeholder={"Área de Fábril Máximo"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={valormetroinicial}
                    name={"valormetroinicial"}
                    onChange={event => setValormetroinicial(event.target.value)}
                    placeholder={"Valor por Metro Mínimo"}
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextField
                    value={valormetrofinal}
                    name={"valormetrofinal"}
                    onChange={event => setValormetrofinal(event.target.value)}
                    placeholder={"Valor por Metro Máximo"}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            <Grid item md={2} sm={12}>
              <TextField
                value={referencia}
                name={"referencia"}
                onChange={event => setReferencia(event.target.value)}
                placeholder={"Referência"}
                fullWidth
                onKeyUp={event => {
                  if (event.key === "Enter") {
                    onFilter && onFilter();
                  }
                }}
              />
            </Grid>

            {assinaturas?.length > 0 && (
              <Grid item md={3} xs={12}>
                <SelectInputSimple
                  label="Publicado em"
                  labelId={"IdAssinatura"}
                  name={"IdAssinatura"}
                  value={IdAssinatura}
                  onChange={event => setIdAssinatura(event.target.value)}
                  options={assinaturas}
                  classHelp={{ paddingTop: 0 }}
                />
              </Grid>
            )}

            {filiais?.length > 0 && (
              <Grid item md={3} xs={12}>
                <SelectInputSimple
                  label="Filial"
                  labelId={"IdFilial"}
                  name={"IdFilial"}
                  value={idFilial}
                  onChange={event => setIdFilial(event.target.value)}
                  options={filiais}
                  optionTitle="NomeFantasia"
                  classHelp={{ paddingTop: 0 }}
                />
              </Grid>
            )}

            <Grid item md={2} sm={12}>
              <FormControl component="fieldset">
                <FormGroup style={{ flexDirection: "row" }}>
                  <FormControlLabel
                    label={"Somente Matriz"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="somenteMatriz"
                        onChange={event =>
                          setSomenteMatriz(event.target.checked)
                        }
                        checked={Boolean(somenteMatriz)}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {["Residencial", "Rural"].includes(String(grupo)) && (
              <Grid item md={12}>
                <Grid container>
                  <Grid item md={3} sm={12}>
                    <GroupRadioComponent
                      arrayNumbers={[1, 2, 3, 4, 5]}
                      label={"Dormitórios"}
                      name={"dormitorios"}
                      value={dormitorios}
                      onChange={event => setDormitorios(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={3} sm={12}>
                    <GroupRadioComponent
                      arrayNumbers={[1, 2, 3, 4, 5]}
                      label={"Suites"}
                      name={"suites"}
                      value={suites}
                      onChange={event => setSuites(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={3} sm={12}>
                    <GroupRadioComponent
                      arrayNumbers={[1, 2, 3, 4, 5]}
                      label={"Vagas"}
                      name={"vagas"}
                      value={vagas}
                      onChange={event => setVagas(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={3} sm={12}>
                    <GroupRadioComponent
                      arrayNumbers={[1, 2, 3, 4, 5]}
                      label={"Banheiros"}
                      name={"banheiros"}
                      value={banheiros}
                      onChange={event => setBanheiros(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item md={12}>
              <FormControl component="fieldset">
                <FormGroup style={{ flexDirection: "row" }}>
                  <FormControlLabel
                    label={"Aceita Permuta"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="permuta"
                        onChange={event => setPermuta(event.target.checked)}
                        checked={Boolean(permuta)}
                      />
                    }
                  />

                  <FormControlLabel
                    label={"Minha Casa Minha Vida"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Mcmv"
                        onChange={event => setMcmv(event.target.checked)}
                        checked={Boolean(Mcmv)}
                      />
                    }
                  />

                  <FormControlLabel
                    label={"Condomínio Fechado"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="condominiofechado"
                        onChange={event =>
                          setCondominiofechado(event.target.checked)
                        }
                        checked={Boolean(condominiofechado)}
                      />
                    }
                  />
                  <FormControlLabel
                    label={"Em Oferta"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="oferta"
                        onChange={event => setOferta(event.target.checked)}
                        checked={Boolean(oferta)}
                      />
                    }
                  />
                  <FormControlLabel
                    label={"Banner"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="banner"
                        onChange={event => setBanner(event.target.checked)}
                        checked={Boolean(banner)}
                      />
                    }
                  />

                  <FormControlLabel
                    label={"Destaque"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="destaque"
                        onChange={event => setDestaque(event.target.checked)}
                        checked={Boolean(destaque)}
                      />
                    }
                  />

                  <FormControlLabel
                    label={"Super Destaque"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="superDestaque"
                        onChange={event =>
                          setSuperDestaque(event.target.checked)
                        }
                        checked={Boolean(superDestaque)}
                      />
                    }
                  />

                  <FormControlLabel
                    label={"Lançamento"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="lancamento"
                        onChange={event => setLancamento(event.target.checked)}
                        checked={Boolean(lancamento)}
                      />
                    }
                  />

                  <FormControlLabel
                    label={"Com Foto"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="comFoto"
                        onChange={event => {
                          setComFoto(event.target.checked);
                          setSemFoto(false);
                        }}
                        checked={Boolean(comFoto)}
                      />
                    }
                  />
                  <FormControlLabel
                    label={"Sem Foto"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="semFoto"
                        onChange={event => {
                          setSemFoto(event.target.checked);
                          setComFoto(false);
                        }}
                        checked={Boolean(semFoto)}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    label="Data Cadastro Inicial"
                    value={dateStart}
                    onChange={newValue => setDateStart(newValue)}
                  />
                  <DatePicker
                    label="Data Cadastro Final"
                    value={dateEnd}
                    onChange={newValue => setDateEnd(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </SimpleCollapse>
      </Grid>
      <Grid item md={12}>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          {setEmptyValues && (
            <Button
              sx={{
                backgroundColor: colors.grey[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={setEmptyValues}
            >
              Limpar
            </Button>
          )}

          {onFilter && (
            <Button
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={onFilter}
            >
              Pesquisar
            </Button>
          )}

          {onAdd && (
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={onAdd}
            >
              Adicionar
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
