import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import useLoadingStore from "../../store/loading";
import { api } from "../../services/api";
import useModalStore from "../../store/modalStore";

export function usePhoto(props) {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [results, setResults] = useState();
  const [photo, setPhoto] = useState({});
  const { setLoadingTable, loadingDelete, setLoadingDelete } = useLoadingStore(
    state => state
  );
  const { modalOpen, showModal, hideModal } = useModalStore(state => state);
  const id = props?.imovelId || null;

  useEffect(() => {
    if (id) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getList = async () => {
    try {
      setLoadingTable(true);

      const { data } = await api.get(`/imoveis/${id}`);

      setResults(data?.data?.fotos || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingTable(false);
    }
  };

  const handleOpen = (status = true) => {
    setOpen(status);
    setOpenEdit(false);
    if (!status) setPhoto({});
  };

  const handleOpenEdit = (status = true) => {
    setOpenEdit(status);
    setOpen(false);
    if (!status) setPhoto({});
  };

  const handleEdit = item => {
    setPhoto(item);
    handleOpenEdit();
  };

  const handleShow = item => {
    setPhoto(item);
    showModal();
  };

  const handleClose = () => {
    setPhoto(null);
    hideModal();
  };

  const handleDelete = async () => {
    try {
      setLoadingDelete(true);

      await api.delete(`/imoveis/fotos/${id}/destroy-fotos/${photo.Id}`);

      setLoadingDelete(false);
      handleClose();
      getList();
      enqueueSnackbar("Registro deletado com sucesso", {
        variant: "success"
      });
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingDelete(false);
    }
  };

  const onDragEnd = async result => {
    if (!result.destination) return;

    const items = Array.from(results || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    items.forEach(item => {
      if (item.Id === reorderedItem.Id) {
        item.Ordem = result.destination.index;
      }
    });

    setResults(items);
  };

  const handleReorder = async () => {
    if (!results.length) return;

    try {
      const items = Array.from(results || []);

      const formData = {
        photo_ids: items.map((item, index) => {
          return { Id: item.Id, Ordem: index };
        })
      };

      await api.put(`/imoveis/fotos/${id}/update-fotos-order`, formData);

      enqueueSnackbar("Imagens reordenada com sucesso", {
        variant: "success"
      });

      getList();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    results,
    setResults,
    open,
    openEdit,
    handleOpen,
    photo,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    hideModal,
    handleClose,
    handleDelete,
    loadingDelete,
    handleOpenEdit,
    onDragEnd,
    handleReorder
  };
}
