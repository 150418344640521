import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import useLoadingStore from "../../store/loading";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function useDashboard() {
  const [results, setResults] = useState();
  const { setLoadingTable } = useLoadingStore(state => state);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async () => {
    try {
      setLoadingTable(true);

      const { data } = await api.get(`/dashboard`);

      setResults(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingTable(false);
    }
  };

  return {
    results,
    getList
  };
}
