import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { useUserProfile } from "../useUserProfile";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function useContractorForm() {
  const {
    user: { contratante }
  } = useUserProfile();
  const [photo_url, setPhotoUrl] = useState(null);
  const [estados, setEstados] = useState([]);

  const getEstados = async () => {
    try {
      const { data } = await api.get("/estados", {
        params: {
          paginate: 0
        }
      });

      setEstados(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getEstados();
  }, []);

  useEffect(() => {
    if (contratante?.foto_url) {
      setPhotoUrl(contratante?.foto_url || null);
    }
  }, [contratante?.foto_url]);

  const initialValues = {
    id: contratante?.Id || "",
    RazaoSocial: contratante?.RazaoSocial || "",
    NomeFantasia: contratante?.NomeFantasia || "",
    CNPJ: contratante?.CNPJ || "",
    InscricaoEstadual: contratante?.InscricaoEstadual || "",
    WebSite: contratante?.WebSite || "",
    Registro: contratante?.Registro || "",
    IdEstado: contratante?.IdEstado || "",
    TipoEndereco: contratante?.TipoEndereco || "",
    Endereco: contratante?.Endereco || "",
    Numero: contratante?.Numero || "",
    Complemento: contratante?.Complemento || "",
    Bairro: contratante?.Bairro || "",
    Cidade: contratante?.Cidade || "",
    Cep: contratante?.Cep || "",
    Contato: contratante?.Contato || "",
    Email: contratante?.Email || "",
    Telefone: contratante?.Telefone || "",
    Mensagem: contratante?.Mensagem || "",
    Observacao: contratante?.Observacao || "",
    Logotipo: contratante?.Logotipo || false,
    TipoPessoa: contratante?.TipoPessoa || "PJ"
  };

  const validationSchema = Yup.object().shape({
    // RazaoSocial: Yup.string().required("Este campo é obrigatório"),
    // NomeFantasia: Yup.string().required("Este campo é obrigatório"),
    // CNPJ: Yup.string().required("Este campo é obrigatório"),
    IdEstado: Yup.string().required("Este campo é obrigatório"),
    TipoEndereco: Yup.string().required("Este campo é obrigatório"),
    Endereco: Yup.string().required("Este campo é obrigatório"),
    Numero: Yup.string().required("Este campo é obrigatório"),
    Bairro: Yup.string().required("Este campo é obrigatório"),
    Cidade: Yup.string().required("Este campo é obrigatório"),
    Cep: Yup.string().required("Este campo é obrigatório"),
    Contato: Yup.string().required("Este campo é obrigatório"),
    Email: Yup.string().required("Este campo é obrigatório"),
    Telefone: Yup.string().required("Este campo é obrigatório"),
    Mensagem: Yup.string().required("Este campo é obrigatório")
  });

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      setSubmitting(true);

      const { id, Logotipo, ...formData } = values;

      const path = `/contratantes/${id}`;
      formData["_method"] = "PUT";

      if (values.Logotipo && values.Logotipo instanceof File && Logotipo) {
        formData.Logotipo = Logotipo;
      }

      await api.post(path, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      setStatus({ success: true });
      setSubmitting(false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleImage = (files, field, setValues) => {
    if (files.length > 0) {
      setValues(field, files[0], false);
      setPhotoUrl(URL.createObjectURL(files[0]));
    }
  };

  const removeImage = (field, setValues) => {
    setValues(field, false, false);
    setPhotoUrl(null);
  };

  const handleCep = async (values, setValues) => {
    try {
      const cep = values.Cep.replace(/\D/g, "");

      if (cep.length < 8) {
        enqueueSnackbar("Por favor informe o cep corretamente", {
          variant: "error"
        });
        return;
      }

      const { data } = await api.get(`/endereco/cep?cep=${cep}`);

      setValues("Endereco", data?.logradouro, false);
      setValues("Cidade", data?.localidade, false);
      setValues("Bairro", data?.bairro, false);
      setValues("TipoEndereco", data?.type, false);
      setValues("IdEstado", data?.IdEstado, false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    handleImage,
    removeImage,
    photo_url,
    estados,
    handleCep
  };
}
