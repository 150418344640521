import {
  Button,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Box
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useThemeColor } from "../../../hooks/useThemeColor";
import SelectInputSimple from "../../../components/SelectInputSimple";
import { typesCollaborator } from "../../../helpers";

export default function CollaboratorFilter({
  search,
  setSearch,
  onFilter,
  teams,
  pessoas,
  onAdd,
  IdEquipe,
  setIdEquipe,
  IdPessoa,
  setIdPessoa,
  IdTipoColaborador,
  setIdTipoColaborador
}) {
  const { colors } = useThemeColor();

  const handleChange = event => {
    event.persist();
    setSearch(event.target.value);
  };

  return (
    <Grid container spacing={3} style={{ padding: 16 }}>
      <Grid item md={3} sm={12}>
        <TextField
          value={search}
          onChange={handleChange}
          placeholder={"Pesquisar"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onKeyUp={event => {
            if (event.key === "Enter") {
              onFilter();
            }
          }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <SelectInputSimple
          label="Tipo Colaborador"
          labelId={"IdTipoColaborador"}
          name={"IdTipoColaborador"}
          value={IdTipoColaborador}
          onChange={event => setIdTipoColaborador(event.target.value)}
          options={typesCollaborator}
          optionTitle="Nome"
          classHelp={{ paddingTop: 0 }}
        />
      </Grid>
      {teams?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Equipe"
            labelId={"IdEquipe"}
            name={"IdEquipe"}
            value={IdEquipe}
            onChange={event => setIdEquipe(event.target.value)}
            options={teams}
            optionTitle="Nome"
            classHelp={{ paddingTop: 0 }}
          />
        </Grid>
      )}
      {pessoas?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Pessoa"
            labelId={"IdPessoa"}
            name={"IdPessoa"}
            value={IdPessoa}
            onChange={event => setIdPessoa(event.target.value)}
            options={pessoas}
            optionTitle="Nome"
            classHelp={{ paddingTop: 0 }}
          />
        </Grid>
      )}
      <Grid item md={12} sm={12}>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          {onFilter && (
            <Button
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={onFilter}
            >
              Pesquisar
            </Button>
          )}

          {onAdd && (
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={onAdd}
            >
              Adicionar
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
