import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import SelectInputSimple from "../../../../components/SelectInputSimple";
import { useThemeColor } from "../../../../hooks/useThemeColor";

export default function Adittional({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  colaboradores,
  filiais,
  lastImovel,
  setFieldValue
}) {
  const { colors } = useThemeColor();

  return (
    <React.Fragment>
      <Grid item md={12}>
        <Typography variant="h4">Publicidade</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Titulo"
          name="TituloWeb"
          value={values.TituloWeb}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.TituloWeb && errors.TituloWeb}
          error={Boolean(touched.TituloWeb && errors.TituloWeb)}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <InputLabel style={{ color: colors.grey[100] }} id={"AdmImoveis"}>
          Descrição do Imóvel no Site
        </InputLabel>
        <CKEditor
          id="DescricaoSite"
          name="DescricaoSite"
          editor={ClassicEditor}
          data={values.DescricaoSite}
          onReady={editor => editor.setData(values.DescricaoSite)}
          onChange={(event, editor) =>
            setFieldValue("DescricaoSite", editor.getData())
          }
        />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">Controle de Chaves</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Chaves"
          name="Chaves"
          value={values.Chaves}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Chaves && errors.Chaves}
          error={Boolean(touched.Chaves && errors.Chaves)}
        />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">Informações Adicionais</Typography>
      </Grid>
      {colaboradores?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Captador"
            labelId={"IdCaptador"}
            name={"IdCaptador"}
            value={values.IdCaptador}
            onChange={handleChange}
            helperText={touched.IdCaptador && errors.IdCaptador}
            error={Boolean(touched.IdCaptador && errors.IdCaptador)}
            options={colaboradores}
          />
        </Grid>
      )}
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          type="date"
          label="Data da Captação"
          name="DataCaptacao"
          value={values.DataCaptacao}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.DataCaptacao && errors.DataCaptacao}
          error={Boolean(touched.DataCaptacao && errors.DataCaptacao)}
        />
      </Grid>
      {colaboradores?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Participante"
            labelId={"IdParticipante"}
            name={"IdParticipante"}
            value={values.IdParticipante}
            onChange={handleChange}
            helperText={touched.IdParticipante && errors.IdParticipante}
            error={Boolean(touched.IdParticipante && errors.IdParticipante)}
            options={colaboradores}
          />
        </Grid>
      )}
      {colaboradores?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Placa por"
            labelId={"IdPlaca"}
            name={"IdPlaca"}
            value={values.IdPlaca}
            onChange={handleChange}
            helperText={touched.IdPlaca && errors.IdPlaca}
            error={Boolean(touched.IdPlaca && errors.IdPlaca)}
            options={colaboradores}
          />
        </Grid>
      )}
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          type="date"
          label="Data da Placa"
          name="DataPlaca"
          value={values.DataPlaca}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.DataPlaca && errors.DataPlaca}
          error={Boolean(touched.DataPlaca && errors.DataPlaca)}
        />
      </Grid>
      {colaboradores?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Atualizado por"
            labelId={"IdAtualizador"}
            name={"IdAtualizador"}
            value={values.IdAtualizador}
            onChange={handleChange}
            helperText={touched.IdAtualizador && errors.IdAtualizador}
            error={Boolean(touched.IdAtualizador && errors.IdAtualizador)}
            options={colaboradores}
          />
        </Grid>
      )}
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          type="date"
          label="Fim da Locação"
          name="FimLocacao"
          value={values.FimLocacao}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.FimLocacao && errors.FimLocacao}
          error={Boolean(touched.FimLocacao && errors.FimLocacao)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Ano Contrução"
          name="Idade"
          type="number"
          value={values.Idade}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Idade && errors.Idade}
          error={Boolean(touched.Idade && errors.Idade)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Código"
          value={values.id || "0"}
          disabled={isSubmitting}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography>
          {`Última Referência cadastrada: ${
            lastImovel?.Referencia || lastImovel?.Id || ""
          }`}
        </Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Referência"
          name="Referencia"
          value={values.Referencia}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Referencia && errors.Referencia}
          error={Boolean(touched.Referencia && errors.Referencia)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <SelectInputSimple
          label="Situação"
          labelId={"IdSituacao"}
          name={"IdSituacao"}
          value={values.IdSituacao}
          onChange={handleChange}
          helperText={touched.IdSituacao && errors.IdSituacao}
          error={Boolean(touched.IdSituacao && errors.IdSituacao)}
          options={[
            { Id: "1", Nome: "Ativo" },
            { Id: "2", Nome: "Alugado" },
            { Id: "3", Nome: "Vendido" },
            { Id: "4", Nome: "Administrado" },
            { Id: "5", Nome: "Suspenso" }
          ]}
        />
      </Grid>
      {filiais?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Filial"
            labelId={"IdFilial"}
            name={"IdFilial"}
            value={values.IdFilial}
            onChange={handleChange}
            helperText={touched.IdFilial && errors.IdFilial}
            error={Boolean(touched.IdFilial && errors.IdFilial)}
            options={filiais}
            optionTitle="NomeFantasia"
          />
        </Grid>
      )}
    </React.Fragment>
  );
}
