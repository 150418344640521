import React from "react";
import { Grid, TextField } from "@mui/material";

export default function Residential02({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  tipoImovelSelected
}) {
  return (
    <React.Fragment>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Living"
          name="Living"
          value={values.Living}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Living && errors.Living}
          error={Boolean(touched.Living && errors.Living)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Dormitório"
          name="Dormitorio"
          value={values.Dormitorio}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Dormitorio && errors.Dormitorio}
          error={Boolean(touched.Dormitorio && errors.Dormitorio)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Dorm Empregada"
          name="DormEmpregada"
          value={values.DormEmpregada}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.DormEmpregada && errors.DormEmpregada}
          error={Boolean(touched.DormEmpregada && errors.DormEmpregada)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Suite"
          name="Suite"
          value={values.Suite}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Suite && errors.Suite}
          error={Boolean(touched.Suite && errors.Suite)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Suite Master"
          name="SuiteMaster"
          value={values.SuiteMaster}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.SuiteMaster && errors.SuiteMaster}
          error={Boolean(touched.SuiteMaster && errors.SuiteMaster)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Wc Social"
          name="WcSocial"
          value={values.WcSocial}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.WcSocial && errors.WcSocial}
          error={Boolean(touched.WcSocial && errors.WcSocial)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Wc Empregada"
          name="WcEmpregada"
          value={values.WcEmpregada}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.WcEmpregada && errors.WcEmpregada}
          error={Boolean(touched.WcEmpregada && errors.WcEmpregada)}
        />
      </Grid>
    </React.Fragment>
  );
}
