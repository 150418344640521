import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function useImovelFilter() {
  const [tiposImovel, setTiposImovel] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const [gruposImovel, setGruposImovel] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [assinaturas, setAssinaturas] = useState([]);

  useEffect(() => {
    getFiliais();
    getGruposImovel();
    getTiposImovel();
    getUnidades();
    getAssinaturas();
  }, []);

  const getTiposImovel = async () => {
    try {
      const { data } = await api.get("/tipoimoveis/get-tipos-imovel", {
        params: {
          paginate: 0
        }
      });

      setTiposImovel(
        data?.data?.map(t => {
          return {
            ...t,
            Id: `${t.SubTipoSGI}`,
            Nome: `${t.SubTipoSGI}`
          };
        }) || []
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getGruposImovel = async () => {
    try {
      const { data } = await api.get("/tipoimoveis/get-grupos-imovel");

      setGruposImovel(
        data?.data?.map(t => {
          return {
            ...t,
            Id: t.GrupoId
          };
        }) || []
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getFiliais = async () => {
    try {
      const { data } = await api.get("/filiais", {
        params: {
          paginate: 0
        }
      });

      setFiliais(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getUnidades = async () => {
    try {
      const { data } = await api.get("/unidades", {
        params: {
          paginate: 0
        }
      });

      setUnidades(
        data?.data?.map(i => {
          return {
            ...i,
            Nome: `${i.Nome} / ${i?.empreendimento?.Nome}`
          };
        }) || []
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getAssinaturas = async () => {
    try {
      const { data } = await api.get("/assinaturas", {
        params: {
          paginate: 0
        }
      });

      setAssinaturas(
        data?.data?.map(a => {
          return {
            ...a,
            Nome: a.portal?.Nome
          };
        }) || []
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    tiposImovel,
    filiais,
    gruposImovel,
    unidades,
    assinaturas
  };
}
