import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import "moment/locale/pt-br";
import { errorsMessage } from "../../helpers";
import useLoadingStore from "../../store/loading";
import useModalStore from "../../store/modalStore";
import { api } from "../../services/api";

export function useImovel() {
  const [results, setResults] = useState();
  const [item, setItem] = useState(null);
  const [term, setTerm] = useState("");
  const [idtipoimovel, setIdtipoimovel] = useState("");
  const [referencia, setReferencia] = useState("");
  const [idtransacao, setIdtransacao] = useState("");
  const [condominiofechado, setCondominiofechado] = useState(false);
  const [idtopografia, setIdtopografia] = useState("");
  const [valorinicial, setValorinicial] = useState("");
  const [valorfinal, setValorfinal] = useState("");
  const [areaconstruidainicial, setAreaconstruidainicial] = useState("");
  const [areaconstruidafinal, setAreaconstruidafinal] = useState("");
  const [areaterrenoinicial, setAreaterrenoinicial] = useState("");
  const [areaterrenofinal, setAreaterrenofinal] = useState("");
  const [areautilinicial, setAreautilinicial] = useState("");
  const [areautilfinal, setAreautilfinal] = useState("");
  const [dormitorios, setDormitorios] = useState("");
  const [suites, setSuites] = useState("");
  const [vagas, setVagas] = useState("");
  const [banheiros, setBanheiros] = useState("");
  const [areagalpaoinicial, setAreagalpaoinicial] = useState("");
  const [areagalpaofinal, setAreagalpaofinal] = useState("");
  const [areaescritorioinicial, setAreaescritorioinicial] = useState("");
  const [areaescritoriofinal, setAreaescritoriofinal] = useState("");
  const [areafabrilinicial, setAreafabrilinicial] = useState("");
  const [areafabrilfinal, setAreafabrilfinal] = useState("");
  const [valormetroinicial, setValormetroinicial] = useState("");
  const [valormetrofinal, setValormetrofinal] = useState("");
  const [bairros, setBairros] = useState([]);
  const [idFilial, setIdFilial] = useState("");
  const [IdUnidade, setIdUnidade] = useState("");
  const [IdObra, setIdObra] = useState("");
  const [grupo, setGrupo] = useState("");
  const [IdSituacao, setIdSituacao] = useState("1");
  const [permuta, setPermuta] = useState(false);
  const [oferta, setOferta] = useState(false);
  const [Mcmv, setMcmv] = useState(false);
  const [somenteMatriz, setSomenteMatriz] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [IdAssinatura, setIdAssinatura] = useState("");
  const [comFoto, setComFoto] = useState(false);
  const [semFoto, setSemFoto] = useState(false);
  const [banner, setBanner] = useState(false);
  const [destaque, setDestaque] = useState(false);
  const [superDestaque, setSuperDestaque] = useState(false);
  const [lancamento, setLancamento] = useState(false);
  const { setLoadingTable } = useLoadingStore(state => state);
  const { modalOpen, showModal, hideModal } = useModalStore(state => state);

  const handleFilter = () => {
    const filters = {};
    if (term) filters.term = term;

    if (referencia) {
      filters.referencia = referencia;
      return filters;
    }

    if (bairros.length > 0) filters.bairros = bairros;
    if (idtipoimovel) filters.idtipoimovel = idtipoimovel;
    filters.idtransacao = idtransacao;
    if (condominiofechado) filters.condominiofechado = condominiofechado;
    if (idtopografia) filters.idtopografia = idtopografia;
    if (valorinicial && Number.parseFloat(valorinicial) > 0)
      filters.valorinicial = valorinicial;
    if (valorfinal && Number.parseFloat(valorfinal) > 0)
      filters.valorfinal = valorfinal;
    if (areaconstruidainicial)
      filters.areaconstruidainicial = areaconstruidainicial;
    if (areaconstruidafinal) filters.areaconstruidafinal = areaconstruidafinal;
    if (areaterrenoinicial) filters.areaterrenoinicial = areaterrenoinicial;
    if (areaterrenofinal) filters.areaterrenofinal = areaterrenofinal;
    if (areautilinicial) filters.areautilinicial = areautilinicial;
    if (areautilfinal) filters.areautilfinal = areautilfinal;
    if (dormitorios) filters.dormitorios = dormitorios;
    if (suites) filters.suites = suites;
    if (vagas) filters.vagas = vagas;
    if (banheiros) filters.banheiros = banheiros;
    if (areagalpaoinicial) filters.areagalpaoinicial = areagalpaoinicial;
    if (areagalpaofinal) filters.areagalpaofinal = areagalpaofinal;
    if (areaescritorioinicial)
      filters.areaescritorioinicial = areaescritorioinicial;
    if (areaescritoriofinal) filters.areaescritoriofinal = areaescritoriofinal;
    if (areafabrilinicial) filters.areafabrilinicial = areafabrilinicial;
    if (areafabrilfinal) filters.areafabrilfinal = areafabrilfinal;
    if (valormetroinicial) filters.valormetroinicial = valormetroinicial;
    if (valormetrofinal) filters.valormetrofinal = valormetrofinal;
    if (idFilial) filters.IdFilial = idFilial;
    if (somenteMatriz) filters.somenteMatriz = somenteMatriz;
    if (grupo) filters.grupo = grupo;
    if (IdUnidade) filters.IdUnidade = IdUnidade;
    if (IdObra) filters.IdObra = IdObra;
    if (IdSituacao) filters.IdSituacao = IdSituacao;
    if (permuta) filters.permuta = permuta;
    if (oferta) filters.oferta = oferta;
    if (Mcmv) filters.Mcmv = Mcmv;
    if (comFoto) filters.comFoto = comFoto;
    if (semFoto) filters.semFoto = semFoto;
    if (IdAssinatura) filters.IdAssinatura = IdAssinatura;
    if (banner) filters.banner = banner;
    if (destaque) filters.destaque = destaque;
    if (superDestaque) filters.superDestaque = superDestaque;
    if (lancamento) filters.lancamento = lancamento;
    if (dateStart)
      filters.dateStart = moment(dateStart)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    if (dateEnd)
      filters.dateEnd = moment(dateEnd)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

    return filters;
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async (page = 1, limit = 10) => {
    try {
      setLoadingTable(true);

      const { data } = await api.get(`/imoveis`, {
        params: {
          filters: handleFilter(),
          page,
          limit
        }
      });

      setResults(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingTable(false);
    }
  };

  const getBairros = async (search = null) => {
    try {
      if (search) {
        const { data } = await api.get(`/bairros?term=${search}`, {}, false);
        return data.data.map(i => ({
          ...i,
          name: `${i.Bairro} ${i.Cidade} ${i.Estado}`
        }));
      }
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const handleSearch = () => {
    getList();
  };

  const handleShow = item => {
    setItem(item);
    showModal();
  };

  const handleClose = () => {
    setItem(null);
    hideModal();
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/imoveis/${item.Id}`);
      handleClose();
      getList();
      enqueueSnackbar("Registro deletado com sucesso", {
        variant: "success"
      });
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const handleAddItem = bairro => {
    setBairros(bairros => [...bairros, bairro]);
  };

  const handleDeleteItem = bairroDelete => {
    setBairros(bairros =>
      bairros.filter(bairro => bairro.key !== bairroDelete.key)
    );
  };

  const onChangeSpeaker = search => {
    if (search && search.length > 1) {
      return getBairros(search);
    }
  };

  const handleSelectSpeaker = async item => {
    if (bairros.some(i => i.name === item.name)) {
      enqueueSnackbar("Item já existe na lista", { variant: "info" });
    } else {
      handleAddItem(item);
    }
  };

  const setEmptyValues = () => {
    setTerm("");
    setIdtipoimovel("");
    setReferencia("");
    setIdtransacao("");
    setCondominiofechado(false);
    setIdtopografia("");
    setValorinicial("");
    setValorfinal("");
    setAreaconstruidainicial("");
    setAreaconstruidafinal("");
    setAreaterrenoinicial("");
    setAreaterrenofinal("");
    setAreautilinicial("");
    setAreautilfinal("");
    setDormitorios("");
    setSuites("");
    setVagas("");
    setBanheiros("");
    setAreagalpaoinicial("");
    setAreagalpaofinal("");
    setAreaescritorioinicial("");
    setAreaescritoriofinal("");
    setAreafabrilinicial("");
    setAreafabrilfinal("");
    setValormetroinicial("");
    setValormetrofinal("");
    setBairros([]);
    setIdFilial("");
    setIdUnidade("");
    setIdObra("");
    setGrupo("");
    setIdSituacao("1");
    setPermuta(false);
    setMcmv(false);
    setSomenteMatriz(false);
    setDateStart(null);
    setDateEnd(null);
    setIdAssinatura("");
    setOferta(false);
    setComFoto(false);
    setSemFoto(false);
    setBanner(false);
    setDestaque(false);
    setSuperDestaque(false);
    setLancamento(false);
  };

  return {
    results,
    term,
    setTerm,
    handleSearch,
    handleShow,
    modalOpen,
    hideModal,
    handleClose,
    handleDelete,
    getList,
    idtipoimovel,
    idtransacao,
    condominiofechado,
    idtopografia,
    valorinicial,
    valorfinal,
    areaconstruidainicial,
    areaconstruidafinal,
    areaterrenoinicial,
    areaterrenofinal,
    areautilinicial,
    areautilfinal,
    dormitorios,
    suites,
    vagas,
    banheiros,
    areagalpaoinicial,
    areagalpaofinal,
    areaescritorioinicial,
    areaescritoriofinal,
    areafabrilinicial,
    valormetroinicial,
    valormetrofinal,
    setIdtipoimovel,
    setIdtransacao,
    setCondominiofechado,
    setIdtopografia,
    setValorinicial,
    setValorfinal,
    setAreaconstruidainicial,
    setAreaconstruidafinal,
    setAreaterrenoinicial,
    setAreaterrenofinal,
    setAreautilinicial,
    setAreautilfinal,
    setDormitorios,
    setSuites,
    setVagas,
    setBanheiros,
    setAreagalpaoinicial,
    setAreagalpaofinal,
    setAreaescritorioinicial,
    setAreaescritoriofinal,
    setAreafabrilinicial,
    setValormetroinicial,
    setValormetrofinal,
    areafabrilfinal,
    setAreafabrilfinal,
    bairros,
    handleDeleteItem,
    handleAddItem,
    onChangeSpeaker,
    handleSelectSpeaker,
    idFilial,
    setIdFilial,
    somenteMatriz,
    setSomenteMatriz,
    referencia,
    setReferencia,
    grupo,
    setGrupo,
    IdUnidade,
    setIdUnidade,
    IdObra,
    setIdObra,
    IdSituacao,
    setIdSituacao,
    permuta,
    setPermuta,
    Mcmv,
    setMcmv,
    setEmptyValues,
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    oferta,
    setOferta,
    IdAssinatura,
    setIdAssinatura,
    comFoto,
    semFoto,
    setComFoto,
    setSemFoto,
    banner,
    setBanner,
    destaque,
    setDestaque,
    superDestaque,
    setSuperDestaque,
    lancamento,
    setLancamento
  };
}
