import { useEffect, useState } from "react";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function useSimuladorForm({ simulador, getSimulators, handleClose }) {
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBanks = async () => {
    try {
      const { data } = await api.get("/bancos", {
        params: {
          paginate: 0
        }
      });

      setBanks(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const initialValues = {
    id: simulador?.Id || "",
    IdBanco: simulador?.IdBanco || "",
    Link: simulador?.Link || ""
  };

  const validationSchema = Yup.object().shape({
    IdBanco: Yup.string().required("Informe o banco"),
    Link: Yup.string().required("Informe o link")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id ? `/simuladores/${id}` : `/simuladores`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getSimulators();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    banks
  };
}
