import { Form } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useThemeColor } from "../../../hooks/useThemeColor";
import TextFieldMoney from "../../../components/TextFieldMoney";
import TextFieldMask from "../../../components/TextFieldMask";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function EnterpriseFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldValue,
  handleValueChange,
  permission,
  handleCep,
  estados,
  id
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-enterprise">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Box display={"flex"} gap={2}>
            <FormControl component="fieldset">
              <FormGroup style={{ flexDirection: "row" }}>
                <FormControlLabel
                  label={"Plantão"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Plantao"
                      onChange={handleChange}
                      checked={Boolean(values.Plantao)}
                    />
                  }
                />
                <FormControlLabel
                  label={"Publicar"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Publicar"
                      onChange={handleChange}
                      checked={Boolean(values.Publicar)}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Nome"
            name="Nome"
            value={values.Nome}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Nome && errors.Nome}
            error={Boolean(touched.Nome && errors.Nome)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Administradora"
            name="Administradora"
            value={values.Administradora}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Administradora && errors.Administradora}
            error={Boolean(touched.Administradora && errors.Administradora)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Tipo"
            name="Tipo"
            value={values.Tipo}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Tipo && errors.Tipo}
            error={Boolean(touched.Tipo && errors.Tipo)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Andares"
            name="Andares"
            value={values.Andares}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Andares && errors.Andares}
            error={Boolean(touched.Andares && errors.Andares)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Ano de construção"
            name="AnoConstrucao"
            value={values.AnoConstrucao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.AnoConstrucao && errors.AnoConstrucao}
            error={Boolean(touched.AnoConstrucao && errors.AnoConstrucao)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Unidades por Andar"
            name="UnidadesAndar"
            value={values.UnidadesAndar}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.UnidadesAndar && errors.UnidadesAndar}
            error={Boolean(touched.UnidadesAndar && errors.UnidadesAndar)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Paisagismo"
            name="Paisagismo"
            value={values.Paisagismo}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Paisagismo && errors.Paisagismo}
            error={Boolean(touched.Paisagismo && errors.Paisagismo)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Arquitetura"
            name="Arquitetura"
            value={values.Arquitetura}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Arquitetura && errors.Arquitetura}
            error={Boolean(touched.Arquitetura && errors.Arquitetura)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Site"
            name="Site"
            value={values.Site}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Site && errors.Site}
            error={Boolean(touched.Site && errors.Site)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMoney
            margin="normal"
            variant="filled"
            fullWidth
            label="Valor Mínimo"
            name="ValorMin"
            value={values.ValorMin}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={event =>
              handleValueChange(event, "ValorMin", setFieldValue)
            }
            helperText={touched.ValorMin && errors.ValorMin}
            error={Boolean(touched.ValorMin && errors.ValorMin)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMoney
            margin="normal"
            variant="filled"
            fullWidth
            label="Valor Máximo"
            name="ValorMax"
            value={values.ValorMax}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={event =>
              handleValueChange(event, "ValorMax", setFieldValue)
            }
            helperText={touched.ValorMax && errors.ValorMax}
            error={Boolean(touched.ValorMax && errors.ValorMax)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Fase"
            labelId={"IdFase"}
            name={"IdFase"}
            value={values.IdFase}
            onChange={handleChange}
            helperText={touched.IdFase && errors.IdFase}
            error={Boolean(touched.IdFase && errors.IdFase)}
            options={[
              { Id: "1", Nome: "Inicial" },
              { Id: "2", Nome: "Construção" },
              { Id: "3", Nome: "Vistoria" },
              { Id: "4", Nome: "Documentação" },
              { Id: "5", Nome: "Pronto" }
            ]}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Construtora"
            name="Construtora"
            value={values.Construtora}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Construtora && errors.Construtora}
            error={Boolean(touched.Construtora && errors.Construtora)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Incorporadora"
            name="Incorporadora"
            value={values.Incorporadora}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Incorporadora && errors.Incorporadora}
            error={Boolean(touched.Incorporadora && errors.Incorporadora)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"99999-999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Cep"
            name="Cep"
            value={values.Cep}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Cep && errors.Cep}
            error={Boolean(touched.Cep && errors.Cep)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handleCep(values, setFieldValue)}>
                  <SearchIcon />
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Tipo de endereço"
            name="TipoEndereco"
            value={values.TipoEndereco}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.TipoEndereco && errors.TipoEndereco}
            error={Boolean(touched.TipoEndereco && errors.TipoEndereco)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Endereço"
            name="Endereco"
            value={values.Endereco}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Endereco && errors.Endereco}
            error={Boolean(touched.Endereco && errors.Endereco)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Número"
            name="Numero"
            value={values.Numero}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Numero && errors.Numero}
            error={Boolean(touched.Numero && errors.Numero)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Complemento"
            name="Complemento"
            value={values.Complemento}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Complemento && errors.Complemento}
            error={Boolean(touched.Complemento && errors.Complemento)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Bairro"
            name="Bairro"
            value={values.Bairro}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Bairro && errors.Bairro}
            error={Boolean(touched.Bairro && errors.Bairro)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cidade"
            name="Cidade"
            value={values.Cidade}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Cidade && errors.Cidade}
            error={Boolean(touched.Cidade && errors.Cidade)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Estado"
            labelId={"IdEstado"}
            name={"IdEstado"}
            value={values.IdEstado}
            onChange={handleChange}
            helperText={touched.IdEstado && errors.IdEstado}
            error={Boolean(touched.IdEstado && errors.IdEstado)}
            options={estados}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Contato"
            name="Contato"
            value={values.Contato}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Contato && errors.Contato}
            error={Boolean(touched.Contato && errors.Contato)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="E-mail"
            name="Email"
            type="email"
            value={values.Email}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Email && errors.Email}
            error={Boolean(touched.Email && errors.Email)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 9999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone"
            name="Telefone"
            value={values.Telefone}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Telefone && errors.Telefone}
            error={Boolean(touched.Telefone && errors.Telefone)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 99999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone Mensagem"
            name="Mensagem"
            value={values.Mensagem}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Mensagem && errors.Mensagem}
            error={Boolean(touched.Mensagem && errors.Mensagem)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Profissão"
            name="Profissao"
            value={values.Profissao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Profissao && errors.Profissao}
            error={Boolean(touched.Profissao && errors.Profissao)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Data de Entrega"
            name="DataEntrega"
            type="date"
            value={values.DataEntrega}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.DataEntrega && errors.DataEntrega}
            error={Boolean(touched.DataEntrega && errors.DataEntrega)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Situação"
            labelId={"IdStatus"}
            name={"IdStatus"}
            value={values.IdStatus}
            onChange={handleChange}
            helperText={touched.IdStatus && errors.IdStatus}
            error={Boolean(touched.IdStatus && errors.IdStatus)}
            options={[
              { Id: "1", Nome: "Ativo" },
              { Id: "2", Nome: "Inativo" },
              { Id: "3", Nome: "Suspenso" }
            ]}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            label="Observação"
            name="Observacao"
            value={values.Observacao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Observacao && errors.Observacao}
            error={Boolean(touched.Observacao && errors.Observacao)}
          />
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
