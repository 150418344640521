import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { errorsMessage, formatCurrency, parseValue } from "../../helpers";
import { api } from "../../services/api";
import useLoadingStore from "../../store/loading";

export function useImovelForm(props) {
  const navigate = useNavigate();
  const [imovel, setImovel] = useState({});
  const [tiposImovel, setTiposImovel] = useState([]);
  const [estados, setEstados] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const [zoneamentos, setZoneamentos] = useState([]);
  const [tipoImovelSelected, setTipoImovelSelected] = useState(null);
  const [lastImovel, setLastImovel] = useState(null);

  const { setLoadingCep } = useLoadingStore(state => state);
  const id = props?.imovelId || null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getItem = async id => {
    try {
      const { data } = await api.get(`/imoveis/${id}`);

      setImovel(data?.data || {});
      setTipoImovelSelected(data?.data?.tipoimovel);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getTiposImovel = async () => {
    try {
      const { data } = await api.get("/tipoimoveis", {
        params: {
          paginate: 0
        }
      });

      setTiposImovel(
        data?.data?.map(t => {
          return {
            ...t,
            Nome: `${t.SubTipoSGI} - ${t.Categoria} - ${t.SubTipo}`
          };
        }) || []
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getEstados = async () => {
    try {
      const { data } = await api.get("/estados", {
        params: {
          paginate: 0
        }
      });

      setEstados(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getUnidades = async () => {
    try {
      const { data } = await api.get("/unidades", {
        params: {
          paginate: 0
        }
      });

      setUnidades(
        data?.data?.map(i => {
          return {
            ...i,
            Nome: `${i.Nome} / ${i?.empreendimento?.Nome}`
          };
        }) || []
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getColadoradores = async () => {
    try {
      const { data } = await api.get("/colaboradores", {
        params: {
          paginate: 0
        }
      });

      setColaboradores(
        data?.data.map(a => {
          return {
            ...a,
            Nome: `${a.pessoa?.Nome} - ${a.tipoColaborador}`
          };
        }) || []
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getFiliais = async () => {
    try {
      const { data } = await api.get("/filiais", {
        params: {
          paginate: 0
        }
      });

      setFiliais(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getZoneamentos = async () => {
    try {
      const { data } = await api.get("/zonas", {
        params: {
          paginate: 0
        }
      });

      setZoneamentos(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getLastImovel = async () => {
    try {
      const { data } = await api.get("/imoveis/get/last-imovel");

      setLastImovel(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getEstados();
    getTiposImovel();
    getUnidades();
    getColadoradores();
    getFiliais();
    getZoneamentos();
    getLastImovel();
  }, []);

  useEffect(() => {
    if (id) {
      getItem(id);
    }
  }, [id]);

  const initialValues = {
    // DADOS INICIAIS
    id: imovel?.Id || "",
    IdTipoImovel: imovel?.IdTipoImovel || "",
    Localizacao: imovel?.Localizacao || "",
    IdTransacao: imovel?.IdTransacao || "",
    IdUnidade: imovel?.IdUnidade || "",
    IdEstado: imovel?.IdEstado || "",
    TipoEndereco: imovel?.TipoEndereco || "",
    Endereco: imovel?.Endereco || "",
    Numero: imovel?.Numero || "",
    Complemento: imovel?.Complemento || "",
    Bairro: imovel?.Bairro || "",
    Cidade: imovel?.Cidade || "",
    Cep: imovel?.Cep || "",
    Latitude: imovel?.Latitude || "",
    Longitude: imovel?.Longitude || "",
    LinkVideo: imovel?.LinkVideo || "",

    // DADOS GLOBAIS TABELA IMOVEIS
    AreaTerreno: imovel?.AreaTerreno || "",
    AreaConstruida: imovel?.AreaConstruida || "",
    AreaUtil: imovel?.AreaUtil || "",
    AreaComum: imovel?.AreaComum || "",
    AreaPrivativa: imovel?.AreaPrivativa || "",
    AreaTotal: imovel?.AreaTotal || "",
    Medida: imovel?.Medida || "",
    Garagem: imovel?.Garagem || "",
    VagaVisitante: imovel?.VagaVisitante || "",
    AquecedorCentral: imovel?.AquecedorCentral || "",
    SistemaSeguranca: imovel?.SistemaSeguranca || "",
    ArCondicionado: imovel?.ArCondicionado
      ? Boolean(imovel?.ArCondicionado)
      : false,
    Esquina: imovel?.Esquina ? Boolean(imovel?.Esquina) : false,
    Telefone: imovel?.Telefone ? Boolean(imovel?.Telefone) : false,
    Interfone: imovel?.Interfone ? Boolean(imovel?.Interfone) : false,
    InfraInternet: imovel?.InfraInternet
      ? Boolean(imovel?.InfraInternet)
      : false,
    Agua: imovel?.Agua ? Boolean(imovel?.Agua) : false,
    Luz: imovel?.Luz ? Boolean(imovel?.Luz) : false,
    P24h: imovel?.P24h ? Boolean(imovel?.P24h) : false,
    Banheiro: imovel?.Banheiro || "",
    Andar: imovel?.Andar || "",
    PeDireito: imovel?.PeDireito || "",
    Divisorias: imovel?.Divisorias ? Boolean(imovel?.Divisorias) : false,
    EntradaIndependente: imovel?.EntradaIndependente
      ? Boolean(imovel?.EntradaIndependente)
      : false,
    HeliPonto: imovel?.HeliPonto ? Boolean(imovel?.HeliPonto) : false,
    Forro: imovel?.Forro ? Boolean(imovel?.Forro) : false,
    Auditorio: imovel?.Auditorio ? Boolean(imovel?.Auditorio) : false,
    Luminarias: imovel?.Luminarias ? Boolean(imovel?.Luminarias) : false,
    BusinessCenter: imovel?.BusinessCenter
      ? Boolean(imovel?.BusinessCenter)
      : false,
    Banner: imovel?.Banner ? Boolean(imovel?.Banner) : false,
    FotosWeb: imovel?.FotosWeb ? Boolean(imovel?.FotosWeb) : false,
    Internet: imovel?.Internet ? Boolean(imovel?.Internet) : false,
    Oferta: imovel?.Oferta ? Boolean(imovel?.Oferta) : false,
    IdPublicidade: imovel?.IdPublicidade || "",

    // DADOS COMERCIALIZAÇÃO
    ValorVenda: imovel?.ValorVenda
      ? formatCurrency(imovel?.ValorVenda || 0)
      : "",
    ValorLocacao: imovel?.ValorLocacao
      ? formatCurrency(imovel?.ValorLocacao || 0)
      : "",
    ValorCondominio: imovel?.ValorCondominio
      ? formatCurrency(imovel?.ValorCondominio || 0)
      : "",
    ValorMetro: imovel?.ValorMetro
      ? formatCurrency(imovel?.ValorMetro || 0)
      : "",
    ValorIptu: imovel?.ValorIptu ? formatCurrency(imovel?.ValorIptu || 0) : "",

    TelPortaria: imovel?.TelPortaria || "",
    Zelador: imovel?.Zelador || "",
    IdUso: imovel?.IdUso || "0",
    IdObra: imovel?.IdObra || "0",
    IdFrente: imovel?.IdFrente || "0",
    IdZoneamento: imovel?.IdZoneamento || "",
    CondominioFechado: imovel?.CondominioFechado
      ? Boolean(imovel?.CondominioFechado)
      : false,
    EmExposicao: imovel?.EmExposicao ? Boolean(imovel?.EmExposicao) : false,
    Plantao: imovel?.Plantao ? Boolean(imovel?.Plantao) : false,
    Permuta: imovel?.Permuta ? Boolean(imovel?.Permuta) : false,
    Banco: imovel?.Banco ? Boolean(imovel?.Banco) : false,
    Cafe: imovel?.Cafe ? Boolean(imovel?.Cafe) : false,
    Restaurante: imovel?.Restaurante ? Boolean(imovel?.Restaurante) : false,
    CondPagamento: imovel?.CondPagamento || "",
    Observacao: imovel?.Observacao || "",
    MemorialDescritivo: imovel?.MemorialDescritivo || "",
    Elevador: imovel?.Elevador ? Boolean(imovel?.Elevador) : false,

    // DADOS RESIDENCIAL 01
    Mcmv: imovel?.residencial_rural?.Mcmv
      ? Boolean(imovel?.residencial_rural?.Mcmv)
      : false,
    TipoPiso: imovel?.residencial_rural?.TipoPiso || "",
    Lavanderia: imovel?.residencial_rural?.Lavanderia
      ? Boolean(imovel?.residencial_rural?.Lavanderia)
      : false,
    QuadraPoli: imovel?.residencial_rural?.QuadraPoli
      ? Boolean(imovel?.residencial_rural?.QuadraPoli)
      : false,
    Armario: imovel?.residencial_rural?.Armario
      ? Boolean(imovel?.residencial_rural?.Armario)
      : false,
    Despensa: imovel?.residencial_rural?.Despensa
      ? Boolean(imovel?.residencial_rural?.Despensa)
      : false,
    ArmarioCozinha: imovel?.residencial_rural?.ArmarioCozinha
      ? Boolean(imovel?.residencial_rural?.ArmarioCozinha)
      : false,
    CozinhaEquipada: imovel?.residencial_rural?.CozinhaEquipada
      ? Boolean(imovel?.residencial_rural?.CozinhaEquipada)
      : false,
    CozinhaPlanejada: imovel?.residencial_rural?.CozinhaPlanejada
      ? Boolean(imovel?.residencial_rural?.CozinhaPlanejada)
      : false,
    Mobiliada: imovel?.residencial_rural?.Mobiliada
      ? Boolean(imovel?.residencial_rural?.Mobiliada)
      : false,
    SalaAlmoco: imovel?.residencial_rural?.SalaAlmoco
      ? Boolean(imovel?.residencial_rural?.SalaAlmoco)
      : false,
    SalaJantar: imovel?.residencial_rural?.SalaJantar
      ? Boolean(imovel?.residencial_rural?.SalaJantar)
      : false,
    SalaIntima: imovel?.residencial_rural?.SalaIntima
      ? Boolean(imovel?.residencial_rural?.SalaIntima)
      : false,
    Sacada: imovel?.residencial_rural?.Sacada
      ? Boolean(imovel?.residencial_rural?.Sacada)
      : false,
    HallEntrada: imovel?.residencial_rural?.HallEntrada
      ? Boolean(imovel?.residencial_rural?.HallEntrada)
      : false,
    TvCabo: imovel?.residencial_rural?.TvCabo
      ? Boolean(imovel?.residencial_rural?.TvCabo)
      : false,
    Jardim: imovel?.residencial_rural?.Jardim
      ? Boolean(imovel?.residencial_rural?.Jardim)
      : false,
    Geminada: imovel?.residencial_rural?.Geminada
      ? Boolean(imovel?.residencial_rural?.Geminada)
      : false,
    Guarita: imovel?.residencial_rural?.Guarita
      ? Boolean(imovel?.residencial_rural?.Guarita)
      : false,
    Quintal: imovel?.residencial_rural?.Quintal
      ? Boolean(imovel?.residencial_rural?.Quintal)
      : false,

    // DADOS RESIDENCIAL 02
    Varanda: imovel?.residencial_rural?.Varanda
      ? Boolean(imovel?.residencial_rural?.Varanda)
      : false,
    Terraco: imovel?.residencial_rural?.Terraco
      ? Boolean(imovel?.residencial_rural?.Terraco)
      : false,
    PlayGround: imovel?.residencial_rural?.PlayGround
      ? Boolean(imovel?.residencial_rural?.PlayGround)
      : false,
    SalaGinastica: imovel?.residencial_rural?.SalaGinastica
      ? Boolean(imovel?.residencial_rural?.SalaGinastica)
      : false,
    Lareira: imovel?.residencial_rural?.Lareira
      ? Boolean(imovel?.residencial_rural?.Lareira)
      : false,
    Copa: imovel?.residencial_rural?.Copa
      ? Boolean(imovel?.residencial_rural?.Copa)
      : false,
    SalaoFesta: imovel?.residencial_rural?.SalaoFesta
      ? Boolean(imovel?.residencial_rural?.SalaoFesta)
      : false,
    SalaoJogos: imovel?.residencial_rural?.SalaoJogos
      ? Boolean(imovel?.residencial_rural?.SalaoJogos)
      : false,
    Piscina: imovel?.residencial_rural?.Piscina
      ? Boolean(imovel?.residencial_rural?.Piscina)
      : false,
    Adega: imovel?.residencial_rural?.Adega
      ? Boolean(imovel?.residencial_rural?.Adega)
      : false,
    Churrasqueira: imovel?.residencial_rural?.Churrasqueira
      ? Boolean(imovel?.residencial_rural?.Churrasqueira)
      : false,
    Sauna: imovel?.residencial_rural?.Sauna
      ? Boolean(imovel?.residencial_rural?.Sauna)
      : false,
    EspacoGourmet: imovel?.residencial_rural?.EspacoGourmet
      ? Boolean(imovel?.residencial_rural?.EspacoGourmet)
      : false,
    JardimInverno: imovel?.residencial_rural?.JardimInverno
      ? Boolean(imovel?.residencial_rural?.JardimInverno)
      : false,
    ChildrenCare: imovel?.residencial_rural?.ChildrenCare
      ? Boolean(imovel?.residencial_rural?.ChildrenCare)
      : false,
    Edicula: imovel?.residencial_rural?.Edicula
      ? Boolean(imovel?.residencial_rural?.Edicula)
      : false,
    Escritorio: imovel?.residencial_rural?.Escritorio
      ? Boolean(imovel?.residencial_rural?.Escritorio)
      : false,
    AreaServico: imovel?.residencial_rural?.AreaServico
      ? Boolean(imovel?.residencial_rural?.AreaServico)
      : false,
    Living: imovel?.residencial_rural?.Living || "",
    Dormitorio: imovel?.residencial_rural?.Dormitorio || "",
    DormEmpregada: imovel?.residencial_rural?.DormEmpregada || "",
    Suite: imovel?.residencial_rural?.Suite || "",
    SuiteMaster: imovel?.residencial_rural?.SuiteMaster || "",
    WcSocial: imovel?.residencial_rural?.WcSocial || "",
    WcEmpregada: imovel?.residencial_rural?.WcEmpregada || "",
    QuantidadeAndares: imovel?.residencial_rural?.QuantidadeAndares || "",
    Lavabo: imovel?.residencial_rural?.Lavabo
      ? Boolean(imovel?.residencial_rural?.Lavabo)
      : false,
    Closet: imovel?.residencial_rural?.Closet
      ? Boolean(imovel?.residencial_rural?.Closet)
      : false,
    Hidro: imovel?.residencial_rural?.Hidro
      ? Boolean(imovel?.residencial_rural?.Hidro)
      : false,

    // DADOS RURAL
    Acessos: imovel?.residencial_rural?.Acessos || "",
    Equipamentos: imovel?.residencial_rural?.Equipamentos || "",
    Pomar: imovel?.residencial_rural?.Pomar
      ? Boolean(imovel?.residencial_rural?.Pomar)
      : false,
    Rio: imovel?.residencial_rural?.Rio
      ? Boolean(imovel?.residencial_rural?.Rio)
      : false,
    Lago: imovel?.residencial_rural?.Lago
      ? Boolean(imovel?.residencial_rural?.Lago)
      : false,
    CasaCaseiro: imovel?.residencial_rural?.CasaCaseiro
      ? Boolean(imovel?.residencial_rural?.CasaCaseiro)
      : false,
    Curral: imovel?.residencial_rural?.Curral
      ? Boolean(imovel?.residencial_rural?.Curral)
      : false,
    Baia: imovel?.residencial_rural?.Baia
      ? Boolean(imovel?.residencial_rural?.Baia)
      : false,
    PocoArtesiano: imovel?.residencial_rural?.PocoArtesiano
      ? Boolean(imovel?.residencial_rural?.PocoArtesiano)
      : false,

    // DADOS COMERCIAL

    // DADOS TERRENO
    Quadra: imovel?.terreno?.Quadra || "",
    Lote: imovel?.terreno?.Lote || "",
    IdTopografia: imovel?.terreno?.IdTopografia || "0",

    // DADOS GALPAO
    AreaEscritorio: imovel?.galpao?.AreaEscritorio || "",
    AreaGalpao: imovel?.galpao?.AreaGalpao || "",
    AreaFabril: imovel?.galpao?.AreaFabril || "",
    AreaPatio: imovel?.galpao?.AreaPatio || "",
    VaoLivre: imovel?.galpao?.VaoLivre || "",
    ForcaTrifasica: imovel?.galpao?.ForcaTrifasica || "",
    Plataforma: imovel?.galpao?.Plataforma || "",
    CabinePrimaria: imovel?.galpao?.CabinePrimaria
      ? Boolean(imovel?.galpao?.CabinePrimaria)
      : false,
    Docas: imovel?.galpao?.Docas ? Boolean(imovel?.galpao?.Docas) : false,
    AcessoCaminhao: imovel?.galpao?.AcessoCaminhao
      ? Boolean(imovel?.galpao?.AcessoCaminhao)
      : false,
    ElevadorCarga: imovel?.galpao?.ElevadorCarga
      ? Boolean(imovel?.galpao?.ElevadorCarga)
      : false,
    EsteiraRolante: imovel?.galpao?.EsteiraRolante
      ? Boolean(imovel?.galpao?.EsteiraRolante)
      : false,
    PonteRolante: imovel?.galpao?.PonteRolante
      ? Boolean(imovel?.galpao?.PonteRolante)
      : false,
    PisoElevado: imovel?.galpao?.PisoElevado
      ? Boolean(imovel?.galpao?.PisoElevado)
      : false,
    Vestiario: imovel?.galpao?.Vestiario
      ? Boolean(imovel?.galpao?.Vestiario)
      : false,
    Wc: imovel?.galpao?.Wc ? Boolean(imovel?.galpao?.Wc) : false,
    Refeitorio: imovel?.galpao?.Refeitorio
      ? Boolean(imovel?.galpao?.Refeitorio)
      : false,
    Ambulatorio: imovel?.galpao?.Ambulatorio
      ? Boolean(imovel?.galpao?.Ambulatorio)
      : false,

    // DADOS ADICIONAIS
    TituloWeb: imovel?.TituloWeb || "",
    DescricaoSite: imovel?.DescricaoSite || "",
    Chaves: imovel?.Chaves || "",
    IdCaptador: imovel?.IdCaptador || "",
    DataCaptacao: imovel?.DataCaptacao || "",
    IdParticipante: imovel?.IdParticipante || "",
    IdPlaca: imovel?.IdPlaca || "",
    DataPlaca: imovel?.DataPlaca || "",
    IdAtualizador: imovel?.IdAtualizador || "",
    FimLocacao: imovel?.FimLocacao || "",
    Idade: imovel?.Idade || "",
    Referencia: imovel?.Referencia || "",
    IdSituacao: imovel?.IdSituacao || "1",
    IdFilial: imovel?.IdFilial || ""
  };

  const validationSchema = Yup.object().shape({
    IdTipoImovel: Yup.string().required("Este campo é obrigatório"),
    Localizacao: Yup.string().required("Este campo é obrigatório"),
    IdTransacao: Yup.string().required("Este campo é obrigatório"),
    IdEstado: Yup.string().required("Este campo é obrigatório"),
    TipoEndereco: Yup.string().required("Este campo é obrigatório"),
    Endereco: Yup.string().required("Este campo é obrigatório"),
    Numero: Yup.number().required("Este campo é obrigatório"),
    Bairro: Yup.string().required("Este campo é obrigatório"),
    Cidade: Yup.string().required("Este campo é obrigatório"),
    Cep: Yup.string().required("Este campo é obrigatório"),
    IdSituacao: Yup.string().required("Este campo é obrigatório"),
    IdObra: Yup.string().required("Este campo é obrigatório"),
    IdUso: Yup.string().required("Este campo é obrigatório"),
    FotosWeb: Yup.bool().required("Este campo é obrigatório"),
    CondominioFechado: Yup.bool().required("Este campo é obrigatório"),
    Plantao: Yup.bool().required("Este campo é obrigatório"),
    EmExposicao: Yup.bool().required("Este campo é obrigatório"),
    Banco: Yup.bool().required("Este campo é obrigatório"),
    Cafe: Yup.bool().required("Este campo é obrigatório"),
    Restaurante: Yup.bool().required("Este campo é obrigatório"),
    Internet: Yup.bool().required("Este campo é obrigatório"),
    Oferta: Yup.bool().required("Este campo é obrigatório"),
    Banner: Yup.bool().required("Este campo é obrigatório"),
    Permuta: Yup.bool().required("Este campo é obrigatório"),
    Referencia: Yup.string().max(
      10,
      "Por favor, informe no máximo 10 caracteres"
    )
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;
      formData.ValorVenda = parseValue(formData.ValorVenda);
      formData.ValorLocacao = parseValue(formData.ValorLocacao);
      formData.ValorCondominio = parseValue(formData.ValorCondominio);
      formData.ValorMetro = parseValue(formData.ValorMetro);
      formData.ValorIptu = parseValue(formData.ValorIptu);

      formData.AreaTerreno = parseValue(formData.AreaTerreno);
      formData.AreaConstruida = parseValue(formData.AreaConstruida);
      formData.AreaUtil = parseValue(formData.AreaUtil);
      formData.AreaComum = parseValue(formData.AreaComum);
      formData.AreaPrivativa = parseValue(formData.AreaPrivativa);
      formData.AreaTotal = parseValue(formData.AreaTotal);
      formData.Medida = parseValue(formData.Medida);
      formData.PeDireito = parseValue(formData.PeDireito);
      formData.AreaGalpao = parseValue(formData.AreaGalpao);
      formData.AreaEscritorio = parseValue(formData.AreaEscritorio);
      formData.AreaEscritorio = parseValue(formData.AreaEscritorio);
      formData.AreaFabril = parseValue(formData.AreaFabril);
      formData.AreaPatio = parseValue(formData.AreaPatio);

      const path = id ? `/imoveis/${id}` : `/imoveis`;
      const method = id ? "put" : "post";

      const { data } = await api[method](path, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      if (id) {
        setTimeout(() => {
          window.location.reload();
        }, 400);
      } else {
        navigate(`/imoveis/edit/${data?.data?.Id}`);
      }
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleCep = async (values, setValues) => {
    try {
      setLoadingCep(true);

      const cep = values.Cep.replace(/\D/g, "");

      if (cep.length < 8) {
        enqueueSnackbar("Por favor informe o cep corretamente", {
          variant: "error"
        });
        return;
      }

      const { data } = await api.get(`/endereco/cep?cep=${cep}`);

      setValues("Endereco", data?.logradouro, false);
      setValues("Cidade", data?.localidade, false);
      setValues("Bairro", data?.bairro, false);
      setValues("TipoEndereco", data?.type, false);
      setValues("IdEstado", data?.IdEstado, false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingCep(false);
    }
  };

  const handleValueChange = (formattedValue, field, setValues) => {
    setValues(field, formattedValue, false);
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    tiposImovel,
    estados,
    unidades,
    handleCep,
    colaboradores,
    filiais,
    zoneamentos,
    tipoImovelSelected,
    setTipoImovelSelected,
    handleValueChange,
    lastImovel
  };
}
