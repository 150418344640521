import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useRegister() {
  const navigate = useNavigate();

  const initialValues = {
    Nome: "",
    email: "",
    Telefone: "",
    password: "",
    password_confirmation: "",
    aceiteTermos: false
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Informe seu nome"),
    email: Yup.string().email("E-mail inválido").required("Informe seu e-mail"),
    Telefone: Yup.string().required("Informe seu telefone"),
    password: Yup.string()
      .min(6, "Informe pelo menos 6 caracteres")
      .required("Informe uma senha"),
    password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "As senhas não conferem, favor verificar"
      )
      .required("Confirme sua senha"),
    aceiteTermos: Yup.bool().oneOf([true], "O aceite dos termos é requerido")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const {
        data: { error, message }
      } = await api.post("/auth/register", values);

      enqueueSnackbar(message, {
        variant: error ? "error" : "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
