import Sidebar from "../pages/shared/Sidebar";
import Topbar from "../pages/shared/Topbar";
import useSidebarStore from "../store/sidebar";

export default function DefaultLayout({ children }) {
  const { visible } = useSidebarStore(state => state);

  return (
    <div className="app">
      <Sidebar isSidebar={visible} />
      <main className="content">
        <Topbar />

        {children}
      </main>
    </div>
  );
}
