import { useEffect, useState } from "react";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useBankForm({ bank, getBanks, handleClose }) {
  const [photo_url, setPhotoUrl] = useState(null);

  const initialValues = {
    id: bank?.Id || "",
    Nome: bank?.Nome || "",
    IdBanco: bank?.IdBanco || "",
    Logomarca: bank?.Logomarca || false,
    Link: bank?.Link || ""
  };

  useEffect(() => {
    if (bank?.foto_url) {
      setPhotoUrl(bank?.foto_url);
    }
  }, [bank?.foto_url]);

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Informe o nome"),
    IdBanco: Yup.string().required("Informe o banco")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, Logomarca, ...formData } = values;

      const path = id ? `/bancos/${id}` : `/bancos`;

      if (id) {
        formData["_method"] = "PUT";
      }

      if (values.Logomarca && values.Logomarca instanceof File && Logomarca) {
        formData.Logomarca = Logomarca;
      }

      await api.post(path, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getBanks();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleImage = (files, field, setValues) => {
    if (files.length > 0) {
      setValues(field, files[0], false);
      setPhotoUrl(URL.createObjectURL(files[0]));
    }
  };

  const removeImage = (field, setValues) => {
    setValues(field, false, false);
    setPhotoUrl(null);
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    handleImage,
    removeImage,
    photo_url
  };
}
