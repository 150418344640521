import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useUserForm(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [photo_url, setPhotoUrl] = useState(null);
  const [perfis, setPerfils] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const id = props?.id || null;

  const getItem = async id => {
    try {
      const { data } = await api.get(`/users/${id}`);

      setUser(data?.data || {});
      if (data?.data?.foto_url) setPhotoUrl(data?.data?.foto_url);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getPerfis = async () => {
    try {
      const { data } = await api.get("/perfis", {
        params: {
          paginate: 0
        }
      });

      setPerfils(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getFiliais = async () => {
    try {
      const { data } = await api.get("/filiais", {
        params: {
          paginate: 0
        }
      });

      setFiliais(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getPerfis();
    getFiliais();
  }, []);

  useEffect(() => {
    if (id) {
      getItem(id);
    }
  }, [id]);

  const initialValues = {
    id: user?.Id || "",
    Nome: user?.Nome || "",
    email: user?.email || "",
    password: "",
    IdPerfil: user?.IdPerfil || "",
    IdFilial: user?.IdFilial || "",
    IdSituacao: user?.IdSituacao || "1",
    Observacao: user?.Observacao || "",
    Foto: user?.Foto || false
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Este campo é obrigatório"),
    email: Yup.string().required("Este campo é obrigatório"),
    password: Yup.string()
      .min(6, "Informe ao menos 6 caracteres")
      .concat(!id ? Yup.string().required("Este campo é obrigatório") : null),
    IdPerfil: Yup.string().required("Este campo é obrigatório"),
    IdSituacao: Yup.string().required("Este campo é obrigatório")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, Foto, password, ...formData } = values;

      const path = id ? `/users/${id}` : `/users`;

      if (id) {
        formData["_method"] = "PUT";
      }

      if (values.Foto && values.Foto instanceof File && Foto) {
        formData.Foto = Foto;
      }

      if (values.password.length > 0) {
        formData.password = password;
      }

      const { data } = await api.post(path, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      navigate(`/usuarios/edit/${data?.data?.Id}`);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleImage = (files, field, setValues) => {
    if (files.length > 0) {
      setValues(field, files[0], false);
      setPhotoUrl(URL.createObjectURL(files[0]));
    }
  };

  const removeImage = (field, setValues) => {
    setValues(field, false, false);
    setPhotoUrl(null);
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    handleImage,
    removeImage,
    photo_url,
    perfis,
    filiais
  };
}
