import { Box, Tooltip } from "@mui/material";
import { MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useThemeColor } from "../../hooks/useThemeColor";

const ItemComponent = ({ title, to, icon, selected, setSelected, toolTip }) => {
  const { colors } = useThemeColor();

  return (
    <Tooltip title={toolTip} placement="top">
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100]
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Box>{title}</Box>
        <Link to={to} />
      </MenuItem>
    </Tooltip>
  );
};

export default ItemComponent;
