import { Form } from "formik";
import {
  Button,
  Grid,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useThemeColor } from "../../../hooks/useThemeColor";
import TextFieldMask from "../../../components/TextFieldMask";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function FilialFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldValue,
  estados,
  handleCep,
  permission,
  id
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-filial">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <FormControl>
            <FormLabel
              style={{ color: colors.grey[100] }}
              id="demo-row-radio-buttons-group-label"
            >
              Tipo Pessoa
            </FormLabel>
            <RadioGroup
              row
              name="TipoPessoa"
              value={values.TipoPessoa}
              onChange={handleChange}
              aria-labelledby="demo-row-radio-buttons-group-label"
            >
              <FormControlLabel
                value="PF"
                control={<Radio style={{ color: colors.grey[100] }} />}
                label="Pessoa Física"
              />
              <FormControlLabel
                value="PJ"
                control={<Radio style={{ color: colors.grey[100] }} />}
                label="Pessoa Jurídica"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cód. Filial"
            name="IdFilial"
            value={values.IdFilial}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.IdFilial && errors.IdFilial}
            error={Boolean(touched.IdFilial && errors.IdFilial)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Razão Social"
            name="RazaoSocial"
            value={values.RazaoSocial}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.RazaoSocial && errors.RazaoSocial}
            error={Boolean(touched.RazaoSocial && errors.RazaoSocial)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Nome Fantasia"
            name="NomeFantasia"
            value={values.NomeFantasia}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.NomeFantasia && errors.NomeFantasia}
            error={Boolean(touched.NomeFantasia && errors.NomeFantasia)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={
              values.TipoPessoa === "PJ"
                ? "99.999.999/9999-99"
                : "999.999.999-99"
            }
            margin="normal"
            variant="filled"
            fullWidth
            label="CNPJ/CPF"
            name="CNPJ"
            value={values.CNPJ}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.CNPJ && errors.CNPJ}
            error={Boolean(touched.CNPJ && errors.CNPJ)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Inscrição Estadual"
            name="InscricaoEstadual"
            value={values.InscricaoEstadual}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.InscricaoEstadual && errors.InscricaoEstadual}
            error={Boolean(
              touched.InscricaoEstadual && errors.InscricaoEstadual
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Website"
            name="WebSite"
            value={values.WebSite}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.WebSite && errors.WebSite}
            error={Boolean(touched.WebSite && errors.WebSite)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"99999-999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Cep"
            name="Cep"
            value={values.Cep}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Cep && errors.Cep}
            error={Boolean(touched.Cep && errors.Cep)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handleCep(values, setFieldValue)}>
                  <SearchIcon />
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Tipo Endereço"
            name="TipoEndereco"
            value={values.TipoEndereco}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.TipoEndereco && errors.TipoEndereco}
            error={Boolean(touched.TipoEndereco && errors.TipoEndereco)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Endereço"
            name="Endereco"
            value={values.Endereco}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Endereco && errors.Endereco}
            error={Boolean(touched.Endereco && errors.Endereco)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Número"
            name="Numero"
            value={values.Numero}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Numero && errors.Numero}
            error={Boolean(touched.Numero && errors.Numero)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Complemento"
            name="Complemento"
            value={values.Complemento}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Complemento && errors.Complemento}
            error={Boolean(touched.Complemento && errors.Complemento)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Bairro"
            name="Bairro"
            value={values.Bairro}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Bairro && errors.Bairro}
            error={Boolean(touched.Bairro && errors.Bairro)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cidade"
            name="Cidade"
            value={values.Cidade}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Cidade && errors.Cidade}
            error={Boolean(touched.Cidade && errors.Cidade)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Estado"
            labelId={"IdEstado"}
            name={"IdEstado"}
            value={values.IdEstado}
            onChange={handleChange}
            helperText={touched.IdEstado && errors.IdEstado}
            error={Boolean(touched.IdEstado && errors.IdEstado)}
            options={estados}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Contato"
            name="Contato"
            value={values.Contato}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Contato && errors.Contato}
            error={Boolean(touched.Contato && errors.Contato)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="E-mail"
            name="Email"
            type="email"
            value={values.Email}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Email && errors.Email}
            error={Boolean(touched.Email && errors.Email)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 9999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone"
            name="Telefone"
            value={values.Telefone}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Telefone && errors.Telefone}
            error={Boolean(touched.Telefone && errors.Telefone)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 99999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone Mensagem"
            name="Mensagem"
            value={values.Mensagem}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Mensagem && errors.Mensagem}
            error={Boolean(touched.Mensagem && errors.Mensagem)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Registro"
            name="Registro"
            value={values.Registro}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Registro && errors.Registro}
            error={Boolean(touched.Registro && errors.Registro)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Situação"
            labelId={"IdSituacao"}
            name={"IdSituacao"}
            value={values.IdSituacao}
            onChange={handleChange}
            helperText={touched.IdSituacao && errors.IdSituacao}
            error={Boolean(touched.IdSituacao && errors.IdSituacao)}
            options={[
              { Id: "1", Nome: "Ativo" },
              { Id: "2", Nome: "Inativo" },
              { Id: "3", Nome: "Suspenso" }
            ]}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            label="Observação"
            name="Observacao"
            value={values.Observacao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Observacao && errors.Observacao}
            error={Boolean(touched.Observacao && errors.Observacao)}
          />
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
