import { Form } from "formik";
import { Button, Grid, TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useThemeColor } from "../../../hooks/useThemeColor";
import TextFieldMask from "../../../components/TextFieldMask";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function PeopleFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldValue,
  estados,
  handleCep,
  permission,
  id
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-people">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Nome"
            name="Nome"
            value={values.Nome}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Nome && errors.Nome}
            error={Boolean(touched.Nome && errors.Nome)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"999.999.999-99"}
            margin="normal"
            variant="filled"
            fullWidth
            label="CPF"
            name="CNPJ"
            value={values.CNPJ}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.CNPJ && errors.CNPJ}
            error={Boolean(touched.CNPJ && errors.CNPJ)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="RG"
            name="IE"
            value={values.IE}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.IE && errors.IE}
            error={Boolean(touched.IE && errors.IE)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"99999-999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Cep"
            name="Cep"
            value={values.Cep}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Cep && errors.Cep}
            error={Boolean(touched.Cep && errors.Cep)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handleCep(values, setFieldValue)}>
                  <SearchIcon />
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Tipo Endereço"
            name="TipoEndereco"
            value={values.TipoEndereco}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.TipoEndereco && errors.TipoEndereco}
            error={Boolean(touched.TipoEndereco && errors.TipoEndereco)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Endereço"
            name="Endereco"
            value={values.Endereco}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Endereco && errors.Endereco}
            error={Boolean(touched.Endereco && errors.Endereco)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Número"
            name="Numero"
            value={values.Numero}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Numero && errors.Numero}
            error={Boolean(touched.Numero && errors.Numero)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Complemento"
            name="Complemento"
            value={values.Complemento}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Complemento && errors.Complemento}
            error={Boolean(touched.Complemento && errors.Complemento)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Bairro"
            name="Bairro"
            value={values.Bairro}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Bairro && errors.Bairro}
            error={Boolean(touched.Bairro && errors.Bairro)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cidade"
            name="Cidade"
            value={values.Cidade}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Cidade && errors.Cidade}
            error={Boolean(touched.Cidade && errors.Cidade)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Estado"
            labelId={"IdEstado"}
            name={"IdEstado"}
            value={values.IdEstado}
            onChange={handleChange}
            helperText={touched.IdEstado && errors.IdEstado}
            error={Boolean(touched.IdEstado && errors.IdEstado)}
            options={estados}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Contato"
            name="Contato"
            value={values.Contato}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Contato && errors.Contato}
            error={Boolean(touched.Contato && errors.Contato)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="E-mail"
            name="Email"
            type="email"
            value={values.Email}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Email && errors.Email}
            error={Boolean(touched.Email && errors.Email)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 9999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone Residencial"
            name="TelRes"
            value={values.TelRes}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.TelRes && errors.TelRes}
            error={Boolean(touched.TelRes && errors.TelRes)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 9999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone Comercial"
            name="TelCom"
            value={values.TelCom}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.TelCom && errors.TelCom}
            error={Boolean(touched.TelCom && errors.TelCom)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 99999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Celular"
            name="Celular"
            value={values.Celular}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Celular && errors.Celular}
            error={Boolean(touched.Celular && errors.Celular)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Profissão"
            name="Profissao"
            value={values.Profissao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Profissao && errors.Profissao}
            error={Boolean(touched.Profissao && errors.Profissao)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Data de Nascimento"
            name="DataNascimento"
            type="date"
            value={values.DataNascimento}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.DataNascimento && errors.DataNascimento}
            error={Boolean(touched.DataNascimento && errors.DataNascimento)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Situação"
            labelId={"IdSituacao"}
            name={"IdSituacao"}
            value={values.IdSituacao}
            onChange={handleChange}
            helperText={touched.IdSituacao && errors.IdSituacao}
            error={Boolean(touched.IdSituacao && errors.IdSituacao)}
            options={[
              { Id: "1", Nome: "Ativo" },
              { Id: "2", Nome: "Inativo" },
              { Id: "3", Nome: "Suspenso" }
            ]}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            label="Observação"
            name="Observacao"
            value={values.Observacao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Observacao && errors.Observacao}
            error={Boolean(touched.Observacao && errors.Observacao)}
          />
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
