import { Button, Step, StepLabel, Stepper } from "@mui/material";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { useThemeColor } from "../../hooks/useThemeColor";
import useUserStore from "../../store/userStore";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4"
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4"
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4"
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 30
  },
  "& .QontoStepIcon-circle": {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function ButtonCustom({ handleClick, children, ...rest }) {
  const { colors } = useThemeColor();

  return (
    <Button
      sx={{
        backgroundColor: colors.greenAccent[700],
        color: colors.grey[100],
        fontSize: "14px",
        fontWeight: "bold",
        padding: "10px 20px"
      }}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default function StepperContainer({ value, handleStep, imovelId }) {
  const { permissions } = useUserStore(state => state);

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Pessoa")[0] ?? null;

  return (
    <Stepper activeStep={value} alternativeLabel connector={<QontoConnector />}>
      <Step>
        <StepLabel StepIconComponent={QontoStepIcon}>
          <ButtonCustom handleClick={() => handleStep(0)}>
            Dados do Imóvel
          </ButtonCustom>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={QontoStepIcon}>
          <ButtonCustom handleClick={() => handleStep(1)} disabled={!imovelId}>
            Publicidade
          </ButtonCustom>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={QontoStepIcon}>
          <ButtonCustom
            handleClick={() => handleStep(2)}
            disabled={!imovelId || !permission?.Consultar}
          >
            Proprietário
          </ButtonCustom>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={QontoStepIcon}>
          <ButtonCustom handleClick={() => handleStep(3)} disabled={!imovelId}>
            Fotos
          </ButtonCustom>
        </StepLabel>
      </Step>
    </Stepper>
  );
}
