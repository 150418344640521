import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useUserProfileForm } from "../../hooks/pages/useUserProfileForm";
import UserProfileFormData from "./_partials/UserProfileFormData";

export default function UserProfileEdit() {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    handleImage,
    removeImage,
    photo_url
  } = useUserProfileForm();

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Meu Perfil" subtitle="Bem vindo ao meu perfil" />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <UserProfileFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                handleImage={handleImage}
                removeImage={removeImage}
                setFieldValue={setFieldValue}
                photo_url={photo_url}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
