import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function usePhotoFormEdit({ photo, imovelId, getPhotos, handleClose }) {
  const initialValues = {
    id: photo?.Id || "",
    Descricao: photo?.Descricao || "",
    ExibirSite: photo?.ExibirSite || false,
    Destaque: photo?.Destaque || false,
    Ordem: photo?.Ordem || "0",
    Site: photo?.Site || false
  };

  const validationSchema = Yup.object().shape({
    Descricao: Yup.string().required("Informe a descrição da foto"),
    ExibirSite: Yup.bool(),
    Destaque: Yup.bool(),
    Ordem: Yup.number().required("Informe a ordem da foto"),
    Site: Yup.bool()
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      await api.put(`/imoveis/fotos/${imovelId}/update-fotos/${id}`, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getPhotos();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
