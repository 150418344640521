import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Form, Formik } from "formik";
import { useThemeColor } from "../../hooks/useThemeColor";
import { useRegister } from "../../hooks/pages/useRegister";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from "@mui/material";

export default function Register() {
  const { colors } = useThemeColor();
  const { initialValues, validationSchema, onSubmit } = useRegister();

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: colors.primary[400],
          borderRadius: "1rem",
          p: 8
        }}
      >
        <Box sx={{ m: 1 }}>
          <img
            src="https://sgimais.com.br/images/logo.png"
            alt="logo"
            style={{ width: 100 }}
          />
        </Box>
        <Typography component="h1" variant="h5">
          Criar conta de teste
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting
          }) => {
            return (
              <Form>
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Nome"
                  name="Nome"
                  autoComplete="Nome"
                  autoFocus
                  color="success"
                  style={{
                    backgroundColor: colors.blueAccent[900]
                  }}
                  value={values.Nome}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Nome && errors.Nome}
                  error={Boolean(touched.Nome && errors.Nome)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  color="success"
                  style={{
                    backgroundColor: colors.blueAccent[900]
                  }}
                  value={values.email}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Telefone"
                  name="Telefone"
                  autoComplete="Telefone"
                  autoFocus
                  color="success"
                  style={{
                    backgroundColor: colors.blueAccent[900]
                  }}
                  value={values.Telefone}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Telefone && errors.Telefone}
                  error={Boolean(touched.Telefone && errors.Telefone)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  autoComplete="current-password"
                  style={{
                    backgroundColor: colors.blueAccent[900]
                  }}
                  value={values.password}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  name="password_confirmation"
                  label="Confirmar Senha"
                  type="password"
                  autoComplete="current-password"
                  style={{
                    backgroundColor: colors.blueAccent[900]
                  }}
                  value={values.password_confirmation}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={
                    touched.password_confirmation &&
                    errors.password_confirmation
                  }
                  error={Boolean(
                    touched.password_confirmation &&
                      errors.password_confirmation
                  )}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="aceiteTermos"
                        onChange={handleChange}
                        checked={Boolean(values.aceiteTermos)}
                        style={{ color: colors.grey[100] }}
                      />
                    }
                    label={
                      <span>
                        Concordo com os{" "}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          href={require("../../data/terms.pdf")}
                          target="_blank"
                          rel="noreferrer"
                        >
                          termos de uso e privacidade
                        </a>{" "}
                      </span>
                    }
                  />
                  {errors?.aceiteTermos && (
                    <FormHelperText style={{ color: "red" }}>
                      {errors?.aceiteTermos}
                    </FormHelperText>
                  )}
                </FormGroup>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: colors.blueAccent[600],
                    color: colors.grey[100],
                    fontWeight: "bold"
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Processando..." : "Teste por 5 dias"}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      sx={{ color: colors.grey[100] }}
                      component={RouterLink}
                      to="/"
                      variant="body2"
                    >
                      Já tem uma conta? Clique aqui
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Container>
  );
}
