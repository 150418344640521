import { Box, Card, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../../components/TableComponent";
import { useCollaborator } from "../../hooks/pages/useCollaborator";
import CollaboratorCreate from "./CollaboratorCreate";
import PaginationComponent from "../../components/PaginationComponent";
import ModalComponent from "../../components/ModalComponent";
import CollaboratorFilter from "./_partials/CollaboratorFilter";
import useUserStore from "../../store/userStore";

export default function CollaboratorList() {
  const { colors } = useThemeColor();
  const { permissions } = useUserStore(state => state);
  const {
    results,
    term,
    setTerm,
    handleSearch,
    open,
    handleOpen,
    collaborator,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete,
    teams,
    pessoas,
    IdEquipe,
    setIdEquipe,
    IdPessoa,
    setIdPessoa,
    IdTipoColaborador,
    setIdTipoColaborador
  } = useCollaborator();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Colaborador")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header
        title="Colaboradores"
        subtitle="Bem vindo a lista de colaboradores"
      />
      <Card
        sx={{
          backgroundColor: colors.primary[400]
        }}
      >
        <CollaboratorFilter
          onFilter={permission && permission?.Consultar ? handleSearch : false}
          search={term}
          setSearch={setTerm}
          onAdd={permission && permission?.Inserir ? () => handleOpen() : false}
          teams={teams}
          pessoas={pessoas}
          IdEquipe={IdEquipe}
          setIdEquipe={setIdEquipe}
          IdPessoa={IdPessoa}
          setIdPessoa={setIdPessoa}
          IdTipoColaborador={IdTipoColaborador}
          setIdTipoColaborador={setIdTipoColaborador}
        />
      </Card>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results?.data || []}
          head={["ID", "USUÁRIO", "TIPO COLABORADOR", "EQUIPE"]}
          columns={["Id", "pessoa.Nome", "tipoColaborador", "equipe.Nome"]}
          handleDelete={
            permission && permission?.Excluir ? item => handleShow(item) : false
          }
          handleEdit={
            permission && permission?.Atualizar
              ? item => handleEdit(item)
              : false
          }
          handleShow={item => handleEdit(item)}
        />

        {results?.total > 0 && (
          <PaginationComponent
            count={results.total}
            page={results.current_page}
            handleLimit={getList}
            handlePage={getList}
          />
        )}
      </Box>

      {open && (
        <CollaboratorCreate
          open={open}
          collaborator={collaborator}
          setOpen={() => handleOpen(false)}
          getList={getList}
          teams={teams}
          pessoas={pessoas}
        />
      )}

      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          title="Deletar Registro"
          maxWidth={"sm"}
          handleOpen={handleClose}
          handleClick={handleDelete}
          buttonOkColor="redAccent"
        >
          <Typography color="textPrimary" variant="h4">
            Tem certeza que deseja excluir? Esta ação não poderá ser revertida.
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
}
