import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material";
import { useThemeColor } from "../../../../hooks/useThemeColor";

export default function Features({ values, handleChange, tipoImovelSelected }) {
  const { colors } = useThemeColor();

  return (
    <React.Fragment>
      <Grid item md={12}>
        <Typography variant="h4">Características</Typography>
      </Grid>
      <Grid item md={12}>
        <FormControl component="fieldset">
          <FormGroup style={{ flexDirection: "column" }}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <FormControlLabel
                  label={"HeliPonto"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="HeliPonto"
                      onChange={handleChange}
                      checked={Boolean(values.HeliPonto)}
                    />
                  }
                />
              </Grid>

              <Grid item md={3}>
                <FormControlLabel
                  label={"Entrada Independente"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="EntradaIndependente"
                      onChange={handleChange}
                      checked={Boolean(values.EntradaIndependente)}
                    />
                  }
                />
              </Grid>

              <Grid item md={3}>
                <FormControlLabel
                  label={"Portaria 24h"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="P24h"
                      onChange={handleChange}
                      checked={Boolean(values.P24h)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Água"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Agua"
                      onChange={handleChange}
                      checked={Boolean(values.Agua)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Luz"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Luz"
                      onChange={handleChange}
                      checked={Boolean(values.Luz)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Infra Internet"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="InfraInternet"
                      onChange={handleChange}
                      checked={Boolean(values.InfraInternet)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Interfone"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Interfone"
                      onChange={handleChange}
                      checked={Boolean(values.Interfone)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Telefone"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Telefone"
                      onChange={handleChange}
                      checked={Boolean(values.Telefone)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Esquina"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Esquina"
                      onChange={handleChange}
                      checked={Boolean(values.Esquina)}
                    />
                  }
                />
              </Grid>

              <Grid item md={3}>
                <FormControlLabel
                  label={"Banco"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Banco"
                      onChange={handleChange}
                      checked={Boolean(values.Banco)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Café"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Cafe"
                      onChange={handleChange}
                      checked={Boolean(values.Cafe)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Restaurante"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Restaurante"
                      onChange={handleChange}
                      checked={Boolean(values.Restaurante)}
                    />
                  }
                />
              </Grid>
              {!["Terreno", "Galpao"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Elevador"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Elevador"
                        onChange={handleChange}
                        checked={Boolean(values.Elevador)}
                      />
                    }
                  />
                </Grid>
              )}

              {!["Residencial", "Rural", "Terreno", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Cabine Primaria"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="CabinePrimaria"
                        onChange={handleChange}
                        checked={Boolean(values.CabinePrimaria)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Rural", "Terreno", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Docas"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Docas"
                        onChange={handleChange}
                        checked={Boolean(values.Docas)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Rural", "Terreno", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Acesso à Caminhao"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="AcessoCaminhao"
                        onChange={handleChange}
                        checked={Boolean(values.AcessoCaminhao)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Rural", "Terreno", "Residencial", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Elevador de Carga"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="ElevadorCarga"
                        onChange={handleChange}
                        checked={Boolean(values.ElevadorCarga)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Rural", "Terreno", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Esteira Rolante"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="EsteiraRolante"
                        onChange={handleChange}
                        checked={Boolean(values.EsteiraRolante)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Rural", "Terreno", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Ponte Rolante"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="PonteRolante"
                        onChange={handleChange}
                        checked={Boolean(values.PonteRolante)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Rural", "Terreno", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Piso Elevado"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="PisoElevado"
                        onChange={handleChange}
                        checked={Boolean(values.PisoElevado)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Rural", "Terreno", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Vestiário"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Vestiario"
                        onChange={handleChange}
                        checked={Boolean(values.Vestiario)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Rural", "Terreno", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Wc"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Wc"
                        onChange={handleChange}
                        checked={Boolean(values.Wc)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Lavanderia"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Lavanderia"
                        onChange={handleChange}
                        checked={Boolean(values.Lavanderia)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Quadra Poli"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="QuadraPoli"
                        onChange={handleChange}
                        checked={Boolean(values.QuadraPoli)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Armário"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Armario"
                        onChange={handleChange}
                        checked={Boolean(values.Armario)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Despensa"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Despensa"
                        onChange={handleChange}
                        checked={Boolean(values.Despensa)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Armário Cozinha"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="ArmarioCozinha"
                        onChange={handleChange}
                        checked={Boolean(values.ArmarioCozinha)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Cozinha Equipada"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="CozinhaEquipada"
                        onChange={handleChange}
                        checked={Boolean(values.CozinhaEquipada)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Cozinha Planejada"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="CozinhaPlanejada"
                        onChange={handleChange}
                        checked={Boolean(values.CozinhaPlanejada)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Mobiliada"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Mobiliada"
                        onChange={handleChange}
                        checked={Boolean(values.Mobiliada)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Sala de Almoço"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="SalaAlmoco"
                        onChange={handleChange}
                        checked={Boolean(values.SalaAlmoco)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Sala Jantar"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="SalaJantar"
                        onChange={handleChange}
                        checked={Boolean(values.SalaJantar)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Sala Íntima"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="SalaIntima"
                        onChange={handleChange}
                        checked={Boolean(values.SalaIntima)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Sacada"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Sacada"
                        onChange={handleChange}
                        checked={Boolean(values.Sacada)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Hall de Entrada"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="HallEntrada"
                        onChange={handleChange}
                        checked={Boolean(values.HallEntrada)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Tv à Cabo"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="TvCabo"
                        onChange={handleChange}
                        checked={Boolean(values.TvCabo)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Jardim"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Jardim"
                        onChange={handleChange}
                        checked={Boolean(values.Jardim)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Geminada"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Geminada"
                        onChange={handleChange}
                        checked={Boolean(values.Geminada)}
                      />
                    }
                  />
                </Grid>
              )}
              {["NAO_SALVOU_EM_TERRENO"].includes(
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Guarita"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Guarita"
                        onChange={handleChange}
                        checked={Boolean(values.Guarita)}
                      />
                    }
                  />
                </Grid>
              )}

              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Quintal"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Quintal"
                        onChange={handleChange}
                        checked={Boolean(values.Quintal)}
                      />
                    }
                  />
                </Grid>
              )}
              <Grid item md={3}>
                <FormControlLabel
                  label={"Condomínio Fechado"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="CondominioFechado"
                      onChange={handleChange}
                      checked={Boolean(values.CondominioFechado)}
                    />
                  }
                />
              </Grid>
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Varanda"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Varanda"
                        onChange={handleChange}
                        checked={Boolean(values.Varanda)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Play Ground"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="PlayGround"
                        onChange={handleChange}
                        checked={Boolean(values.PlayGround)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Sala Ginástica"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="SalaGinastica"
                        onChange={handleChange}
                        checked={Boolean(values.SalaGinastica)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Lareira"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Lareira"
                        onChange={handleChange}
                        checked={Boolean(values.Lareira)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Copa"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Copa"
                        onChange={handleChange}
                        checked={Boolean(values.Copa)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Salão Festa"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="SalaoFesta"
                        onChange={handleChange}
                        checked={Boolean(values.SalaoFesta)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Salão Jogos"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="SalaoJogos"
                        onChange={handleChange}
                        checked={Boolean(values.SalaoJogos)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Piscina"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Piscina"
                        onChange={handleChange}
                        checked={Boolean(values.Piscina)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Adega"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Adega"
                        onChange={handleChange}
                        checked={Boolean(values.Adega)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Churrasqueira"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Churrasqueira"
                        onChange={handleChange}
                        checked={Boolean(values.Churrasqueira)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Sauna"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Sauna"
                        onChange={handleChange}
                        checked={Boolean(values.Sauna)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Espaço Gourmet"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="EspacoGourmet"
                        onChange={handleChange}
                        checked={Boolean(values.EspacoGourmet)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Jardim de Inverno"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="JardimInverno"
                        onChange={handleChange}
                        checked={Boolean(values.JardimInverno)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Children Care"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="ChildrenCare"
                        onChange={handleChange}
                        checked={Boolean(values.ChildrenCare)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Edícula"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Edicula"
                        onChange={handleChange}
                        checked={Boolean(values.Edicula)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Galpao", "Comercial"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Escritório"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Escritorio"
                        onChange={handleChange}
                        checked={Boolean(values.Escritorio)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Àrea de Serviço"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="AreaServico"
                        onChange={handleChange}
                        checked={Boolean(values.AreaServico)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Lavabo"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Lavabo"
                        onChange={handleChange}
                        checked={Boolean(values.Lavabo)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Closet"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Closet"
                        onChange={handleChange}
                        checked={Boolean(values.Closet)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Hidro"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Hidro"
                        onChange={handleChange}
                        checked={Boolean(values.Hidro)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Pomar"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Pomar"
                        onChange={handleChange}
                        checked={Boolean(values.Pomar)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Rio"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Rio"
                        onChange={handleChange}
                        checked={Boolean(values.Rio)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Lago"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Lago"
                        onChange={handleChange}
                        checked={Boolean(values.Lago)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Casa Caseiro"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="CasaCaseiro"
                        onChange={handleChange}
                        checked={Boolean(values.CasaCaseiro)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Curral"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Curral"
                        onChange={handleChange}
                        checked={Boolean(values.Curral)}
                      />
                    }
                  />
                </Grid>
              )}
              {!["Residencial", "Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Baia"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Baia"
                        onChange={handleChange}
                        checked={Boolean(values.Baia)}
                      />
                    }
                  />
                </Grid>
              )}

              {["Galpao", "Comercial"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Business Center"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="BusinessCenter"
                        onChange={handleChange}
                        checked={Boolean(values.BusinessCenter)}
                      />
                    }
                  />
                </Grid>
              )}

              {["Galpao", "Comercial"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Auditório"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Auditorio"
                        onChange={handleChange}
                        checked={Boolean(values.Auditorio)}
                      />
                    }
                  />
                </Grid>
              )}

              {["Galpao", "Comercial"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Divisórias"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Divisorias"
                        onChange={handleChange}
                        checked={Boolean(values.Divisorias)}
                      />
                    }
                  />
                </Grid>
              )}

              {["Rural", "Residencial"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Poço Artesiano"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="PocoArtesiano"
                        onChange={handleChange}
                        checked={Boolean(values.PocoArtesiano)}
                      />
                    }
                  />
                </Grid>
              )}

              {!["Terreno"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Luminárias"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Luminarias"
                        onChange={handleChange}
                        checked={Boolean(values.Luminarias)}
                      />
                    }
                  />
                </Grid>
              )}

              {!["Terreno"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Forro"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Forro"
                        onChange={handleChange}
                        checked={Boolean(values.Forro)}
                      />
                    }
                  />
                </Grid>
              )}

              {!["Terreno"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Ar Condicionado"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="ArCondicionado"
                        onChange={handleChange}
                        checked={Boolean(values.ArCondicionado)}
                      />
                    }
                  />
                </Grid>
              )}

              {!["Rural", "Terreno", "Comercial", "Galpao"].includes(
                tipoImovelSelected?.Grupo
              ) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Terraço"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Terraco"
                        onChange={handleChange}
                        checked={Boolean(values.Terraco)}
                      />
                    }
                  />
                </Grid>
              )}

              {["Galpao"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Refeitório"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Refeitorio"
                        onChange={handleChange}
                        checked={Boolean(values.Refeitorio)}
                      />
                    }
                  />
                </Grid>
              )}

              {["Galpao"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Ambulatório"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Ambulatorio"
                        onChange={handleChange}
                        checked={Boolean(values.Ambulatorio)}
                      />
                    }
                  />
                </Grid>
              )}

              <Grid item md={3}>
                <FormControlLabel
                  label={"Em Exposição"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="EmExposicao"
                      onChange={handleChange}
                      checked={Boolean(values.EmExposicao)}
                    />
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel
                  label={"Plantão"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Plantao"
                      onChange={handleChange}
                      checked={Boolean(values.Plantao)}
                    />
                  }
                />
              </Grid>

              <Grid item md={3}>
                <FormControlLabel
                  label={"Aceita Permuta"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Permuta"
                      onChange={handleChange}
                      checked={Boolean(values.Permuta)}
                    />
                  }
                />
              </Grid>

              {["Residencial"].includes(tipoImovelSelected?.Grupo) && (
                <Grid item md={3}>
                  <FormControlLabel
                    label={"Minha Casa Minha Vida"}
                    control={
                      <Checkbox
                        style={{ color: colors.grey[100] }}
                        name="Mcmv"
                        onChange={handleChange}
                        checked={Boolean(values.Mcmv)}
                      />
                    }
                  />
                </Grid>
              )}
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}
