import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useCollaboratorForm({
  collaborator,
  getCollaborators,
  handleClose
}) {
  const initialValues = {
    id: collaborator?.Id || "",
    IdTipoColaborador: collaborator?.IdTipoColaborador || "0",
    IdPessoa: collaborator?.IdPessoa || "",
    IdEquipe: collaborator?.IdEquipe || ""
  };

  const validationSchema = Yup.object().shape({
    IdTipoColaborador: Yup.string().required("Informe o colaborador"),
    IdPessoa: Yup.string().required("Informe a pessoa"),
    IdEquipe: Yup.string().required("Informe a equipe")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id ? `/colaboradores/${id}` : `/colaboradores`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getCollaborators();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
