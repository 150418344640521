import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useTeamForm({ team, getTeams, handleClose }) {
  const initialValues = {
    id: team?.Id || "",
    Nome: team?.Nome || ""
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Informe o nome")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id ? `/equipes/${id}` : `/equipes`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getTeams();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
