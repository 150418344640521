import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function useLogin() {
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: ""
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Informe seu e-mail"),
    password: Yup.string().required("Informe sua senha")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { data } = await api.post("/auth/login", {
        email: values.email,
        password: values.password
      });

      enqueueSnackbar("Login realizado com sucesso", {
        variant: "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      sessionStorage.setItem("authUser", JSON.stringify(data?.data));

      navigate("/dashboard");
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
