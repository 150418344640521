import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Box, FormControl } from "@mui/material";

const ReactSelectAsync = ({
  handleChange,
  handleInputChange,
  defaultOptions,
  error,
  helperText,
  placeholder,
  isDisabled,
  isClearable,
  isLoading,
  valueName,
  labelName,
  value
}) => {
  return (
    <Box>
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ lineHeight: "2.6" }}
        style={
          error ? { border: "1px solid #f44336", borderRadius: "3px" } : {}
        }
      >
        <AsyncSelect
          cacheOptions
          isLoading={isLoading}
          isDisabled={isDisabled}
          isClearable={isClearable}
          onChange={handleChange}
          placeholder={placeholder}
          defaultOptions={defaultOptions}
          loadOptions={handleInputChange}
          menuPortalTarget={document.body}
          value={value}
          getOptionValue={option => option[valueName]}
          getOptionLabel={option => option[labelName]}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999, color: "black" })
          }}
          noOptionsMessage={() => "Nenhum resultado encontrado"}
          loadingMessage={() => "Carregando..."}
        />
      </FormControl>
      {error && <helperText>{helperText}</helperText>}
    </Box>
  );
};

ReactSelectAsync.defaultProps = {
  placeholder: "Selecione um item",
  isLoading: false,
  isDisabled: false,
  isClearable: false,
  valueName: "id",
  labelName: "name"
};

ReactSelectAsync.propTypes = {
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  defaultOptions: PropTypes.array,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  valueName: PropTypes.string,
  labelName: PropTypes.string,
  value: PropTypes.any
};

export default ReactSelectAsync;
