import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useSignatureForm } from "../../hooks/pages/useSignatureForm";
import SignatureFormData from "./_partials/SignatureFormData";
import useUserStore from "../../store/userStore";

export default function SignatureCreate() {
  const { permissions } = useUserStore(state => state);
  const {
    initialValues,
    validationSchema,
    onSubmit,
    portais,
    handleValueChange
  } = useSignatureForm();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Assinatura")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Assinatura" subtitle="Bem vindo ao criar assinatura" />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <SignatureFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                portais={portais}
                handleValueChange={handleValueChange}
                permission={permission}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
