import React from "react";
import { TextField } from "@mui/material";
import { formatCurrency } from "../../helpers";

export default function TextFieldMoney({ onChange, ...props }) {
  const handleChange = event => {
    const inputValue = event.target.value;

    const formattedValue = formatCurrency(inputValue);

    onChange(formattedValue, inputValue.replace(/\D/g, ""));
  };

  return <TextField onChange={handleChange} {...props} />;
}
