import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { useThemeColor } from "../../hooks/useThemeColor";

export default function ModalComponent({
  open,
  title,
  maxWidth,
  fullWidth,
  btClose,
  btOk,
  handleOpen,
  handleClick,
  iconCancel,
  iconConfirm,
  scroll,
  disabledClick,
  buttonOkType,
  buttonForm,
  buttonOkColor,
  children
}) {
  const { colors } = useThemeColor();

  return (
    <Dialog
      open={open}
      scroll={scroll}
      onClose={handleOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogTitle sx={{ fontSize: 20, fontWeight: "bold" }}>
        {title}
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>{children}</DialogContent>
      <DialogActions>
        <Button
          sx={{
            backgroundColor: colors.grey[200],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            ":hover": {
              color: colors.grey[100]
            }
          }}
          onClick={handleOpen}
          startIcon={iconCancel ? iconCancel : null}
        >
          {btClose}
        </Button>

        {handleClick &&
          (buttonOkType === "submit" ? (
            <Button
              type="submit"
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              form={buttonForm}
              disabled={disabledClick}
              startIcon={iconConfirm ? iconConfirm : null}
            >
              {btOk}
            </Button>
          ) : (
            <Button
              type="button"
              sx={{
                backgroundColor: colors[buttonOkColor][700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={handleClick}
              disabled={disabledClick}
              startIcon={iconConfirm ? iconConfirm : null}
            >
              {btOk}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  );
}

ModalComponent.defaultProps = {
  open: false,
  title: "Modal Title",
  maxWidth: "sm",
  btClose: "Fechar",
  btOk: "Ok",
  handleOpen: () => {},
  handleClick: () => {},
  iconCancel: null,
  iconConfirm: null,
  scroll: "body",
  disabledClick: false,
  fullWidth: true,
  buttonOkType: "button",
  buttonForm: "",
  buttonOkColor: "greenAccent"
};

ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  btClose: PropTypes.string,
  btOk: PropTypes.string,
  handleOpen: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
  iconCancel: PropTypes.object,
  iconConfirm: PropTypes.object,
  scroll: PropTypes.oneOf(["body", "paper"]),
  disabledClick: PropTypes.bool,
  fullWidth: PropTypes.bool,
  buttonOkType: PropTypes.oneOf(["button", "submit"]),
  buttonForm: PropTypes.string,
  buttonOkColor: PropTypes.oneOf([
    "grey",
    "primary",
    "greenAccent",
    "redAccent",
    "blueAccent"
  ])
};
