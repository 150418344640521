import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../components/ModalComponent";
import SelectInputSimple from "../../components/SelectInputSimple";
import { useCollaboratorForm } from "../../hooks/pages/useCollaboratorForm";
import { typesCollaborator } from "../../helpers";

export default function CollaboratorCreate({
  open,
  setOpen,
  collaborator,
  getList,
  teams,
  pessoas
}) {
  const { initialValues, validationSchema, onSubmit } = useCollaboratorForm({
    collaborator,
    getCollaborators: getList,
    handleClose: setOpen
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, isSubmitting }) => {
          return (
            <Form id="form-collaborator">
              <ModalComponent
                open={open}
                title="Dados do Colaborador"
                maxWidth={"sm"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-collaborator"
              >
                <SelectInputSimple
                  label="Tipo Colaborador"
                  labelId={"IdTipoColaborador"}
                  name={"IdTipoColaborador"}
                  value={values.IdTipoColaborador}
                  onChange={handleChange}
                  helperText={
                    touched.IdTipoColaborador && errors.IdTipoColaborador
                  }
                  error={Boolean(
                    touched.IdTipoColaborador && errors.IdTipoColaborador
                  )}
                  options={typesCollaborator}
                />

                <SelectInputSimple
                  label="Pessoa"
                  labelId={"IdPessoa"}
                  name={"IdPessoa"}
                  value={values.IdPessoa}
                  onChange={handleChange}
                  helperText={touched.IdPessoa && errors.IdPessoa}
                  error={Boolean(touched.IdPessoa && errors.IdPessoa)}
                  options={pessoas}
                />

                <SelectInputSimple
                  label="Equipe"
                  labelId={"IdEquipe"}
                  name={"IdEquipe"}
                  value={values.IdEquipe}
                  onChange={handleChange}
                  helperText={touched.IdEquipe && errors.IdEquipe}
                  error={Boolean(touched.IdEquipe && errors.IdEquipe)}
                  options={teams}
                />
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
