import { Box, Container, Grid, Typography } from "@mui/material";
import { useThemeColor } from "../hooks/useThemeColor";
import { Email, Phone, WhatsApp } from "@mui/icons-material";

export default function AuthLayout({ children }) {
  return (
    <div className="app" style={{ flexDirection: "column" }}>
      <main className="content">{children}</main>
      <Footer />
    </div>
  );
}

const Footer = () => {
  const { colors } = useThemeColor();

  return (
    <Box
      component={"footer"}
      sx={{
        width: "100%",
        backgroundColor: colors.greenAccent[600],
        paddingTop: "1rem",
        paddingBottom: "1rem",
        position: "fixed",
        left: 0,
        bottom: 0
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Box display={"flex"} flexWrap={"wrap"} gap={3}>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2
                }}
                color="textSecondary"
                variant="subtitle1"
              >
                <Phone /> {`Central: (11) 2951-3203`}
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2
                }}
                color="textSecondary"
                variant="subtitle1"
              >
                <WhatsApp /> {`Suporte: (11) 96081-6663`}
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2
                }}
                color="textSecondary"
                variant="subtitle1"
              >
                <Email />{" "}
                <a
                  href="mailto:suporte@sgimais.com.br"
                  style={{ color: "white" }}
                >
                  {" "}
                  suporte@sgimais.com.br
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
