import React from "react";
import { Grid, TextField } from "@mui/material";

export default function Residential01({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting
}) {
  return (
    <React.Fragment>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Tipo de Piso"
          name="TipoPiso"
          value={values.TipoPiso}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.TipoPiso && errors.TipoPiso}
          error={Boolean(touched.TipoPiso && errors.TipoPiso)}
        />
      </Grid>
    </React.Fragment>
  );
}
