import { useNavigate } from "react-router-dom";
import { Box, Card, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../../components/TableComponent";
import { useUser } from "../../hooks/pages/useUser";
import ModalComponent from "../../components/ModalComponent";
import PaginationComponent from "../../components/PaginationComponent";
import UserFilter from "./_partials/UserFilter";
import useUserStore from "../../store/userStore";

export default function UserList() {
  const { colors } = useThemeColor();
  const { permissions } = useUserStore(state => state);
  const navigate = useNavigate();
  const {
    results,
    term,
    setTerm,
    handleSearch,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete,
    getList,
    IdFilial,
    setIdFilial,
    IdPerfil,
    setIdPerfil,
    filiais,
    perfis
  } = useUser();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Usuario")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="Usuários" subtitle="Bem vindo a lista de usuários" />
      <Card
        sx={{
          backgroundColor: colors.primary[400]
        }}
      >
        <UserFilter
          onFilter={permission && permission?.Consultar ? handleSearch : false}
          search={term}
          setSearch={setTerm}
          onAdd={
            permission && permission?.Inserir
              ? () => navigate("/usuarios/create")
              : false
          }
          IdFilial={IdFilial}
          setIdFilial={setIdFilial}
          IdPerfil={IdPerfil}
          setIdPerfil={setIdPerfil}
          filiais={filiais}
          perfis={perfis}
        />
      </Card>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results?.data || []}
          head={["ID", "NOME", "EMAIL", "FILIAL", "PERFIL"]}
          columns={[
            "Id",
            "Nome",
            "email",
            "filial.NomeFantasia",
            "perfil.Nome"
          ]}
          handleDelete={
            permission && permission?.Excluir ? item => handleShow(item) : false
          }
          handleEdit={
            permission && permission?.Atualizar
              ? ({ Id }) => navigate(`/usuarios/edit/${Id}`)
              : false
          }
          handleShow={({ Id }) => navigate(`/usuarios/edit/${Id}`)}
        />

        {results?.total > 0 && (
          <PaginationComponent
            count={results.total}
            page={results.current_page}
            handleLimit={getList}
            handlePage={getList}
          />
        )}
      </Box>

      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          title="Deletar Registro"
          maxWidth={"sm"}
          handleOpen={handleClose}
          handleClick={handleDelete}
          buttonOkColor="redAccent"
        >
          <Typography color="textPrimary" variant="h4">
            Tem certeza que deseja excluir? Esta ação não poderá ser revertida.
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
}
