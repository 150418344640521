import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import useLoadingStore from "../../store/loading";
import useModalStore from "../../store/modalStore";
import { api } from "../../services/api";

export function useCollaborator() {
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState();
  const [term, setTerm] = useState("");
  const [collaborator, setCollaborator] = useState({});
  const [teams, setTeams] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [IdEquipe, setIdEquipe] = useState("");
  const [IdPessoa, setIdPessoa] = useState("");
  const [IdTipoColaborador, setIdTipoColaborador] = useState("");
  const { setLoadingTable } = useLoadingStore(state => state);
  const { modalOpen, showModal, hideModal } = useModalStore(state => state);

  useEffect(() => {
    getTeams();
    getPessoas();
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = () => {
    const filters = {};
    if (term?.length > 0) filters.term = term;
    if (IdEquipe) filters.IdEquipe = IdEquipe;
    if (IdPessoa) filters.IdPessoa = IdPessoa;
    if (IdTipoColaborador) filters.IdTipoColaborador = IdTipoColaborador;
    return filters;
  };

  const getList = async (page = 1, limit = 10) => {
    try {
      setLoadingTable(true);

      const { data } = await api.get(`/colaboradores`, {
        params: {
          filters: handleFilter(),
          page,
          limit
        }
      });

      setResults(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingTable(false);
    }
  };

  const getTeams = async () => {
    try {
      const { data } = await api.get("/equipes", {
        params: {
          paginate: 0
        }
      });

      setTeams(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getPessoas = async () => {
    try {
      const { data } = await api.get("/pessoas", {
        params: {
          paginate: 0
        }
      });

      setPessoas(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const handleSearch = () => {
    getList();
  };

  const handleOpen = (status = true) => {
    setOpen(status);
    if (!status) setCollaborator({});
  };

  const handleEdit = item => {
    setCollaborator(item);
    handleOpen();
  };

  const handleShow = item => {
    setCollaborator(item);
    showModal();
  };

  const handleClose = () => {
    setCollaborator(null);
    hideModal();
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/colaboradores/${collaborator.Id}`);
      handleClose();
      getList();
      enqueueSnackbar("Registro deletado com sucesso", {
        variant: "success"
      });
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    results,
    term,
    setTerm,
    handleSearch,
    open,
    handleOpen,
    collaborator,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    hideModal,
    handleClose,
    handleDelete,
    teams,
    pessoas,
    IdEquipe,
    setIdEquipe,
    IdPessoa,
    setIdPessoa,
    IdTipoColaborador,
    setIdTipoColaborador
  };
}
