import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import { useThemeColor } from "../../hooks/useThemeColor";

export default function GroupRadioComponent({
  label,
  name,
  value,
  onChange,
  arrayNumbers = []
}) {
  const { colors } = useThemeColor();

  return (
    <FormControl>
      <FormLabel style={{ color: colors.grey[100] }}>{label}</FormLabel>
      <RadioGroup row name={name} value={value} onChange={onChange}>
        {arrayNumbers.map(i => (
          <FormControlLabel
            key={`name_${i}`}
            value={i}
            control={<Radio style={{ color: colors.grey[100] }} />}
            label={`+${i}`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
