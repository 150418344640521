import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../../components/ModalComponent";
import FilesDropzone from "../../../components/FilesDropezone";
import { usePhotoForm } from "../../../hooks/pages/usePhotoForm";
import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";

export default function PhotoCreate({ open, setOpen, imovelId, getList }) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    handleLogo,
    loadingUpload,
    progress
  } = usePhotoForm({
    imovelId,
    getPhotos: getList,
    handleClose: setOpen
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isSubmitting }) => {
          return (
            <Form id="form-photos">
              <ModalComponent
                open={open}
                title="Envie aqui as fotos do imóvel"
                maxWidth={"xl"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-photos"
              >
                {loadingUpload ? (
                  <LinearProgressWithLabel
                    title={"Aguarde, estamos processando o upload das imagens"}
                    value={progress}
                  />
                ) : (
                  <FilesDropzone
                    multiple
                    preview={values.images && values.images?.length > 0}
                    title={"Upload Imagem"}
                    files={values.images}
                    field={"images"}
                    setFiles={event =>
                      handleLogo(event, "images", setFieldValue)
                    }
                    subtitle={"Arraste e solte"}
                  />
                )}
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
