import { Form, Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useImovelForm } from "../../../hooks/pages/useImovelForm";
import { useThemeColor } from "../../../hooks/useThemeColor";
import Commercialization from "./_partials/Commercialization";
import Residential01 from "./_partials/Residential01";
import Residential02 from "./_partials/Residential02";
import Adittional from "./_partials/Adittional";
import Rural from "./_partials/Rural";
import Galpao from "./_partials/Galpao";
import useLoadingStore from "../../../store/loading";
import TextFieldMask from "../../../components/TextFieldMask";
import SelectInputSimple from "../../../components/SelectInputSimple";
import Features from "./_partials/Features";
import { ScrollToFieldError } from "../../../components/ScrollToFieldFormikError";

export default function ImovelFormData({ imovelId, handleStep, permission }) {
  const { colors } = useThemeColor();
  const {
    initialValues,
    validationSchema,
    onSubmit,
    tiposImovel,
    estados,
    handleCep,
    unidades,
    colaboradores,
    filiais,
    zoneamentos,
    tipoImovelSelected,
    setTipoImovelSelected,
    handleValueChange,
    lastImovel
  } = useImovelForm({
    imovelId,
    handleStep
  });
  const { loadingCep } = useLoadingStore(state => state);

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form id="form-imovel">
              <ScrollToFieldError />
              <Grid container spacing={2}>
                <Grid container item md={12} xs={12} justifyContent="flex-end">
                  {!imovelId ? (
                    <>
                      {permission && permission?.Inserir === 1 && (
                        <Button
                          sx={{
                            backgroundColor: colors.greenAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                          }}
                          type="submit"
                        >
                          Salvar
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {permission && permission?.Atualizar === 1 && (
                        <Button
                          sx={{
                            backgroundColor: colors.greenAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                          }}
                          type="submit"
                        >
                          Salvar
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Typography variant="h4">Padrão</Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <SelectInputSimple
                    label="Tipo do Imóvel"
                    labelId={"IdTipoImovel"}
                    name={"IdTipoImovel"}
                    value={values.IdTipoImovel}
                    onChange={event => {
                      const { value } = event.target;
                      setFieldValue("IdTipoImovel", value);
                      setTipoImovelSelected(
                        tiposImovel.filter(
                          i => Number(i.Id) === Number(value)
                        )[0] || null
                      );
                    }}
                    helperText={touched.IdTipoImovel && errors.IdTipoImovel}
                    error={Boolean(touched.IdTipoImovel && errors.IdTipoImovel)}
                    options={tiposImovel}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Localização"
                    name="Localizacao"
                    value={values.Localizacao}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.Localizacao && errors.Localizacao}
                    error={Boolean(touched.Localizacao && errors.Localizacao)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <SelectInputSimple
                    label="Transação"
                    labelId={"IdTransacao"}
                    name={"IdTransacao"}
                    value={values.IdTransacao}
                    onChange={handleChange}
                    helperText={touched.IdTransacao && errors.IdTransacao}
                    error={Boolean(touched.IdTransacao && errors.IdTransacao)}
                    options={[
                      { Id: "1", Nome: "Alugar" },
                      { Id: "2", Nome: "Vender" },
                      { Id: "3", Nome: "Vender/Alugar" },
                      { Id: "4", Nome: "Temporada" }
                    ]}
                  />
                </Grid>
                {unidades?.length > 0 && (
                  <Grid item md={3} xs={12}>
                    <SelectInputSimple
                      label="Empreendimento"
                      labelId={"IdUnidade"}
                      name={"IdUnidade"}
                      value={values.IdUnidade}
                      onChange={handleChange}
                      helperText={touched.IdUnidade && errors.IdUnidade}
                      error={Boolean(touched.IdUnidade && errors.IdUnidade)}
                      options={unidades}
                    />
                  </Grid>
                )}
                <Grid item md={12}>
                  <Typography variant="h4">Dados de endereço</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextFieldMask
                    mask={"99999-999"}
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Cep"
                    name="Cep"
                    value={values.Cep}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={touched.Cep && errors.Cep}
                    error={Boolean(touched.Cep && errors.Cep)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCep(values, setFieldValue)}
                        >
                          {loadingCep ? (
                            <CircularProgress size={20} color="secondary" />
                          ) : (
                            <SearchIcon />
                          )}
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Tipo de endereço"
                    name="TipoEndereco"
                    value={values.TipoEndereco}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.TipoEndereco && errors.TipoEndereco}
                    error={Boolean(touched.TipoEndereco && errors.TipoEndereco)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Endereço"
                    name="Endereco"
                    value={values.Endereco}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.Endereco && errors.Endereco}
                    error={Boolean(touched.Endereco && errors.Endereco)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Número"
                    name="Numero"
                    value={values.Numero}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.Numero && errors.Numero}
                    error={Boolean(touched.Numero && errors.Numero)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Complemento"
                    name="Complemento"
                    value={values.Complemento}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.Complemento && errors.Complemento}
                    error={Boolean(touched.Complemento && errors.Complemento)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Bairro"
                    name="Bairro"
                    value={values.Bairro}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.Bairro && errors.Bairro}
                    error={Boolean(touched.Bairro && errors.Bairro)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Cidade"
                    name="Cidade"
                    value={values.Cidade}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.Cidade && errors.Cidade}
                    error={Boolean(touched.Cidade && errors.Cidade)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <SelectInputSimple
                    label="Estado"
                    labelId={"IdEstado"}
                    name={"IdEstado"}
                    value={values.IdEstado}
                    onChange={handleChange}
                    helperText={touched.IdEstado && errors.IdEstado}
                    error={Boolean(touched.IdEstado && errors.IdEstado)}
                    options={estados}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Latitude"
                    name="Latitude"
                    value={values.Latitude}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.Latitude && errors.Latitude}
                    error={Boolean(touched.Latitude && errors.Latitude)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Longitude"
                    name="Longitude"
                    value={values.Longitude}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.Longitude && errors.Longitude}
                    error={Boolean(touched.Longitude && errors.Longitude)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    margin="normal"
                    variant="filled"
                    fullWidth
                    label="Link do vídeo"
                    name="LinkVideo"
                    value={values.LinkVideo}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    helperText={touched.LinkVideo && errors.LinkVideo}
                    error={Boolean(touched.LinkVideo && errors.LinkVideo)}
                  />
                </Grid>
                {values.IdTipoImovel && values.IdTipoImovel > 0 && (
                  <>
                    <Commercialization
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      isSubmitting={isSubmitting}
                      zoneamentos={zoneamentos}
                      handleValueChange={handleValueChange}
                      setFieldValue={setFieldValue}
                      tipoImovelSelected={tipoImovelSelected}
                    />
                    {["Residencial", "Rural"].includes(
                      tipoImovelSelected?.Grupo
                    ) && (
                      <>
                        <Residential01
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          isSubmitting={isSubmitting}
                          tipoImovelSelected={tipoImovelSelected}
                        />
                        <Residential02
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          isSubmitting={isSubmitting}
                          tipoImovelSelected={tipoImovelSelected}
                        />
                      </>
                    )}
                    {["Galpao"].includes(tipoImovelSelected?.Grupo) && (
                      <Galpao
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isSubmitting={isSubmitting}
                        tipoImovelSelected={tipoImovelSelected}
                      />
                    )}
                    {["Rural"].includes(tipoImovelSelected?.Grupo) && (
                      <Rural
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isSubmitting={isSubmitting}
                        tipoImovelSelected={tipoImovelSelected}
                      />
                    )}
                  </>
                )}
                <Features
                  values={values}
                  handleChange={handleChange}
                  tipoImovelSelected={tipoImovelSelected}
                />
                <Adittional
                  colaboradores={colaboradores}
                  filiais={filiais}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isSubmitting={isSubmitting}
                  tipoImovelSelected={tipoImovelSelected}
                  lastImovel={lastImovel}
                  setFieldValue={setFieldValue}
                />
                <Grid container spacing={2}>
                  <Grid
                    container
                    item
                    md={12}
                    xs={12}
                    justifyContent="flex-end"
                  >
                    {!imovelId ? (
                      <>
                        {permission && permission?.Inserir === 1 && (
                          <Button
                            sx={{
                              backgroundColor: colors.greenAccent[700],
                              color: colors.grey[100],
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "10px 20px"
                            }}
                            type="submit"
                          >
                            Salvar
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {permission && permission?.Atualizar === 1 && (
                          <Button
                            sx={{
                              backgroundColor: colors.greenAccent[700],
                              color: colors.grey[100],
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "10px 20px"
                            }}
                            type="submit"
                          >
                            Salvar
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
