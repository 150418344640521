import { useNavigate } from "react-router-dom";
import { Box, Card, Typography } from "@mui/material";
import Header from "../../components/Header";
import TableComponent from "../../components/TableComponent";
import ImovelFilter from "./_partials/ImovelFilter";
import { useImovel } from "../../hooks/pages/useImovel";
import { useThemeColor } from "../../hooks/useThemeColor";
import PaginationComponent from "../../components/PaginationComponent";
import ModalComponent from "../../components/ModalComponent";
import useUserStore from "../../store/userStore";

export default function ImovelList() {
  const { colors } = useThemeColor();
  const { permissions } = useUserStore(state => state);
  const navigate = useNavigate();
  const {
    results,
    term,
    setTerm,
    handleSearch,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete,
    getList,
    idtipoimovel,
    idtransacao,
    condominiofechado,
    idtopografia,
    valorinicial,
    valorfinal,
    areaconstruidainicial,
    areaconstruidafinal,
    areaterrenoinicial,
    areaterrenofinal,
    areautilinicial,
    areautilfinal,
    dormitorios,
    suites,
    vagas,
    banheiros,
    areagalpaoinicial,
    areagalpaofinal,
    areaescritorioinicial,
    areaescritoriofinal,
    areafabrilinicial,
    valormetroinicial,
    valormetrofinal,
    areafabrilfinal,
    setIdtipoimovel,
    setIdtransacao,
    setCondominiofechado,
    setIdtopografia,
    setValorinicial,
    setValorfinal,
    setAreaconstruidainicial,
    setAreaconstruidafinal,
    setAreaterrenoinicial,
    setAreaterrenofinal,
    setAreautilinicial,
    setAreautilfinal,
    setDormitorios,
    setSuites,
    setVagas,
    setBanheiros,
    setAreagalpaoinicial,
    setAreagalpaofinal,
    setAreaescritorioinicial,
    setAreaescritoriofinal,
    setAreafabrilinicial,
    setValormetroinicial,
    setValormetrofinal,
    setAreafabrilfinal,
    bairros,
    handleDeleteItem,
    handleAddItem,
    onChangeSpeaker,
    handleSelectSpeaker,
    idFilial,
    setIdFilial,
    somenteMatriz,
    setSomenteMatriz,
    referencia,
    setReferencia,
    grupo,
    setGrupo,
    IdUnidade,
    setIdUnidade,
    IdObra,
    setIdObra,
    IdSituacao,
    setIdSituacao,
    permuta,
    setPermuta,
    Mcmv,
    setMcmv,
    setEmptyValues,
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    oferta,
    setOferta,
    IdAssinatura,
    setIdAssinatura,
    comFoto,
    semFoto,
    setComFoto,
    setSemFoto,
    banner,
    setBanner,
    destaque,
    setDestaque,
    superDestaque,
    setSuperDestaque,
    lancamento,
    setLancamento
  } = useImovel();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Imovel")[0] ?? null;

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="Imóveis" subtitle="Bem vindo a lista de imóveis" />
      <Card
        sx={{
          backgroundColor: colors.primary[400]
        }}
      >
        <ImovelFilter
          onFilter={permission && permission?.Consultar ? handleSearch : false}
          search={term}
          bairros={bairros}
          handleDeleteItem={handleDeleteItem}
          handleAddItem={handleAddItem}
          setSearch={setTerm}
          onAdd={
            permission && permission?.Inserir
              ? () => navigate("/imoveis/create")
              : false
          }
          idtipoimovel={idtipoimovel}
          idtransacao={idtransacao}
          condominiofechado={condominiofechado}
          idtopografia={idtopografia}
          valorinicial={valorinicial}
          valorfinal={valorfinal}
          areaconstruidainicial={areaconstruidainicial}
          areaconstruidafinal={areaconstruidafinal}
          areaterrenoinicial={areaterrenoinicial}
          areaterrenofinal={areaterrenofinal}
          areautilinicial={areautilinicial}
          areautilfinal={areautilfinal}
          dormitorios={dormitorios}
          suites={suites}
          vagas={vagas}
          banheiros={banheiros}
          areagalpaoinicial={areagalpaoinicial}
          areagalpaofinal={areagalpaofinal}
          areaescritorioinicial={areaescritorioinicial}
          areaescritoriofinal={areaescritoriofinal}
          areafabrilinicial={areafabrilinicial}
          valormetroinicial={valormetroinicial}
          valormetrofinal={valormetrofinal}
          setIdtipoimovel={setIdtipoimovel}
          setIdtransacao={setIdtransacao}
          setCondominiofechado={setCondominiofechado}
          setIdtopografia={setIdtopografia}
          setValorinicial={setValorinicial}
          setValorfinal={setValorfinal}
          setAreaconstruidainicial={setAreaconstruidainicial}
          setAreaconstruidafinal={setAreaconstruidafinal}
          setAreaterrenoinicial={setAreaterrenoinicial}
          setAreaterrenofinal={setAreaterrenofinal}
          setAreautilinicial={setAreautilinicial}
          setAreautilfinal={setAreautilfinal}
          setDormitorios={setDormitorios}
          setSuites={setSuites}
          setVagas={setVagas}
          setBanheiros={setBanheiros}
          setAreagalpaoinicial={setAreagalpaoinicial}
          setAreagalpaofinal={setAreagalpaofinal}
          setAreaescritorioinicial={setAreaescritorioinicial}
          setAreaescritoriofinal={setAreaescritoriofinal}
          setAreafabrilinicial={setAreafabrilinicial}
          setValormetroinicial={setValormetroinicial}
          setValormetrofinal={setValormetrofinal}
          areafabrilfinal={areafabrilfinal}
          setAreafabrilfinal={setAreafabrilfinal}
          onChangeSpeaker={onChangeSpeaker}
          handleSelectSpeaker={handleSelectSpeaker}
          idFilial={idFilial}
          setIdFilial={setIdFilial}
          somenteMatriz={somenteMatriz}
          setSomenteMatriz={setSomenteMatriz}
          referencia={referencia}
          setReferencia={setReferencia}
          grupo={grupo}
          setGrupo={setGrupo}
          IdUnidade={IdUnidade}
          setIdUnidade={setIdUnidade}
          IdObra={IdObra}
          setIdObra={setIdObra}
          IdSituacao={IdSituacao}
          setIdSituacao={setIdSituacao}
          permuta={permuta}
          setPermuta={setPermuta}
          Mcmv={Mcmv}
          setMcmv={setMcmv}
          setEmptyValues={setEmptyValues}
          dateStart={dateStart}
          setDateStart={setDateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
          oferta={oferta}
          setOferta={setOferta}
          IdAssinatura={IdAssinatura}
          setIdAssinatura={setIdAssinatura}
          comFoto={comFoto}
          semFoto={semFoto}
          setComFoto={setComFoto}
          setSemFoto={setSemFoto}
          banner={banner}
          destaque={destaque}
          superDestaque={superDestaque}
          setBanner={setBanner}
          setDestaque={setDestaque}
          setSuperDestaque={setSuperDestaque}
          lancamento={lancamento}
          setLancamento={setLancamento}
        />
      </Card>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results?.data || []}
          head={[
            "ID",
            "REFERÊNCIA",
            "TIPO",
            "FINALIDADE",
            "ENDEREÇO",
            "VENDA",
            "LOCAÇÃO",
            "INFO",
            "DATA"
          ]}
          columns={[
            "Id",
            "Referencia",
            "tipoimovel.SubTipoSGI",
            "finalidade",
            "endereco_completo",
            "valor_venda_format",
            "valor_locacao_format",
            "dados_complementares",
            "DataSistema"
          ]}
          handleDelete={
            permission && permission?.Excluir ? item => handleShow(item) : false
          }
          handleEdit={
            permission && permission?.Atualizar
              ? ({ Id }) => {
                  const url = `${baseUrl}/imoveis/edit/${Id}`;
                  window.open(url, "_blank");
                }
              : false
          }
          handleShow={({ Id }) => {
            const url = `${baseUrl}/imoveis/edit/${Id}`;
            window.open(url, "_blank");
          }}
        />

        {results?.total > 0 && (
          <PaginationComponent
            count={results.total}
            page={results.current_page}
            handleLimit={getList}
            handlePage={getList}
          />
        )}
      </Box>

      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          title="Deletar Registro"
          maxWidth={"sm"}
          handleOpen={handleClose}
          handleClick={handleDelete}
          buttonOkColor="redAccent"
        >
          <Typography color="textPrimary" variant="h4">
            Tem certeza que deseja excluir? Esta ação não poderá ser revertida.
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
}
