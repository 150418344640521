import {
  Button,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Box
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useThemeColor } from "../../hooks/useThemeColor";

export default function SimpleFilterComponent({
  search,
  setSearch,
  onFilter,
  onAdd
}) {
  const { colors } = useThemeColor();

  const handleChange = event => {
    event.persist();
    setSearch(event.target.value);
  };

  return (
    <Grid container spacing={3} style={{ padding: 16 }}>
      <Grid item md={onAdd ? 8 : 10} sm={12}>
        <TextField
          value={search}
          onChange={handleChange}
          placeholder={"Pesquisar"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onKeyUp={event => {
            if (event.key === "Enter") {
              onFilter();
            }
          }}
        />
      </Grid>
      <Grid item md={onAdd ? 4 : 2} sm={12}>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          {onFilter && (
            <Button
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={onFilter}
            >
              Pesquisar
            </Button>
          )}

          {onAdd && (
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={onAdd}
            >
              Adicionar
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
