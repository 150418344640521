import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useContractorForm } from "../../hooks/pages/useContractorForm";
import ContractorFormData from "./_partials/ContractorFormData";

export default function ContractorEdit() {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    handleImage,
    removeImage,
    photo_url,
    estados,
    handleCep
  } = useContractorForm();

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Editar contratante"
          subtitle="Bem vindo ao editar contratante"
        />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <ContractorFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                handleImage={handleImage}
                removeImage={removeImage}
                setFieldValue={setFieldValue}
                photo_url={photo_url}
                estados={estados}
                handleCep={handleCep}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
