import { Box, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../components/ModalComponent";
import { useUnitForm } from "../../hooks/pages/useUnitForm";
import SelectInputSimple from "../../components/SelectInputSimple";

export default function UnitCreate({ open, setOpen, unit, getList }) {
  const { initialValues, validationSchema, onSubmit, enterPrises } =
    useUnitForm({
      unit,
      getUnits: getList,
      handleClose: setOpen
    });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting
        }) => {
          return (
            <Form id="form-unit">
              <ModalComponent
                open={open}
                title="Dados da Unidade"
                maxWidth={"sm"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-unit"
              >
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Nome"
                  name="Nome"
                  value={values.Nome}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Nome && errors.Nome}
                  error={Boolean(touched.Nome && errors.Nome)}
                />

                <SelectInputSimple
                  label="Empreendimento"
                  labelId={"IdEmpreendimento"}
                  name={"IdEmpreendimento"}
                  value={values.IdEmpreendimento}
                  onChange={handleChange}
                  helperText={
                    touched.IdEmpreendimento && errors.IdEmpreendimento
                  }
                  error={Boolean(
                    touched.IdEmpreendimento && errors.IdEmpreendimento
                  )}
                  options={enterPrises}
                />
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
