import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../../components/ModalComponent";
import { usePublicityForm } from "../../../hooks/pages/usePublicityForm";
import { useThemeColor } from "../../../hooks/useThemeColor";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function PublicityCreate({
  open,
  setOpen,
  publicity,
  imovelId,
  getList
}) {
  const { colors } = useThemeColor();
  const { initialValues, validationSchema, onSubmit, assinaturas } =
    usePublicityForm({
      publicity,
      imovelId,
      getPublicidades: getList,
      handleClose: setOpen
    });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting
        }) => {
          return (
            <Form id="form-publicity">
              <ModalComponent
                open={open}
                title="Dados da Publicidade"
                maxWidth={"sm"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-publicity"
              >
                <SelectInputSimple
                  label="Assinatura"
                  labelId={"IdAssinatura"}
                  name={"IdAssinatura"}
                  value={values.IdAssinatura}
                  onChange={handleChange}
                  helperText={touched.IdAssinatura && errors.IdAssinatura}
                  error={Boolean(touched.IdAssinatura && errors.IdAssinatura)}
                  options={assinaturas}
                />

                <FormControl component="fieldset">
                  <FormGroup style={{ flexDirection: "row" }}>
                    <FormControlLabel
                      label={"Banner"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="Banner"
                          onChange={handleChange}
                          checked={Boolean(values.Banner)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={"Oferta"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="Oferta"
                          onChange={handleChange}
                          checked={Boolean(values.Oferta)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={"Destaque"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="Destaque"
                          onChange={handleChange}
                          checked={Boolean(values.Destaque)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={"Super Destaque"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="SuperDestaque"
                          onChange={handleChange}
                          checked={Boolean(values.SuperDestaque)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={"Lançamento"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="Lancamento"
                          onChange={handleChange}
                          checked={Boolean(values.Lancamento)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={"Mostrar Endereço"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="MostrarEndereco"
                          onChange={handleChange}
                          checked={Boolean(values.MostrarEndereco)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={"Mostrar Número"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="MostrarNumero"
                          onChange={handleChange}
                          checked={Boolean(values.MostrarNumero)}
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
