import { useNavigate } from "react-router-dom";
import { Box, Card, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../../components/TableComponent";
import SimpleFilterComponent from "../../components/SimpleFilterComponent";
import { useFilial } from "../../hooks/pages/useFilial";
import PaginationComponent from "../../components/PaginationComponent";
import ModalComponent from "../../components/ModalComponent";
import useUserStore from "../../store/userStore";

export default function FilialList() {
  const { colors } = useThemeColor();
  const { permissions } = useUserStore(state => state);
  const navigate = useNavigate();
  const {
    results,
    term,
    setTerm,
    handleSearch,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete,
    getList
  } = useFilial();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Filial")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="Filiais" subtitle="Bem vindo a lista de filiais" />
      <Card
        sx={{
          backgroundColor: colors.primary[400]
        }}
      >
        <SimpleFilterComponent
          onFilter={permission && permission?.Consultar ? handleSearch : false}
          search={term}
          setSearch={setTerm}
          onAdd={
            permission && permission?.Inserir
              ? () => navigate("/filiais/create")
              : false
          }
        />
      </Card>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results?.data || []}
          head={["ID", "RAZÃO SOCIAL", "NOME FANTASIA", "ENDEREÇO"]}
          columns={["Id", "RazaoSocial", "NomeFantasia", "endereco_completo"]}
          handleDelete={
            permission && permission?.Excluir ? item => handleShow(item) : false
          }
          handleEdit={
            permission && permission?.Atualizar
              ? ({ Id }) => navigate(`/filiais/edit/${Id}`)
              : false
          }
          handleShow={({ Id }) => navigate(`/filiais/edit/${Id}`)}
        />

        {results?.total > 0 && (
          <PaginationComponent
            count={results.total}
            page={results.current_page}
            handleLimit={getList}
            handlePage={getList}
          />
        )}

        {modalOpen && (
          <ModalComponent
            open={modalOpen}
            title="Deletar Registro"
            maxWidth={"sm"}
            handleOpen={handleClose}
            handleClick={handleDelete}
            buttonOkColor="redAccent"
          >
            <Typography color="textPrimary" variant="h4">
              Tem certeza que deseja excluir? Esta ação não poderá ser
              revertida.
            </Typography>
          </ModalComponent>
        )}
      </Box>
    </Box>
  );
}
