import { Box, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../components/ModalComponent";
import { useTeamForm } from "../../hooks/pages/useTeamForm";

export default function TeamCreate({ open, setOpen, team, getList }) {
  const { initialValues, validationSchema, onSubmit } = useTeamForm({
    team,
    getTeams: getList,
    handleClose: setOpen
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting
        }) => {
          return (
            <Form id="form-team">
              <ModalComponent
                open={open}
                title="Dados do Time"
                maxWidth={"sm"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-team"
              >
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Nome"
                  name="Nome"
                  value={values.Nome}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Nome && errors.Nome}
                  error={Boolean(touched.Nome && errors.Nome)}
                />
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
