import { Box, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../components/ModalComponent";
import { useSimuladorForm } from "../../hooks/pages/useSimuladorForm";
import SelectInputSimple from "../../components/SelectInputSimple";

export default function SimuladorCreate({ open, setOpen, simulador, getList }) {
  const { initialValues, validationSchema, onSubmit, banks } = useSimuladorForm(
    {
      simulador,
      getSimulators: getList,
      handleClose: setOpen
    }
  );

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting
        }) => {
          return (
            <Form id="form-simulador">
              <ModalComponent
                open={open}
                title="Dados do Simulador"
                maxWidth={"sm"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-simulador"
              >
                <SelectInputSimple
                  label="Banco"
                  labelId={"IdBanco"}
                  name={"IdBanco"}
                  value={values.IdBanco}
                  onChange={handleChange}
                  helperText={touched.IdBanco && errors.IdBanco}
                  error={Boolean(touched.IdBanco && errors.IdBanco)}
                  options={banks}
                />

                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Link"
                  name="Link"
                  value={values.Link}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Link && errors.Link}
                  error={Boolean(touched.Link && errors.Link)}
                />
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
