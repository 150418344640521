import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import useLoadingStore from "../../store/loading";
import { api } from "../../services/api";
import useModalStore from "../../store/modalStore";

export function usePortal() {
  const [results, setResults] = useState();
  const [term, setTerm] = useState("");
  const [item, setItem] = useState(null);
  const { setLoadingTable } = useLoadingStore(state => state);
  const { modalOpen, showModal, hideModal } = useModalStore(state => state);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = () => {
    const filters = {};
    if (term?.length > 0) filters.term = term;
    return filters;
  };

  const getList = async (page = 1, limit = 10) => {
    try {
      setLoadingTable(true);

      const { data } = await api.get(`/portais`, {
        params: {
          filters: handleFilter(),
          page,
          limit
        }
      });

      setResults(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingTable(false);
    }
  };

  const handleSearch = () => {
    getList();
  };

  const handleShow = item => {
    setItem(item);
    showModal();
  };

  const handleClose = () => {
    setItem(null);
    hideModal();
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/portais/${item.Id}`);
      handleClose();
      getList();
      enqueueSnackbar("Registro deletado com sucesso", {
        variant: "success"
      });
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    results,
    term,
    setTerm,
    handleSearch,
    handleShow,
    modalOpen,
    hideModal,
    handleClose,
    handleDelete,
    getList
  };
}
