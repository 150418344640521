import { Form } from "formik";
import { Button, Grid, TextField } from "@mui/material";
import { useThemeColor } from "../../../hooks/useThemeColor";
import TextFieldMask from "../../../components/TextFieldMask";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function ContractFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-contract">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          <Button
            sx={{
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px"
            }}
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Estado"
            labelId={"idestado"}
            name={"idestado"}
            value={values.idestado}
            onChange={handleChange}
            helperText={touched.idestado && errors.idestado}
            error={Boolean(touched.idestado && errors.idestado)}
            options={[]}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Tipo Endereço"
            name="tipoendereco"
            value={values.tipoendereco}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.tipoendereco && errors.tipoendereco}
            error={Boolean(touched.tipoendereco && errors.tipoendereco)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Endereço"
            name="endereco"
            value={values.endereco}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.endereco && errors.endereco}
            error={Boolean(touched.endereco && errors.endereco)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Número"
            name="numero"
            value={values.numero}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.numero && errors.numero}
            error={Boolean(touched.numero && errors.numero)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Complemento"
            name="complemento"
            value={values.complemento}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.complemento && errors.complemento}
            error={Boolean(touched.complemento && errors.complemento)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Bairro"
            name="bairro"
            value={values.bairro}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.bairro && errors.bairro}
            error={Boolean(touched.bairro && errors.bairro)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cidade"
            name="cidade"
            value={values.cidade}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.cidade && errors.cidade}
            error={Boolean(touched.cidade && errors.cidade)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"99999-999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Cep"
            name="cep"
            value={values.cep}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.cep && errors.cep}
            error={Boolean(touched.cep && errors.cep)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Data de Cadastro"
            name="datacadastro"
            type="date"
            value={values.datacadastro}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.datacadastro && errors.datacadastro}
            error={Boolean(touched.datacadastro && errors.datacadastro)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Situação"
            labelId={"idsituacao"}
            name={"idsituacao"}
            value={values.idsituacao}
            onChange={handleChange}
            helperText={touched.idsituacao && errors.idsituacao}
            error={Boolean(touched.idsituacao && errors.idsituacao)}
            options={[
              { Id: "1", Nome: "Ativo" },
              { Id: "2", Nome: "Inativo" },
              { Id: "3", Nome: "Suspenso" }
            ]}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            label="Observação"
            name="observacao"
            value={values.observacao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.observacao && errors.observacao}
            error={Boolean(touched.observacao && errors.observacao)}
          />
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          <Button
            sx={{
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px"
            }}
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
