import { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function useOwnerForm({
  owner,
  imovelId,
  getProprietarios,
  handleClose
}) {
  const [selected, setSelected] = useState([]);

  const initialValues = {
    id: owner?.Id || "",
    IdPessoa: owner?.IdPessoa || "",
    Percentual: owner?.percentual_format || "0",
    Responsabilidade: owner?.Responsabilidade || "",
    Nome: owner?.pessoa?.Nome || "",
    Contato: owner?.pessoa?.Contato || "",
    TelRes: owner?.pessoa?.TelRes || "",
    TelCom: owner?.pessoa?.TelCom || "",
    Celular: owner?.pessoa?.Celular || "",
    Email: owner?.pessoa?.Email || ""
  };

  const validationSchema = Yup.object().shape({
    IdPessoa: Yup.string(),
    Nome: Yup.string().required("Por favor, informe o proprietátio"),
    Celular: Yup.string().required("Por favor, informe o celular proprietátio"),
    Email: Yup.string().email("E-mail inválido"),
    Percentual: Yup.number().required("Por favor, informe o percentual"),
    Responsabilidade: Yup.string().required(
      "Por favor, informe a responsabilidade"
    )
  });

  useEffect(() => {
    if (owner?.pessoa?.Id) {
      setSelected([
        {
          id: owner?.pessoa?.Id,
          name: owner?.pessoa?.Nome
        }
      ]);
    }
  }, [owner?.pessoa]);

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id
        ? `/imoveis/proprietarios/${imovelId}/update-proprietario/${id}`
        : `/imoveis/proprietarios/${imovelId}/store-proprietario`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getProprietarios();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const getPessoas = async (search = null) => {
    try {
      if (search) {
        const { data } = await api.get(
          `/pessoas?filters[term]=${search}`,
          {
            params: {
              paginate: 0
            }
          },
          false
        );

        return data.data.map(i => ({
          ...i,
          name: `${i.Nome}`
        }));
      }
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const onChangeSpeaker = search => {
    if (search && search.length > 1) {
      return getPessoas(search);
    }
  };

  const handleSelectSpeaker = async (item, setValues) => {
    setValues("IdPessoa", item?.Id, false);
    setValues("Nome", item?.Nome || "", false);
    setValues("Contato", item?.Contato || "", false);
    setValues("TelRes", item?.TelRes || "", false);
    setValues("TelCom", item?.TelCom || "", false);
    setValues("Celular", item?.Celular || "", false);
    setValues("Email", item?.Email || "", false);
    setSelected(item?.Id ? [{ id: item?.Id, name: item?.Nome }] : []);
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    onChangeSpeaker,
    handleSelectSpeaker,
    selected
  };
}
