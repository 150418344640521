import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import useLoadingStore from "../../store/loading";
import useModalStore from "../../store/modalStore";
import { api } from "../../services/api";

export function useUnit() {
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState();
  const [term, setTerm] = useState("");
  const [unit, setUnit] = useState({});
  const { setLoadingTable } = useLoadingStore(state => state);
  const { modalOpen, showModal, hideModal } = useModalStore(state => state);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = () => {
    const filters = {};
    if (term?.length > 0) filters.term = term;
    return filters;
  };

  const getList = async (page = 1, limit = 10) => {
    try {
      setLoadingTable(true);

      const { data } = await api.get(`/unidades`, {
        params: {
          filters: handleFilter(),
          page,
          limit
        }
      });

      setResults(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingTable(false);
    }
  };

  const handleSearch = () => {
    getList();
  };

  const handleOpen = (status = true) => {
    setOpen(status);
    if (!status) setUnit({});
  };

  const handleEdit = item => {
    setUnit(item);
    handleOpen();
  };

  const handleShow = item => {
    setUnit(item);
    showModal();
  };

  const handleClose = () => {
    setUnit(null);
    hideModal();
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/unidades/${unit.Id}`);
      handleClose();
      getList();
      enqueueSnackbar("Registro deletado com sucesso", {
        variant: "success"
      });
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    results,
    term,
    setTerm,
    handleSearch,
    open,
    handleOpen,
    unit,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    hideModal,
    handleClose,
    handleDelete
  };
}
