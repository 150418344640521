import { Route, Routes } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import Login from "../pages/Login";
import Forgot from "../pages/Forgot";
import Dashboard from "../pages/Dashboard";
import ImovelList from "../pages/Imovel/ImovelList";
import ImovelCreate from "../pages/Imovel/ImovelCreate";
import ImovelEdit from "../pages/Imovel/ImovelEdit";
import EnterpriseList from "../pages/Enterprises/EnterpriseList";
import EnterpriseCreate from "../pages/Enterprises/EnterpriseCreate";
import EnterpriseEdit from "../pages/Enterprises/EnterpriseEdit";
import UnitList from "../pages/Units/UnitList";
import ZoneList from "../pages/Zones/ZoneList";
import MidiaList from "../pages/Midia/MidiaList";
import UserList from "../pages/Users/UserList";
import UserCreate from "../pages/Users/UserCreate";
import UserEdit from "../pages/Users/UserEdit";
import ProfileList from "../pages/Profile/ProfileList";
import CollaboratorList from "../pages/Collaborator/CollaboratorList";
import TeamList from "../pages/Team/TeamList";
import ContractorEdit from "../pages/Contractor/ContractorEdit";
import FilialList from "../pages/Filiais/FilialList";
import FilialCreate from "../pages/Filiais/FilialCreate";
import FilialEdit from "../pages/Filiais/FilialEdit";
import PeopleList from "../pages/Peoples/PeopleList";
import PeopleCreate from "../pages/Peoples/PeopleCreate";
import PeopleEdit from "../pages/Peoples/PeopleEdit";
import BankList from "../pages/Banks/BankList";
import SimuladorList from "../pages/Simulador/SimuladorList";
import PortalList from "../pages/Portais/PortalList";
import PortalCreate from "../pages/Portais/PortalCreate";
import PortalEdit from "../pages/Portais/PortalEdit";
import SignatureList from "../pages/Signature/SignatureList";
import SignatureCreate from "../pages/Signature/SignatureCreate";
import SignatureEdit from "../pages/Signature/SignatureEdit";
import ContractList from "../pages/Contract/ContractList";
import ContractCreate from "../pages/Contract/ContractCreate";
import ContractEdit from "../pages/Contract/ContractEdit";
import ResetPassword from "../pages/ResetPassword";
import ConfigSiteEdit from "../pages/ConfigSite/ConfigSiteEdit";
import Register from "../pages/Register";
import UserProfileEdit from "../pages/UserProfile/UserProfileEdit";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot"
        element={
          <PublicRoute>
            <Forgot />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/imoveis"
        element={
          <PrivateRoute>
            <ImovelList />
          </PrivateRoute>
        }
      />
      <Route
        path="/imoveis/create"
        element={
          <PrivateRoute>
            <ImovelCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/imoveis/edit/:id"
        element={
          <PrivateRoute>
            <ImovelEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/empreendimentos"
        element={
          <PrivateRoute>
            <EnterpriseList />
          </PrivateRoute>
        }
      />
      <Route
        path="/empreendimentos/create"
        element={
          <PrivateRoute>
            <EnterpriseCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/empreendimentos/edit/:id"
        element={
          <PrivateRoute>
            <EnterpriseEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/unidades"
        element={
          <PrivateRoute>
            <UnitList />
          </PrivateRoute>
        }
      />
      <Route
        path="/zoneamentos"
        element={
          <PrivateRoute>
            <ZoneList />
          </PrivateRoute>
        }
      />
      <Route
        path="/midias"
        element={
          <PrivateRoute>
            <MidiaList />
          </PrivateRoute>
        }
      />
      <Route
        path="/usuarios"
        element={
          <PrivateRoute>
            <UserList />
          </PrivateRoute>
        }
      />
      <Route
        path="/usuarios/create"
        element={
          <PrivateRoute>
            <UserCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/usuarios/edit/:id"
        element={
          <PrivateRoute>
            <UserEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/perfis"
        element={
          <PrivateRoute>
            <ProfileList />
          </PrivateRoute>
        }
      />
      <Route
        path="/colaboradores"
        element={
          <PrivateRoute>
            <CollaboratorList />
          </PrivateRoute>
        }
      />
      <Route
        path="/equipes"
        element={
          <PrivateRoute>
            <TeamList />
          </PrivateRoute>
        }
      />
      <Route
        path="/contratante"
        element={
          <PrivateRoute>
            <ContractorEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/configsite"
        element={
          <PrivateRoute>
            <ConfigSiteEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/filiais"
        element={
          <PrivateRoute>
            <FilialList />
          </PrivateRoute>
        }
      />
      <Route
        path="/filiais/create"
        element={
          <PrivateRoute>
            <FilialCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/filiais/edit/:id"
        element={
          <PrivateRoute>
            <FilialEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/pessoas"
        element={
          <PrivateRoute>
            <PeopleList />
          </PrivateRoute>
        }
      />
      <Route
        path="/pessoas/create"
        element={
          <PrivateRoute>
            <PeopleCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/pessoas/edit/:id"
        element={
          <PrivateRoute>
            <PeopleEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/bancos"
        element={
          <PrivateRoute>
            <BankList />
          </PrivateRoute>
        }
      />
      <Route
        path="/simulador"
        element={
          <PrivateRoute>
            <SimuladorList />
          </PrivateRoute>
        }
      />
      <Route
        path="/portais"
        element={
          <PrivateRoute>
            <PortalList />
          </PrivateRoute>
        }
      />
      <Route
        path="/portais/create"
        element={
          <PrivateRoute>
            <PortalCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/portais/edit/:id"
        element={
          <PrivateRoute>
            <PortalEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/assinaturas"
        element={
          <PrivateRoute>
            <SignatureList />
          </PrivateRoute>
        }
      />
      <Route
        path="/assinaturas/create"
        element={
          <PrivateRoute>
            <SignatureCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/assinaturas/edit/:id"
        element={
          <PrivateRoute>
            <SignatureEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/contratos"
        element={
          <PrivateRoute>
            <ContractList />
          </PrivateRoute>
        }
      />
      <Route
        path="/contratos/create"
        element={
          <PrivateRoute>
            <ContractCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/contratos/edit/:id"
        element={
          <PrivateRoute>
            <ContractEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/perfil-usuario"
        element={
          <PrivateRoute>
            <UserProfileEdit />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
