import { useNavigate } from "react-router-dom";
import { Box, Card } from "@mui/material";
import Header from "../../components/Header";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../../components/TableComponent";
import SimpleFilterComponent from "../../components/SimpleFilterComponent";
import { useContract } from "../../hooks/pages/useContract";

export default function ContractList() {
  const { colors } = useThemeColor();
  const navigate = useNavigate();
  const { results, term, setTerm, handleSearch } = useContract();

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header
        title="Contratos de locação"
        subtitle="Bem vindo a lista de contratos de locação"
      />
      <Card
        sx={{
          backgroundColor: colors.primary[400]
        }}
      >
        <SimpleFilterComponent
          onFilter={handleSearch}
          search={term}
          setSearch={setTerm}
          onAdd={() => navigate("/contratos/create")}
        />
      </Card>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results || []}
          head={[
            "CONTRATO",
            "LOCATÁRIO",
            "VALOR ALUGUEL",
            "REFERÊNCIA",
            "ENDEREÇO POSTAL",
            "SITUAÇÃO"
          ]}
          columns={[
            "Id",
            "Nome",
            "ValorLancamento",
            "Referencia",
            "Endereco",
            "IdSituacao"
          ]}
          handleDelete={item => console.log(item)}
          handleEdit={({ Id }) => navigate(`/contratos/edit/${Id}`)}
          handleShow={({ id }) => console.log(id)}
        />
      </Box>
    </Box>
  );
}
