import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useContractForm } from "../../hooks/pages/useContractForm";
import ContractFormData from "./_partials/ContractFormData";

export default function ContractCreate() {
  const { initialValues, validationSchema, onSubmit } = useContractForm();

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Contrato" subtitle="Bem vindo ao criar contrato" />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <ContractFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
