import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Collapse, IconButton, MenuList, Typography } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ItemComponent from "../../../components/ItemComponent";

import "react-pro-sidebar/dist/css/styles.css";
import { menus } from "../../../data/mocks/menus";
import { useThemeColor } from "../../../hooks/useThemeColor";
import { useDimension } from "../../../hooks/useDimension";
import { useUserProfile } from "../../../hooks/useUserProfile";
import useUserStore from "../../../store/userStore";

export default function Sidebar({ isSidebar }) {
  const { isCollapsed, setIsCollapsed } = useDimension();
  const { colors } = useThemeColor();
  const [selected, setSelected] = useState("Dashboard");
  const [open, setOpen] = useState(0);
  const { user } = useUserProfile();
  const { permissions } = useUserStore(state => state);

  const handleOpen = menuId => {
    setOpen(menuId === open ? 0 : menuId);
  };

  if (!isSidebar) return null;

  const enablebMenu = f => {
    return permissions?.some(p => f.permission.includes(p.pagina?.Nome));
  };

  const enableSubMenu = f => {
    return permissions?.some(p => {
      if (p.pagina?.Nome === "Usuario" && f.title.includes("Criar Usuário")) {
        return p.Inserir === 1;
      }

      if (p.pagina?.Nome === "Usuario" && f.title.includes("Listagem")) {
        return p.Consultar === 1;
      }

      if (p.pagina?.Nome === "Pessoa" && f.title.includes("Criar Pessoa")) {
        return p.Inserir === 1;
      }

      if (p.pagina?.Nome === "Pessoa" && f.title.includes("Pessoas")) {
        return p.Consultar === 1;
      }

      if (p.pagina?.Nome === "Imovel" && f.title.includes("Buscar")) {
        return p.Consultar === 1;
      }

      if (p.pagina?.Nome === "Imovel" && f.title.includes("Cadastrar")) {
        return p.Inserir === 1;
      }

      return (
        p.pagina?.Nome === f.permission &&
        (p.Inserir === 1 ||
          p.Atualizar === 1 ||
          p.Excluir === 1 ||
          p.Consultar === 1 ||
          p.Imprimir === 1)
      );
    });
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important"
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important"
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important"
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important"
        }
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100]
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  SGIWEB
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="75px"
                  height="75px"
                  src={
                    user?.foto_url
                      ? user?.foto_url
                      : `${process.env.REACT_APP_PUBLIC_URL}/assets/avatar2.png`
                  }
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center" sx={{ mt: "10px" }}>
                <Typography
                  variant="h4"
                  component={RouterLink}
                  to={"/perfil-usuario"}
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {user?.Nome}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {user?.perfil?.Nome}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <ItemComponent
              title="Home"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              toolTip={"Home"}
            />
            {/* slice 0, -2 don't list 2 menus */}
            {menus
              .slice(0, -2)
              .filter(enablebMenu)
              .map((menu, keyMenu) => {
                const submenus = menu?.submenus || [];

                return (
                  <React.Fragment key={keyMenu}>
                    <MenuList onClick={() => handleOpen(menu.id)}>
                      <ItemComponent
                        title={
                          <>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              {menu.title}
                              {open === menu.id ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Typography>
                          </>
                        }
                        to={"#"}
                        icon={menu.icon}
                        selected={selected}
                        setSelected={setSelected}
                        toolTip={menu.title}
                      />
                    </MenuList>
                    {submenus
                      .filter(enableSubMenu)
                      .map((submenu, keySubmenu) => (
                        <Collapse
                          key={keySubmenu}
                          in={open === menu.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ItemComponent
                            title={submenu.title}
                            to={submenu.to}
                            icon={<CircleIcon fontSize="small" />}
                            selected={selected}
                            setSelected={setSelected}
                            toolTip={submenu.title}
                          />
                        </Collapse>
                      ))}
                  </React.Fragment>
                );
              })}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
}
