import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import useLoadingStore from "../../store/loading";
import { api } from "../../services/api";

export function useMidia() {
  const [results, setResults] = useState();
  const [term, setTerm] = useState("");
  const { setLoadingTable, setLoadingDefault } = useLoadingStore(
    state => state
  );

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = () => {
    const filters = {};
    if (term?.length > 0) filters.term = term;
    return filters;
  };

  const getList = async (page = 1, limit = 10) => {
    try {
      setLoadingTable(true);

      const { data } = await api.get(`/assinaturas`, {
        params: {
          filters: handleFilter(),
          page,
          limit
        }
      });

      setResults(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingTable(false);
    }
  };

  const handleSearch = () => {
    getList();
  };

  const handleSend = async item => {
    try {
      setLoadingDefault(item?.Id || false);

      await api.get(`/portais/${item.IdPortal}/gerar-xml`);

      enqueueSnackbar("Dados processados com sucesso", {
        variant: "success"
      });

      getList();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingDefault(false);
    }
  };

  return {
    results,
    term,
    setTerm,
    handleSearch,
    getList,
    handleSend
  };
}
