import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useResetPassword() {
  const params = useParams();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
    password_confirmation: "",
    token: params.token || ""
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Informe seu e-mail"),
    password: Yup.string()
      .min(6, "Informe pelo menos 6 caracteres")
      .required("Informe a nova senha"),
    password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "As senhas não conferem, favor verificar"
      )
      .required("Confirme sua nova senha")
  });

  const handleRequest = data => {
    let formData = {};
    formData.email = data.email;
    formData.token = data.token;
    formData.password = data.password;
    formData.password_confirmation = data.password_confirmation;
    return formData;
  };

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const {
        data: { error, message }
      } = await api.post("/auth/reset-password", handleRequest(values));

      enqueueSnackbar(message, {
        variant: error ? "error" : "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
