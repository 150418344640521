import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
  async config => {
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));

    if (authUser?.token) {
      config.headers.Authorization = `Bearer ${authUser?.token}`;
    }

    config.signal = cancelRequestHandler(config.url).signal;

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    if (err?.response?.status === 401) {
      sessionStorage.removeItem("authUser");
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    }

    return Promise.reject(err);
  }
);

const cancelTokenObject = {};

export const cancelRequestHandler = key => {
  if (key in cancelTokenObject) {
    cancelTokenObject[key].abort();
  }
  cancelTokenObject[key] = new AbortController();

  return cancelTokenObject[key];
};
