import { Form } from "formik";
import { Button, Grid, TextField } from "@mui/material";
import FilesDropzone from "../../../components/FilesDropezone";
import { useThemeColor } from "../../../hooks/useThemeColor";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function UserFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  handleImage,
  removeImage,
  setFieldValue,
  photo_url,
  perfis,
  filiais,
  permission,
  id
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-user">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <FilesDropzone
            avatar
            field={"Foto"}
            img={photo_url || false}
            setFiles={event => handleImage(event, "Foto", setFieldValue)}
          />
          {values.Foto && (
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={() => removeImage("Foto", setFieldValue)}
            >
              Remover foto
            </Button>
          )}
        </Grid>
        <Grid item md={9} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                fullWidth
                label="Nome"
                name="Nome"
                value={values.Nome}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.Nome && errors.Nome}
                error={Boolean(touched.Nome && errors.Nome)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={values.email}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                fullWidth
                label="Senha"
                type="password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.password && errors.password}
                error={Boolean(touched.password && errors.password)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectInputSimple
                label="Perfil"
                labelId={"IdPerfil"}
                name={"IdPerfil"}
                value={values.IdPerfil}
                onChange={handleChange}
                helperText={touched.IdPerfil && errors.IdPerfil}
                error={Boolean(touched.IdPerfil && errors.IdPerfil)}
                options={perfis}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectInputSimple
                label="Filial"
                labelId={"IdFilial"}
                name={"IdFilial"}
                value={values.IdFilial}
                onChange={handleChange}
                helperText={touched.IdFilial && errors.IdFilial}
                error={Boolean(touched.IdFilial && errors.IdFilial)}
                options={filiais}
                optionTitle="NomeFantasia"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectInputSimple
                label="Situação"
                labelId={"IdSituacao"}
                name={"IdSituacao"}
                value={values.IdSituacao}
                onChange={handleChange}
                helperText={touched.IdSituacao && errors.IdSituacao}
                error={Boolean(touched.IdSituacao && errors.IdSituacao)}
                options={[
                  { Id: "1", Nome: "Ativo" },
                  { Id: "2", Nome: "Inativo" },
                  { Id: "3", Nome: "Suspenso" }
                ]}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                multiline
                rows={4}
                fullWidth
                label="Observação"
                name="Observacao"
                value={values.Observacao}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.Observacao && errors.Observacao}
                error={Boolean(touched.Observacao && errors.Observacao)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
