import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { usePhoto } from "../../../hooks/pages/usePhoto";
import PhotoCreate from "./PhotoUpload";
import { useThemeColor } from "../../../hooks/useThemeColor";
import ModalComponent from "../../../components/ModalComponent";
import PhotoEdit from "./PhotoEdit";

export default function PhotosList({ imovelId }) {
  const {
    results,
    open,
    openEdit,
    handleOpen,
    photo,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete,
    loadingDelete,
    handleOpenEdit,
    onDragEnd,
    handleReorder
  } = usePhoto({ imovelId });
  const { colors } = useThemeColor();

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="end">
        <Button
          sx={{
            backgroundColor: colors.grey[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginRight: "10px"
          }}
          type="button"
          onClick={() => handleReorder()}
        >
          Atualizar ordenação
        </Button>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px"
          }}
          type="button"
          onClick={() => handleOpen(true)}
        >
          Adicionar Fotos
        </Button>
      </Box>
      <Box backgroundColor={colors.primary[400]}>
        {results?.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="results" direction="vertical">
              {provided => (
                <ImageList
                  cols={1}
                  sx={{ width: 200 }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {results
                    .sort((a, b) => a.Ordem - b.Ordem)
                    .map((item, index) => (
                      <Draggable
                        key={item.Id}
                        draggableId={item.Id.toString()}
                        index={index}
                      >
                        {provided => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <ImageListItem sx={{ mb: 1 }}>
                              <img
                                srcSet={`${item.foto_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.foto_url}?w=248&fit=crop&auto=format`}
                                alt={item.Descricao}
                                loading="lazy"
                                style={{
                                  height: 200
                                }}
                              />
                              <ImageListItemBar
                                title={`${item.Ordem} - ${item.Descricao}`}
                                actionIcon={
                                  <>
                                    <IconButton
                                      sx={{
                                        color: "rgba(255, 255, 255, 0.54)"
                                      }}
                                      aria-label={`info about ${item.title}`}
                                      onClick={() => handleEdit(item)}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                      sx={{
                                        color: "rgba(255, 255, 255, 0.54)"
                                      }}
                                      aria-label={`info about ${item.title}`}
                                      onClick={() => handleShow(item)}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </>
                                }
                              />
                            </ImageListItem>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ImageList>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
      </Box>

      {open && (
        <PhotoCreate
          imovelId={imovelId}
          open={open}
          setOpen={() => handleOpen(false)}
          getList={getList}
        />
      )}

      {openEdit && (
        <PhotoEdit
          photo={photo}
          imovelId={imovelId}
          open={openEdit}
          setOpen={() => handleOpenEdit(false)}
          getList={getList}
        />
      )}

      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          title="Deletar Registro"
          maxWidth={"sm"}
          handleOpen={handleClose}
          handleClick={handleDelete}
          buttonOkColor="redAccent"
          disabledClick={loadingDelete}
        >
          <Typography color="textPrimary" variant="h4">
            Tem certeza que deseja excluir? Esta ação não poderá ser revertida.
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
}
