import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField
} from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../../components/ModalComponent";
import { usePhotoFormEdit } from "../../../hooks/pages/usePhotoFormEdit";
import { useThemeColor } from "../../../hooks/useThemeColor";

export default function PhotoEdit({ open, setOpen, photo, imovelId, getList }) {
  const { colors } = useThemeColor();
  const { initialValues, validationSchema, onSubmit } = usePhotoFormEdit({
    photo,
    imovelId,
    getPhotos: getList,
    handleClose: setOpen
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting
        }) => {
          return (
            <Form id="form-photo">
              <ModalComponent
                open={open}
                title="Dados da Foto"
                maxWidth={"sm"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-photo"
              >
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Descrição"
                  name="Descricao"
                  value={values.Descricao}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Descricao && errors.Descricao}
                  error={Boolean(touched.Descricao && errors.Descricao)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Ordem"
                  name="Ordem"
                  value={values.Ordem}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Ordem && errors.Ordem}
                  error={Boolean(touched.Ordem && errors.Ordem)}
                />

                <FormControl component="fieldset">
                  <FormGroup style={{ flexDirection: "row" }}>
                    <FormControlLabel
                      label={"Exibir Site"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="ExibirSite"
                          onChange={handleChange}
                          checked={Boolean(values.ExibirSite)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={"Destaque"}
                      control={
                        <Checkbox
                          style={{ color: colors.grey[100] }}
                          name="Destaque"
                          onChange={handleChange}
                          checked={Boolean(values.Destaque)}
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
