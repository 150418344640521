import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useContractForm } from "../../hooks/pages/useContractForm";
import ContractFormData from "./_partials/ContractFormData";

export default function ContractEdit() {
  const { id } = useParams();
  const { initialValues, validationSchema, onSubmit } = useContractForm({
    id
  });

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Editar contrato"
          subtitle="Bem vindo ao editar contrato"
        />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <ContractFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
