import { useEffect, useState } from "react";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useUnitForm({ unit, getUnits, handleClose }) {
  const [enterPrises, setEnterPrises] = useState([]);

  useEffect(() => {
    getEnterprises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEnterprises = async () => {
    try {
      const { data } = await api.get("/empreendimentos", {
        params: {
          paginate: 0
        }
      });

      const enterPrisesData = data?.data || [];

      setEnterPrises(
        unit?.IdEmpreendimento
          ? enterPrisesData.filter(
              item => item.Id === parseInt(unit?.IdEmpreendimento)
            )
          : enterPrisesData
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const initialValues = {
    id: unit?.Id || "",
    Nome: unit?.Nome || "",
    IdEmpreendimento: unit?.IdEmpreendimento || ""
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Informe o nome"),
    IdEmpreendimento: Yup.string().required("Informe o empreendimento")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id ? `/unidades/${id}` : `/unidades`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getUnits();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    enterPrises
  };
}
