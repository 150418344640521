export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const errorsMessage = (error, enqueueSnackbar, variant = "error") => {
  if (error.response) {
    if (error.response.status === 500) {
      enqueueSnackbar(
        "Ocorreu um erro interno, entre em contato com os administradores.",
        { variant }
      );
    } else if (error.response.status === 405) {
      enqueueSnackbar(
        "Este recurso não foi encontrado, entre em contato com os administradores.",
        { variant }
      );
    } else if (error.response.status === 403) {
      enqueueSnackbar("Esta ação não está autorizada para seu perfil.", {
        variant
      });
    } else if (error.response.status === 404) {
      enqueueSnackbar(
        "Este recurso não foi encontrado, entre em contato com os administradores.",
        { variant }
      );
    } else {
      if (error.response.status === 422) {
        const response = error.response.data;
        if (response.errors) {
          let errors = Object.values(response.errors);
          errors = errors.flat();
          errors.map(item => enqueueSnackbar(item, { variant }));
        }
      }
    }
  }
};

// Função para transformar o array de objetos em array de strings
export const parsePageProfileToArray = arrayOfObject => {
  const arrayOfStrings = [];

  arrayOfObject.forEach(obj => {
    const idPagina = obj.IdPagina;

    // Percorre as propriedades de ações e cria o nome da string para cada ação
    Object.keys(obj).forEach(prop => {
      if (prop !== "Id" && prop !== "IdPagina" && prop !== "IdPerfil") {
        const action = prop.toLowerCase(); // converte para minúsculo (ex: "Inserir" -> "inserir")
        const value = obj[prop];
        const nameString = `${idPagina}_${action}`;
        if (value === 1) arrayOfStrings.push(nameString);
      }
    });
  });

  return arrayOfStrings;
};

export const getNestedFieldValue = (obj, fieldPath) => {
  const keys = fieldPath.split(".");
  let value = obj;

  keys.forEach(key => {
    if (value && value.hasOwnProperty(key)) {
      value = value[key];
    } else {
      value = undefined; // Field not found
    }
  });

  return value;
};

export const CurrencyFormat = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});

export const currency = (price = 0) => {
  if (isNaN(price)) {
    price = 0;
  }

  return CurrencyFormat.format(price);
};

export const formatCurrency = inputValue => {
  // Remove qualquer caractere que não seja número
  const numericValue = inputValue.replace(/\D/g, "");

  // Formata o valor como moeda brasileira
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numericValue / 100);

  return formattedValue;
};

export const parseValue = value => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1.$2");
  return value;
};

export const maskHex = value => {
  const match = value.replace(/[g-zG-Z]/g, "").match(/#[a-fA-F0-9]{0,6}/);
  return match ? match[0] : "";
};

export const typesCollaborator = [
  {
    Nome: "Nenhum",
    Id: 0
  },
  {
    Nome: "Corretor",
    Id: 1
  },
  {
    Nome: "Gerente",
    Id: 2
  },
  {
    Nome: "Supervidor",
    Id: 3
  },
  {
    Nome: "Lider",
    Id: 4
  },
  {
    Nome: "Diretor",
    Id: 5
  }
];

export const getThemeModeFromLocalStorage = lsKey => {
  if (!localStorage) {
    return "dark";
  }

  const data = localStorage.getItem(lsKey);

  if (data === "dark" || data === "light") {
    return data;
  }

  return "dark";
};

export const replaceColumn = key => {
  const keys = {
    SubTipoSGI: "Tipo",
    finalidade: "Finalidade",
    endereco_completo: "Endereço",
    valor_venda_format: "Venda",
    valor_locacao_format: "Locação",
    dados_complementares: "Info",
    DataSistema: "Data Cadastro"
  };

  return keys[key] ?? key;
};

export const getFieldErrorNames = formikErrors => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};
