import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { usePortalForm } from "../../hooks/pages/usePortalForm";
import PortalFormData from "./_partials/PortalFormData";
import useUserStore from "../../store/userStore";

export default function PortalEdit() {
  const { id } = useParams();
  const { permissions } = useUserStore(state => state);
  const { initialValues, validationSchema, onSubmit } = usePortalForm({
    id
  });

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Portal")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Editar portal" subtitle="Bem vindo ao editar portal" />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <PortalFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                permission={permission}
                id={id}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
