import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { api } from "../../services/api";
import { errorsMessage, formatCurrency, parseValue } from "../../helpers";

export function useSignatureForm(props) {
  const navigate = useNavigate();
  const [signature, setSignature] = useState({});
  const [portais, setPortais] = useState([]);
  const id = props?.id || null;

  const getItem = async id => {
    try {
      const { data } = await api.get(`/assinaturas/${id}`);

      setSignature(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getPortais = async () => {
    try {
      const { data } = await api.get("/portais", {
        params: {
          paginate: 0
        }
      });

      setPortais(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getPortais();
  }, []);

  useEffect(() => {
    if (id) {
      getItem(id);
    }
  }, [id]);

  const initialValues = {
    id: signature?.Id || "",
    IdPortal: signature?.IdPortal || "",
    Pasta: signature?.Pasta || "",
    ftp: signature?.ftp || "",
    Usuario: signature?.Usuario || "",
    Senha: signature?.Senha || "",
    Arquivo: signature?.Arquivo || "",
    IdTipoArquivo: signature?.IdTipoArquivo || "0",
    Identificacao: signature?.Identificacao || "",
    QtdeBanners: signature?.QtdeBanners || "",
    QtdeOfertas: signature?.QtdeOfertas || "",
    QtdeDestaques: signature?.QtdeDestaques || "",
    QtdeSuperDestaques: signature?.QtdeSuperDestaques || "",
    ValorPlano: signature?.ValorPlano
      ? formatCurrency(signature?.ValorPlano || 0)
      : "",
    IdSituacao: signature?.IdSituacao || "1",
    Observacao: signature?.Observacao || ""
  };

  const validationSchema = Yup.object().shape({
    IdPortal: Yup.string().required("Este campo é obrigatório"),
    ftp: Yup.string().required("Este campo é obrigatório"),
    IdSituacao: Yup.string().required("Este campo é obrigatório")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;
      formData.ValorPlano = parseValue(formData.ValorPlano);

      const path = id ? `/assinaturas/${id}` : `/assinaturas`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      navigate("/assinaturas");
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleValueChange = (formattedValue, field, setValues) => {
    setValues(field, formattedValue, false);
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    portais,
    handleValueChange
  };
}
