import PropTypes from "prop-types";
import { Box, LinearProgress, CircularProgress } from "@mui/material";

export default function IndicatorComponent({ type = "linear", show = false }) {
  return show ? (
    <Box sx={{ width: "100%" }}>
      {type === "linear" ? (
        <LinearProgress color="secondary" />
      ) : (
        <CircularProgress color="secondary" />
      )}
    </Box>
  ) : null;
}

IndicatorComponent.defaultProps = {
  type: "linear",
  show: false
};

IndicatorComponent.propTypes = {
  type: PropTypes.oneOf(["linear", "circle"]),
  show: PropTypes.bool
};
