import { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TableFooter,
  Typography,
  Hidden,
  List,
  ListItem,
  Grid,
  Card,
  CardContent,
  CardActions,
  CircularProgress
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useThemeColor } from "../../hooks/useThemeColor";
import IndicatorComponent from "../IndicatorComponent";
import useLoadingStore from "../../store/loading";
import { getNestedFieldValue, replaceColumn } from "../../helpers";
import CardTable from "./CardTable";

export default function TableComponent({
  list,
  head,
  columns,
  imageColumn,
  handleEdit,
  handleDelete,
  handleShow,
  handleSend,
  handleCheckbox,
  enableCheckbox = false,
  checkBoxKey,
  columnDonwload,
  titleSend = "Enviar"
}) {
  const { colors } = useThemeColor();
  const [checkAll, setCheckAll] = useState(false);
  const [selecteds, setSelected] = useState([]);
  const { loadingTable, loadingDefault } = useLoadingStore(state => state);

  useEffect(() => {
    if (selecteds?.length && handleCheckbox) handleCheckbox(selecteds);
  }, [handleCheckbox, selecteds]);

  const renderSwitch = (item, colunm, key) => {
    let fields = [];

    switch (colunm) {
      case imageColumn:
        let isFile = item instanceof File;
        return (
          <img
            src={!isFile ? item[imageColumn] : URL.createObjectURL(item)}
            srcSet={!isFile ? item[imageColumn] : URL.createObjectURL(item)}
            alt={"teste imagem"}
            loading="lazy"
            style={{
              width: 150
            }}
          />
        );
      case columnDonwload:
        return item[columnDonwload] ? (
          <Button
            href={item[columnDonwload]}
            download={item[columnDonwload]}
            target={"_blank"}
            alt={"Donwload"}
            title={"Donwload"}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px"
            }}
          >
            <GetAppIcon fontSize="small" /> Download
          </Button>
        ) : null;
      default:
        fields = colunm.split(".");
        if (fields.length === 1) {
          return typeof item[fields[0]] == "boolean" ? (
            <Checkbox
              style={{ color: colors.grey[100] }}
              checked={item[fields[0]]}
              value={item[fields[0]]}
            />
          ) : (
            item[fields[0]]
          );
        } else {
          return getNestedFieldValue(item, colunm);
        }
    }
  };

  const handleSelectAll = ({ target }) => {
    if (target.checked) {
      setSelected(list.map(i => String(i[checkBoxKey])));
    } else {
      setSelected([]);
    }
  };

  const handleChange = ({ target }) => {
    let currentSelecteds = [...selecteds];
    if (target.checked) {
      currentSelecteds = [...selecteds, target.value];
    } else {
      currentSelecteds.splice(selecteds.indexOf(target.value), 1);
    }
    setSelected(currentSelecteds);
  };

  return (
    <Box>
      {loadingTable && <IndicatorComponent show={loadingTable} />}

      <Hidden lgDown>
        <TableContainer>
          <Table style={{ minWidth: 800 }}>
            <TableHead
              sx={{
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none"
              }}
            >
              <TableRow>
                {enableCheckbox && (
                  <TableCell>
                    <Checkbox
                      style={{
                        color: colors.grey[100]
                      }}
                      name="checkAll"
                      checked={checkAll}
                      onClick={handleSelectAll}
                      onChange={() => setCheckAll(prevState => !prevState)}
                    />
                  </TableCell>
                )}
                {head.map((column, key) => (
                  <TableCell key={key}>{column}</TableCell>
                ))}
                <TableCell align="right">AÇÕES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 &&
                list.map((item, index) => (
                  <TableRow hover key={`tb-${index.toString()}`}>
                    {enableCheckbox && (
                      <TableCell>
                        <Checkbox
                          style={{
                            color: colors.grey[100]
                          }}
                          value={item[checkBoxKey]}
                          checked={
                            selecteds.includes(String(item[checkBoxKey])) ||
                            false
                          }
                          onChange={e => handleChange(e)}
                        />
                      </TableCell>
                    )}
                    {columns.map((column, key) => (
                      <TableCell key={key}>
                        {renderSwitch(item, column, index)}
                      </TableCell>
                    ))}
                    <TableCell align="right" style={{ minWidth: 150 }}>
                      {handleShow && (
                        <IconButton onClick={() => handleShow(item)}>
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      )}

                      {handleEdit && (
                        <IconButton onClick={() => handleEdit(item)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}

                      {handleDelete && (
                        <IconButton onClick={() => handleDelete(item)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                      {handleSend && (
                        <Button
                          sx={{
                            backgroundColor: colors.greenAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                          }}
                          onClick={() => handleSend(item)}
                        >
                          {loadingDefault && loadingDefault === item?.Id ? (
                            <CircularProgress size={20} color="secondary" />
                          ) : (
                            titleSend
                          )}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {selecteds?.length > 0 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={head?.length + 2}>
                    <Typography variant="body1">{`${selecteds.length} item${
                      selecteds.length > 1 ? "s" : ""
                    } selecionado${
                      selecteds.length > 1 ? "s" : ""
                    }`}</Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Hidden>

      <Hidden lgUp>
        <CardTable>
          <List sx={{ width: "100%", bgcolor: colors.primary[400] }}>
            <Grid container spacing={2}>
              {list.map((item, index) => (
                <ListItem key={`tb-${index.toString()}`}>
                  <Card
                    sx={{
                      width: "100%",
                      bgcolor: colors.primary[400],
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <CardContent>
                      {columns.map((column, key) => (
                        <Box sx={{ fontSize: 16 }} key={key}>
                          <strong>
                            {
                              replaceColumn(column)
                                .replace("foto_url", "")
                                .split(".")
                                .reverse()[0]
                            }
                            :
                          </strong>{" "}
                          {renderSwitch(item, column, index)}
                        </Box>
                      ))}
                    </CardContent>
                    <CardActions sx={{ justifyContent: "flex-end" }}>
                      {handleShow && (
                        <IconButton onClick={() => handleShow(item)}>
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      )}

                      {handleEdit && (
                        <IconButton onClick={() => handleEdit(item)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}

                      {handleDelete && (
                        <IconButton onClick={() => handleDelete(item)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                      {handleSend && (
                        <Button
                          sx={{
                            backgroundColor: colors.greenAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                          }}
                          onClick={() => handleSend(item)}
                        >
                          Enviar
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </ListItem>
              ))}
            </Grid>
          </List>
        </CardTable>
      </Hidden>

      {!loadingTable && list?.length === 0 && (
        <Box>
          <Alert severity="warning">
            <AlertTitle>Sem registros</AlertTitle>
            Nenhum registro encontrado —{" "}
            <strong>verifique os filtros realizados!</strong>
          </Alert>
        </Box>
      )}
      {loadingTable && <IndicatorComponent show={loadingTable} />}
    </Box>
  );
}
