import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MediationIcon from "@mui/icons-material/Mediation";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

export const menus = [
  {
    id: 1,
    title: "Imóveis",
    permission: "Imovel,Empreendimento,Unidade,Zoneamento,Midia",
    icon: <AspectRatioIcon />,
    submenus: [
      {
        id: 1,
        permission: "Imovel",
        title: "Buscar",
        to: "/imoveis"
      },
      {
        id: 2,
        permission: "Imovel",
        title: "Cadastrar",
        to: "/imoveis/create"
      },
      {
        id: 3,
        permission: "Empreendimento",
        title: "Empreendimentos",
        to: "/empreendimentos"
      },
      {
        id: 4,
        permission: "Unidade",
        title: "Unidades",
        to: "/unidades"
      },
      /*{
        id: 5,
        permission: "Zoneamento",
        title: "Zoneamentos",
        to: "/zoneamentos"
      },*/
      {
        id: 6,
        permission: "Midia",
        title: "Mídia",
        to: "/midias"
      }
    ]
  },
  {
    id: 2,
    title: "Usuários",
    permission: "Usuario,Perfil",
    icon: <GroupIcon />,
    submenus: [
      {
        id: 23,
        permission: "Usuario",
        title: "Criar Usuário",
        to: "/usuarios/create"
      },
      {
        id: 7,
        permission: "Usuario",
        title: "Listagem",
        to: "/usuarios"
      },
      {
        id: 8,
        permission: "Perfil",
        title: "Perfil",
        to: "/perfis"
      }
    ]
  },
  {
    id: 3,
    title: "Gerenciador",
    permission:
      "Colaborador,Equipe,Contratante,Config Site,Filial,Pessoa,Banco,Simulador Financeiro",
    icon: <ManageAccountsIcon />,
    submenus: [
      {
        id: 10,
        permission: "Colaborador",
        title: "Colaboradores",
        to: "/colaboradores"
      },
      {
        id: 11,
        permission: "Equipe",
        title: "Equipes",
        to: "/equipes"
      },
      {
        id: 12,
        permission: "Contratante",
        title: "Contratante",
        to: "/contratante"
      },
      {
        id: 22,
        permission: "Config Site",
        title: "Config Site",
        to: "/configsite"
      },
      {
        id: 13,
        permission: "Filial",
        title: "Filial",
        to: "/filiais"
      },
      {
        id: 14,
        permission: "Pessoa",
        title: "Pessoas",
        to: "/pessoas"
      },
      {
        id: 14,
        permission: "Pessoa",
        title: "Criar Pessoa",
        to: "/pessoas/create"
      },
      {
        id: 15,
        permission: "Banco",
        title: "Banco",
        to: "/bancos"
      },
      {
        id: 16,
        permission: "Simulador Financeiro",
        title: "Simulador Financeiro",
        to: "/simulador"
      }
    ]
  },
  {
    id: 4,
    title: "Publicidade",
    permission: "Portal,Assinatura",
    icon: <MediationIcon />,
    submenus: [
      {
        id: 17,
        permission: "Portal",
        title: "Portal",
        to: "/portais"
      },
      {
        id: 18,
        permission: "Assinatura",
        title: "Assinatura",
        to: "/assinaturas"
      }
    ]
  },
  {
    id: 5,
    title: "Admin. de Locadora",
    permission: "Contrato de Locação,Lançamento Financeiro",
    icon: <AdminPanelSettingsIcon />,
    submenus: [
      {
        id: 19,
        permission: "Contrato de Locação",
        title: "Contrato de locação",
        to: "/contratos"
      },
      {
        id: 20,
        permission: "Lançamento Financeiro",
        title: "Lançamento Financeiro",
        to: "/dashboard"
      }
    ]
  },
  {
    id: 6,
    title: "Relatório Admin",
    permission: "Locatário com Pendências",
    icon: <AssessmentIcon />,
    submenus: [
      {
        id: 21,
        permission: "Locatário com Pendências",
        title: "Locat. c/ Pendências",
        to: "/dashboard"
      }
    ]
  }
];
