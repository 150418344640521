import React from "react";
import { Grid, TextField } from "@mui/material";

export default function Rural({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting
}) {
  return (
    <React.Fragment>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Acessos"
          name="Acessos"
          value={values.Acessos}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Acessos && errors.Acessos}
          error={Boolean(touched.Acessos && errors.Acessos)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Equipamentos"
          name="Equipamentos"
          value={values.Equipamentos}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Equipamentos && errors.Equipamentos}
          error={Boolean(touched.Equipamentos && errors.Equipamentos)}
        />
      </Grid>
    </React.Fragment>
  );
}
