import { useEffect, useState } from "react";
import useSidebarStore from "../store/sidebar";

export function useDimension() {
  const { setVisible } = useSidebarStore(state => state);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [windowDimenion, setWindowDimenion] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight
  });

  const detectSize = () => {
    setWindowDimenion({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  useEffect(() => {
    setIsCollapsed(
      windowDimenion?.winWidth && windowDimenion?.winWidth > 768 ? false : true
    );

    setVisible(
      windowDimenion?.winWidth && windowDimenion?.winWidth > 768 ? true : false
    );
  }, [setVisible, windowDimenion?.winWidth]);

  return { windowDimenion, isCollapsed, setIsCollapsed };
}
