import {
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText
} from "@mui/material";
import { useThemeColor } from "../../hooks/useThemeColor";

export default function SelectInputSimple({
  name,
  label,
  labelId,
  value,
  options = [],
  optionId = "Id",
  optionTitle = "Nome",
  onChange,
  error,
  helperText,
  emptyText = "Selecione um item",
  classHelp = { paddingTop: "1rem" },
  multiple
}) {
  const { colors } = useThemeColor();

  return (
    <Box sx={classHelp}>
      <FormControl variant="filled" fullWidth>
        <InputLabel style={{ color: colors.grey[100] }} id={labelId}>
          {label}
        </InputLabel>
        {options?.length > 0 && (
          <Select
            name={name}
            labelId={labelId}
            value={value}
            onChange={onChange}
            label={label}
            multiple={multiple}
          >
            <MenuItem value="">
              <em>{emptyText}</em>
            </MenuItem>
            {options.map((item, key) => (
              <MenuItem key={`${name}_${key}`} value={item[optionId]}>
                {item[optionTitle]}
              </MenuItem>
            ))}
          </Select>
        )}
        {error && (
          <FormHelperText sx={{ color: colors.redAccent[500] }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
