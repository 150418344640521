import { useState } from "react";
import PropTypes from "prop-types";
import TablePagination from "@mui/material/TablePagination";

export default function PaginationComponent({
  count,
  page,
  limit,
  handleLimit,
  handlePage,
  rowsPerPageOptions,
  labelRowsPerPage
}) {
  const [rowsPerPage, setRowsPerPage] = useState(limit);

  const onChangeRowsPerPage = ({ target }) => {
    setRowsPerPage(target.value);
    handleLimit(1, target.value);
  };

  const handleChangePage = (event, newPage) => {
    handlePage(newPage + 1, rowsPerPage);
  };

  return (
    <TablePagination
      component="div"
      count={count}
      page={page - 1}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      labelRowsPerPage={labelRowsPerPage}
      onRowsPerPageChange={onChangeRowsPerPage}
      onPageChange={handleChangePage}
      labelDisplayedRows={({ from, to, count }) =>
        `Página ${from}-${to} de ${count}`
      }
    />
  );
}

PaginationComponent.defaultProps = {
  limit: 10,
  rowsPerPageOptions: [5, 10, 25, 50],
  labelRowsPerPage: "Itens por página"
};

PaginationComponent.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handlePage: PropTypes.func,
  handleLimit: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  labelRowsPerPage: PropTypes.string
};
