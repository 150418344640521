import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function useFilialForm(props) {
  const navigate = useNavigate();
  const [filial, setFilial] = useState({});
  const [estados, setEstados] = useState([]);
  const id = props?.id || null;

  const getItem = async id => {
    try {
      const { data } = await api.get(`/filiais/${id}`);

      setFilial(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getEstados = async () => {
    try {
      const { data } = await api.get("/estados", {
        params: {
          paginate: 0
        }
      });

      setEstados(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getEstados();
  }, []);

  useEffect(() => {
    if (id) {
      getItem(id);
    }
  }, [id]);

  const initialValues = {
    id: filial?.Id || "",
    IdFilial: filial?.IdFilial || "",
    NomeFantasia: filial?.NomeFantasia || "",
    RazaoSocial: filial?.RazaoSocial || "",
    CNPJ: filial?.CNPJ || "",
    InscricaoEstadual: filial?.InscricaoEstadual || "",
    WebSite: filial?.WebSite || "",
    Registro: filial?.Registro || "",
    IdEstado: filial?.IdEstado || "",
    TipoEndereco: filial?.TipoEndereco || "",
    Endereco: filial?.Endereco || "",
    Numero: filial?.Numero || "",
    Complemento: filial?.Complemento || "",
    Bairro: filial?.Bairro || "",
    Cidade: filial?.Cidade || "",
    Cep: filial?.Cep || "",
    Contato: filial?.Contato || "",
    Email: filial?.Email || "",
    Telefone: filial?.Telefone || "",
    Mensagem: filial?.Mensagem || "",
    Observacao: filial?.Observacao || "",
    IdSituacao: filial?.IdSituacao || "1",
    LogoTipo: filial?.LogoTipo || "",
    TipoPessoa: filial?.TipoPessoa || "PJ"
  };

  const validationSchema = Yup.object().shape({
    NomeFantasia: Yup.string().required("Este campo é obrigatório"),
    RazaoSocial: Yup.string().required("Este campo é obrigatório"),
    IdSituacao: Yup.string().required("Este campo é obrigatório")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id ? `/filiais/${id}` : `/filiais`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      navigate("/filiais");
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleCep = async (values, setValues) => {
    try {
      const cep = values.Cep.replace(/\D/g, "");

      if (cep.length < 8) {
        enqueueSnackbar("Por favor informe o cep corretamente", {
          variant: "error"
        });
        return;
      }

      const { data } = await api.get(`/endereco/cep?cep=${cep}`);

      setValues("Endereco", data?.logradouro, false);
      setValues("Cidade", data?.localidade, false);
      setValues("Bairro", data?.bairro, false);
      setValues("TipoEndereco", data?.type, false);
      setValues("IdEstado", data?.IdEstado, false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    estados,
    handleCep
  };
}
