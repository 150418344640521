import { enqueueSnackbar } from "notistack";
import React, { createContext, useEffect, useState } from "react";
import { api } from "../services/api";
import { errorsMessage } from "../helpers";
import useUserStore from "../store/userStore";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
  const authUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [user, setUser] = useState(authUser || {});
  const { setPermissions } = useUserStore(state => state);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    try {
      const { data } = await api.get("/profile/me");

      setUser(data?.data);
      setPermissions(data?.data?.perfil?.perfilpagina);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return (
    <UserContext.Provider value={{ user, getUser }}>
      {children}
    </UserContext.Provider>
  );
}
