import { Box, Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../components/ModalComponent";
import { useBankForm } from "../../hooks/pages/useBankForm";
import FilesDropzone from "../../components/FilesDropezone";
import { useThemeColor } from "../../hooks/useThemeColor";

export default function BankCreate({ open, setOpen, bank, getList }) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    photo_url,
    handleImage,
    removeImage
  } = useBankForm({
    bank,
    getBanks: getList,
    handleClose: setOpen
  });
  const { colors } = useThemeColor();

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form id="form-bank">
              <ModalComponent
                open={open}
                title="Dados do Banco"
                maxWidth={"sm"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-bank"
              >
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Nome"
                  name="Nome"
                  value={values.Nome}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Nome && errors.Nome}
                  error={Boolean(touched.Nome && errors.Nome)}
                />

                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Num. do Banco"
                  name="IdBanco"
                  value={values.IdBanco}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.IdBanco && errors.IdBanco}
                  error={Boolean(touched.IdBanco && errors.IdBanco)}
                />

                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Link"
                  name="Link"
                  value={values.Link}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Link && errors.Link}
                  error={Boolean(touched.Link && errors.Link)}
                />

                <FilesDropzone
                  title={"Logomarca"}
                  field={"Logomarca"}
                  img={photo_url || false}
                  setFiles={event =>
                    handleImage(event, "Logomarca", setFieldValue)
                  }
                />
                {values.Logomarca && (
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px"
                    }}
                    onClick={() => removeImage("Logomarca", setFieldValue)}
                  >
                    Remover foto
                  </Button>
                )}
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
