import { Form } from "formik";
import { Button, Grid, TextField } from "@mui/material";
import { useThemeColor } from "../../../hooks/useThemeColor";
import TextFieldMoney from "../../../components/TextFieldMoney";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function SignatureFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  portais,
  setFieldValue,
  handleValueChange,
  permission,
  id
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-signature">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Portal"
            labelId={"IdPortal"}
            name={"IdPortal"}
            value={values.IdPortal}
            onChange={handleChange}
            helperText={touched.IdPortal && errors.IdPortal}
            error={Boolean(touched.IdPortal && errors.IdPortal)}
            options={portais}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Pasta"
            name="Pasta"
            value={values.Pasta}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Pasta && errors.Pasta}
            error={Boolean(touched.Pasta && errors.Pasta)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="FTP"
            name="ftp"
            value={values.ftp}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.ftp && errors.ftp}
            error={Boolean(touched.ftp && errors.ftp)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Usuário"
            name="Usuario"
            value={values.Usuario}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Usuario && errors.Usuario}
            error={Boolean(touched.Usuario && errors.Usuario)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Senha"
            name="Senha"
            value={values.Senha}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Senha && errors.Senha}
            error={Boolean(touched.Senha && errors.Senha)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Arquivo"
            name="Arquivo"
            value={values.Arquivo}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Arquivo && errors.Arquivo}
            error={Boolean(touched.Arquivo && errors.Arquivo)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Tipo de arquivo"
            labelId={"IdTipoArquivo"}
            name={"IdTipoArquivo"}
            value={values.IdTipoArquivo}
            onChange={handleChange}
            helperText={touched.IdTipoArquivo && errors.IdTipoArquivo}
            error={Boolean(touched.IdTipoArquivo && errors.IdTipoArquivo)}
            options={[
              { Id: "0", Nome: "Nenhum" },
              { Id: "1", Nome: "XML" },
              { Id: "2", Nome: "JSON" }
            ]}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Identificação"
            name="Identificacao"
            value={values.Identificacao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Identificacao && errors.Identificacao}
            error={Boolean(touched.Identificacao && errors.Identificacao)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Banners"
            name="QtdeBanners"
            value={values.QtdeBanners}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.QtdeBanners && errors.QtdeBanners}
            error={Boolean(touched.QtdeBanners && errors.QtdeBanners)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Ofertas"
            name="QtdeOfertas"
            value={values.QtdeOfertas}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.QtdeOfertas && errors.QtdeOfertas}
            error={Boolean(touched.QtdeOfertas && errors.QtdeOfertas)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Destaques"
            name="QtdeDestaques"
            value={values.QtdeDestaques}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.QtdeDestaques && errors.QtdeDestaques}
            error={Boolean(touched.QtdeDestaques && errors.QtdeDestaques)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Super Destaque"
            name="QtdeSuperDestaques"
            value={values.QtdeSuperDestaques}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.QtdeSuperDestaques && errors.QtdeSuperDestaques}
            error={Boolean(
              touched.QtdeSuperDestaques && errors.QtdeSuperDestaques
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMoney
            margin="normal"
            variant="filled"
            fullWidth
            label="Valor do Plano"
            name="ValorPlano"
            value={values.ValorPlano}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={event =>
              handleValueChange(event, "ValorPlano", setFieldValue)
            }
            helperText={touched.ValorPlano && errors.ValorPlano}
            error={Boolean(touched.ValorPlano && errors.ValorPlano)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Situação"
            labelId={"IdSituacao"}
            name={"IdSituacao"}
            value={values.IdSituacao}
            onChange={handleChange}
            helperText={touched.IdSituacao && errors.IdSituacao}
            error={Boolean(touched.IdSituacao && errors.IdSituacao)}
            options={[
              { Id: "1", Nome: "Ativo" },
              { Id: "2", Nome: "Inativo" },
              { Id: "3", Nome: "Suspenso" }
            ]}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            label="Observação"
            name="Observacao"
            value={values.Observacao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Observacao && errors.Observacao}
            error={Boolean(touched.Observacao && errors.Observacao)}
          />
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
