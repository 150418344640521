import { Form } from "formik";
import { Button, Grid, TextField } from "@mui/material";
import FilesDropzone from "../../../components/FilesDropezone";
import { useThemeColor } from "../../../hooks/useThemeColor";

export default function UserProfileFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  handleImage,
  removeImage,
  setFieldValue,
  photo_url
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-user">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          <Button
            sx={{
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px"
            }}
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <FilesDropzone
            avatar
            field={"Foto"}
            img={photo_url || false}
            setFiles={event => handleImage(event, "Foto", setFieldValue)}
          />
          {values.Foto && (
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={() => removeImage("Foto", setFieldValue)}
            >
              Remover foto
            </Button>
          )}
        </Grid>
        <Grid item md={9} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                fullWidth
                label="Nome"
                name="Nome"
                value={values.Nome}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.Nome && errors.Nome}
                error={Boolean(touched.Nome && errors.Nome)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={values.email}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                fullWidth
                label="Senha Atual"
                type="password"
                name="current_password"
                value={values.current_password}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.current_password && errors.current_password}
                error={Boolean(
                  touched.current_password && errors.current_password
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                fullWidth
                label="Nova Senha"
                type="password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.password && errors.password}
                error={Boolean(touched.password && errors.password)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                margin="normal"
                variant="filled"
                fullWidth
                label="Repita Nova Senha"
                type="password"
                name="password_confirmation"
                value={values.password_confirmation}
                onBlur={handleBlur}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={
                  touched.password_confirmation && errors.password_confirmation
                }
                error={Boolean(
                  touched.password_confirmation && errors.password_confirmation
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          <Button
            sx={{
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px"
            }}
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
