import { Box, Button, Typography } from "@mui/material";
import TableComponent from "../../../components/TableComponent";
import { usePublicity } from "../../../hooks/pages/usePublicity";
import { useThemeColor } from "../../../hooks/useThemeColor";
import PublicityCreate from "./PublicityCreate";
import ModalComponent from "../../../components/ModalComponent";

export default function PublicityList({ imovelId }) {
  const { colors } = useThemeColor();
  const {
    results,
    open,
    handleOpen,
    publicity,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete
  } = usePublicity({
    imovelId
  });

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="end">
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px"
          }}
          onClick={() => handleOpen(true)}
        >
          Adicionar
        </Button>
      </Box>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results || []}
          head={[
            "ID",
            "NOME",
            "BANNER",
            "OFERTA",
            "DESTAQUE",
            "SUPER DESTAQUE",
            "LANÇAMENTO"
          ]}
          columns={[
            "Id",
            "assinatura.portal.Nome",
            "Banner",
            "Oferta",
            "Destaque",
            "SuperDestaque",
            "Lancamento"
          ]}
          checkColumn={[
            "Banner",
            "Oferta",
            "Destaque",
            "SuperDestaque",
            "Lancamento"
          ]}
          handleDelete={item => handleShow(item)}
          handleEdit={item => handleEdit(item)}
        />
      </Box>

      {open && (
        <PublicityCreate
          imovelId={imovelId}
          open={open}
          publicity={publicity}
          setOpen={() => handleOpen(false)}
          getList={getList}
        />
      )}

      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          title="Deletar Registro"
          maxWidth={"sm"}
          handleOpen={handleClose}
          handleClick={handleDelete}
          buttonOkColor="redAccent"
        >
          <Typography color="textPrimary" variant="h4">
            Tem certeza que deseja excluir? Esta ação não poderá ser revertida.
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
}
