import { useEffect, useState } from "react";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function usePublicityForm({
  publicity,
  imovelId,
  getPublicidades,
  handleClose
}) {
  const [assinaturas, setAssinaturas] = useState([]);

  const initialValues = {
    id: publicity?.Id || "",
    IdAssinatura: publicity?.IdAssinatura || "",
    Banner: publicity?.Banner || false,
    Oferta: publicity?.Oferta || false,
    Destaque: publicity?.Destaque || false,
    SuperDestaque: publicity?.SuperDestaque || false,
    Lancamento: publicity?.Lancamento || false,
    MostrarEndereco: publicity?.MostrarEndereco || false,
    MostrarNumero: publicity?.MostrarNumero || false
  };

  const validationSchema = Yup.object().shape({
    IdAssinatura: Yup.string().required("Informe a assinatura"),
    Banner: Yup.bool(),
    Oferta: Yup.bool(),
    Destaque: Yup.bool(),
    SuperDestaque: Yup.bool(),
    lancamento: Yup.bool(),
    MostrarEndereco: Yup.bool(),
    MostrarNumero: Yup.bool()
  });

  const getAssinaturas = async () => {
    try {
      const { data } = await api.get("/assinaturas", {
        params: {
          paginate: 0
        }
      });

      setAssinaturas(
        data?.data?.map(a => {
          return {
            ...a,
            Nome: a.portal?.Nome
          };
        }) || []
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getAssinaturas();
  }, []);

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id
        ? `/imoveis/publicidades/${imovelId}/update-publicidade/${id}`
        : `/imoveis/publicidades/${imovelId}/store-publicidade`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      resetForm();
      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
      getPublicidades();
      handleClose();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    assinaturas
  };
}
