import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import TextFieldMoney from "../../../../components/TextFieldMoney";
import SelectInputSimple from "../../../../components/SelectInputSimple";

export default function Commercialization({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  zoneamentos,
  handleValueChange,
  setFieldValue,
  tipoImovelSelected
}) {
  return (
    <React.Fragment>
      <Grid item md={12}>
        <Typography variant="h4">Comercial</Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldMoney
          margin="normal"
          variant="filled"
          fullWidth
          label="Valor de Venda"
          name="ValorVenda"
          value={values.ValorVenda}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={event =>
            handleValueChange(event, "ValorVenda", setFieldValue)
          }
          helperText={touched.ValorVenda && errors.ValorVenda}
          error={Boolean(touched.ValorVenda && errors.ValorVenda)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldMoney
          margin="normal"
          variant="filled"
          fullWidth
          label="Valor de Locação"
          name="ValorLocacao"
          value={values.ValorLocacao}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={event =>
            handleValueChange(event, "ValorLocacao", setFieldValue)
          }
          helperText={touched.ValorLocacao && errors.ValorLocacao}
          error={Boolean(touched.ValorLocacao && errors.ValorLocacao)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldMoney
          margin="normal"
          variant="filled"
          fullWidth
          label="Valor do Condomínio"
          name="ValorCondominio"
          value={values.ValorCondominio}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={event =>
            handleValueChange(event, "ValorCondominio", setFieldValue)
          }
          helperText={touched.ValorCondominio && errors.ValorCondominio}
          error={Boolean(touched.ValorCondominio && errors.ValorCondominio)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldMoney
          margin="normal"
          variant="filled"
          fullWidth
          label="Valor do Metro"
          name="ValorMetro"
          value={values.ValorMetro}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={event =>
            handleValueChange(event, "ValorMetro", setFieldValue)
          }
          helperText={touched.ValorMetro && errors.ValorMetro}
          error={Boolean(touched.ValorMetro && errors.ValorMetro)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldMoney
          margin="normal"
          variant="filled"
          fullWidth
          label="Valor de IPTU"
          name="ValorIptu"
          value={values.ValorIptu}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={event =>
            handleValueChange(event, "ValorIptu", setFieldValue)
          }
          helperText={touched.ValorIptu && errors.ValorIptu}
          error={Boolean(touched.ValorIptu && errors.ValorIptu)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Tel. Portaria"
          name="TelPortaria"
          value={values.TelPortaria}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.TelPortaria && errors.TelPortaria}
          error={Boolean(touched.TelPortaria && errors.TelPortaria)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Zelador"
          name="Zelador"
          value={values.Zelador}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Zelador && errors.Zelador}
          error={Boolean(touched.Zelador && errors.Zelador)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <SelectInputSimple
          label="Uso"
          labelId={"IdUso"}
          name={"IdUso"}
          value={values.IdUso}
          onChange={handleChange}
          helperText={touched.IdUso && errors.IdUso}
          error={Boolean(touched.IdUso && errors.IdUso)}
          options={[
            { Id: "0", Nome: "Nenhum" },
            { Id: "1", Nome: "Residencial" },
            { Id: "2", Nome: "Comercial" },
            { Id: "3", Nome: "Industrial" },
            { Id: "4", Nome: "Geral" }
          ]}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <SelectInputSimple
          label="Obra"
          labelId={"IdObra"}
          name={"IdObra"}
          value={values.IdObra}
          onChange={handleChange}
          helperText={touched.IdObra && errors.IdObra}
          error={Boolean(touched.IdObra && errors.IdObra)}
          options={[
            { Id: "0", Nome: "Nenhum" },
            { Id: "1", Nome: "Pronta" },
            { Id: "2", Nome: "Construção" },
            { Id: "3", Nome: "Parada" },
            { Id: "4", Nome: "Na Planta" }
          ]}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <SelectInputSimple
          label="Frente"
          labelId={"IdFrente"}
          name={"IdFrente"}
          value={values.IdFrente}
          onChange={handleChange}
          helperText={touched.IdFrente && errors.IdFrente}
          error={Boolean(touched.IdFrente && errors.IdFrente)}
          options={[
            { Id: "0", Nome: "Nenhum" },
            { Id: "1", Nome: "Norte" },
            { Id: "2", Nome: "Sul" },
            { Id: "3", Nome: "Leste" },
            { Id: "4", Nome: "Oeste" }
          ]}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <SelectInputSimple
          label="Zoneamento"
          labelId={"IdZoneamento"}
          name={"IdZoneamento"}
          value={values.IdZoneamento}
          onChange={handleChange}
          helperText={touched.IdZoneamento && errors.IdZoneamento}
          error={Boolean(touched.IdZoneamento && errors.IdZoneamento)}
          options={zoneamentos}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          multiline
          rows={4}
          fullWidth
          label="Condições de Pagamento (Vísivel apenas para a imobiliária)"
          name="CondPagamento"
          value={values.CondPagamento}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.CondPagamento && errors.CondPagamento}
          error={Boolean(touched.CondPagamento && errors.CondPagamento)}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          multiline
          rows={4}
          fullWidth
          label="Observação"
          name="Observacao"
          value={values.Observacao}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Observacao && errors.Observacao}
          error={Boolean(touched.Observacao && errors.Observacao)}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          multiline
          rows={4}
          fullWidth
          label="Memorial Descritivo"
          name="MemorialDescritivo"
          value={values.MemorialDescritivo}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.MemorialDescritivo && errors.MemorialDescritivo}
          error={Boolean(
            touched.MemorialDescritivo && errors.MemorialDescritivo
          )}
        />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">Metragem</Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Área do Terreno"
          name="AreaTerreno"
          value={values.AreaTerreno}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.AreaTerreno && errors.AreaTerreno}
          error={Boolean(touched.AreaTerreno && errors.AreaTerreno)}
        />
      </Grid>
      {!["Terreno", "Comercial"].includes(tipoImovelSelected?.Grupo) && (
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Área Construida"
            name="AreaConstruida"
            value={values.AreaConstruida}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.AreaConstruida && errors.AreaConstruida}
            error={Boolean(touched.AreaConstruida && errors.AreaConstruida)}
          />
        </Grid>
      )}

      {!["Terreno", "Rural"].includes(tipoImovelSelected?.Grupo) && (
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Área Útil"
            name="AreaUtil"
            value={values.AreaUtil}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.AreaUtil && errors.AreaUtil}
            error={Boolean(touched.AreaUtil && errors.AreaUtil)}
          />
        </Grid>
      )}
      {!["Terreno"].includes(tipoImovelSelected?.Grupo) && (
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Área Comum"
            name="AreaComum"
            value={values.AreaComum}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.AreaComum && errors.AreaComum}
            error={Boolean(touched.AreaComum && errors.AreaComum)}
          />
        </Grid>
      )}
      {!["Terreno"].includes(tipoImovelSelected?.Grupo) && (
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Área Privativa"
            name="AreaPrivativa"
            value={values.AreaPrivativa}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.AreaPrivativa && errors.AreaPrivativa}
            error={Boolean(touched.AreaPrivativa && errors.AreaPrivativa)}
          />
        </Grid>
      )}

      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Área Total"
          name="AreaTotal"
          value={values.AreaTotal}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.AreaTotal && errors.AreaTotal}
          error={Boolean(touched.AreaTotal && errors.AreaTotal)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Medida"
          name="Medida"
          value={values.Medida}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Medida && errors.Medida}
          error={Boolean(touched.Medida && errors.Medida)}
        />
      </Grid>
      {!["Terreno"].includes(tipoImovelSelected?.Grupo) && (
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Pé Direito"
            name="PeDireito"
            value={values.PeDireito}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.PeDireito && errors.PeDireito}
            error={Boolean(touched.PeDireito && errors.PeDireito)}
          />
        </Grid>
      )}

      {["Galpao"].includes(tipoImovelSelected?.Grupo) && (
        <>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Área Escritório"
              name="AreaEscritorio"
              value={values.AreaEscritorio}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.AreaEscritorio && errors.AreaEscritorio}
              error={Boolean(touched.AreaEscritorio && errors.AreaEscritorio)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Área Galpão"
              name="AreaGalpao"
              value={values.AreaGalpao}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.AreaGalpao && errors.AreaGalpao}
              error={Boolean(touched.AreaGalpao && errors.AreaGalpao)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Área Fabril"
              name="AreaFabril"
              value={values.AreaFabril}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.AreaFabril && errors.AreaFabril}
              error={Boolean(touched.AreaFabril && errors.AreaFabril)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Área Pátio"
              name="AreaPatio"
              value={values.AreaPatio}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.AreaPatio && errors.AreaPatio}
              error={Boolean(touched.AreaPatio && errors.AreaPatio)}
            />
          </Grid>
        </>
      )}

      {["Terreno"].includes(tipoImovelSelected?.Grupo) && (
        <>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Quadra"
              name="Quadra"
              value={values.Quadra}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.Quadra && errors.Quadra}
              error={Boolean(touched.Quadra && errors.Quadra)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Lote"
              name="Lote"
              value={values.Lote}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.Lote && errors.Lote}
              error={Boolean(touched.Lote && errors.Lote)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <SelectInputSimple
              label="Topografia"
              labelId={"IdTopografia"}
              name={"IdTopografia"}
              value={values.IdTopografia}
              onChange={handleChange}
              helperText={touched.IdTopografia && errors.IdTopografia}
              error={Boolean(touched.IdTopografia && errors.IdTopografia)}
              options={[
                { Id: "0", Nome: "Indiferente" },
                { Id: "1", Nome: "Aclive" },
                { Id: "2", Nome: "Declive" },
                { Id: "3", Nome: "Plano" }
              ]}
            />
          </Grid>
        </>
      )}

      {!["Terreno"].includes(tipoImovelSelected?.Grupo) && (
        <>
          <Grid item md={12}>
            <Typography variant="h4">Infra local</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Garagem"
              name="Garagem"
              value={values.Garagem}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.Garagem && errors.Garagem}
              error={Boolean(touched.Garagem && errors.Garagem)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Vaga Visitante"
              name="VagaVisitante"
              value={values.VagaVisitante}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.VagaVisitante && errors.VagaVisitante}
              error={Boolean(touched.VagaVisitante && errors.VagaVisitante)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Aquecedor Central"
              name="AquecedorCentral"
              value={values.AquecedorCentral}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.AquecedorCentral && errors.AquecedorCentral}
              error={Boolean(
                touched.AquecedorCentral && errors.AquecedorCentral
              )}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Sistema Segurança"
              name="SistemaSeguranca"
              value={values.SistemaSeguranca}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.SistemaSeguranca && errors.SistemaSeguranca}
              error={Boolean(
                touched.SistemaSeguranca && errors.SistemaSeguranca
              )}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Banheiro"
              name="Banheiro"
              value={values.Banheiro}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.Banheiro && errors.Banheiro}
              error={Boolean(touched.Banheiro && errors.Banheiro)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="normal"
              variant="filled"
              fullWidth
              label="Andar"
              name="Andar"
              value={values.Andar}
              onBlur={handleBlur}
              disabled={isSubmitting}
              onChange={handleChange}
              helperText={touched.Andar && errors.Andar}
              error={Boolean(touched.Andar && errors.Andar)}
            />
          </Grid>
        </>
      )}

      {["Residencial", "Rural"].includes(tipoImovelSelected?.Grupo) && (
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Quantidade Andares"
            name="QuantidadeAndares"
            value={values.QuantidadeAndares}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.QuantidadeAndares && errors.QuantidadeAndares}
            error={Boolean(
              touched.QuantidadeAndares && errors.QuantidadeAndares
            )}
          />
        </Grid>
      )}
    </React.Fragment>
  );
}
