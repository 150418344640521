import React from "react";
import { Grid, TextField, Typography } from "@mui/material";

export default function Galpao({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting
}) {
  return (
    <React.Fragment>
      <Grid item md={12}>
        <Typography variant="h4">Estrutura</Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Vão Livre"
          name="VaoLivre"
          value={values.VaoLivre}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.VaoLivre && errors.VaoLivre}
          error={Boolean(touched.VaoLivre && errors.VaoLivre)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Força Trifásica"
          name="ForcaTrifasica"
          value={values.ForcaTrifasica}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.ForcaTrifasica && errors.ForcaTrifasica}
          error={Boolean(touched.ForcaTrifasica && errors.ForcaTrifasica)}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          margin="normal"
          variant="filled"
          fullWidth
          label="Plataforma"
          name="Plataforma"
          value={values.Plataforma}
          onBlur={handleBlur}
          disabled={isSubmitting}
          onChange={handleChange}
          helperText={touched.Plataforma && errors.Plataforma}
          error={Boolean(touched.Plataforma && errors.Plataforma)}
        />
      </Grid>
    </React.Fragment>
  );
}
