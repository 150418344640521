import { Box, Button, Chip, Typography } from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";
import TableComponent from "../../../components/TableComponent";
import { useThemeColor } from "../../../hooks/useThemeColor";
import OwnerCreate from "./OwnerCreate";
import { useOwner } from "../../../hooks/pages/useOwner";
import ModalComponent from "../../../components/ModalComponent";

export default function OwnerList({ imovelId }) {
  const { colors } = useThemeColor();
  const {
    results,
    open,
    handleOpen,
    owner,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete,
    percent
  } = useOwner({ imovelId });

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Chip icon={<PercentIcon />} label={`Porcentagem total: ${percent}`} />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px"
          }}
          onClick={() => handleOpen(true)}
        >
          Adicionar
        </Button>
      </Box>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results || []}
          head={["ID", "NOME", "CONTATO", "CELULAR", "% PART", "RESPONSÁVEL"]}
          columns={[
            "Id",
            "pessoa.Nome",
            "pessoa.Contato",
            "pessoa.Celular",
            "percentual_format",
            "responsabilidade_format"
          ]}
          handleDelete={item => handleShow(item)}
          handleEdit={item => handleEdit(item)}
        />
      </Box>

      {open && (
        <OwnerCreate
          imovelId={imovelId}
          open={open}
          owner={owner}
          setOpen={() => handleOpen(false)}
          getList={getList}
        />
      )}

      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          title="Deletar Registro"
          maxWidth={"sm"}
          handleOpen={handleClose}
          handleClick={handleDelete}
          buttonOkColor="redAccent"
        >
          <Typography color="textPrimary" variant="h4">
            Tem certeza que deseja excluir? Esta ação não poderá ser revertida.
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
}
