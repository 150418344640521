import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { useUserProfile } from "../useUserProfile";
import { errorsMessage, maskHex } from "../../helpers";
import { api } from "../../services/api";

export function useConfigSiteForm() {
  const [imgInstitucionalUrl, setImgInstitucionalUrl] = useState(null);
  const [logoMarcaUrl, setLogoMarcaUrl] = useState(null);
  const [favIconUrl, setFavIconUrl] = useState(null);
  const [logoRodapeUrl, setLogoRodapeUrl] = useState(null);

  const {
    user: {
      contratante: { config_site }
    },
    getUser
  } = useUserProfile();

  useEffect(() => {
    if (config_site?.img_institucional_url) {
      setImgInstitucionalUrl(config_site?.img_institucional_url || null);
    }
    if (config_site?.logo_topo_url) {
      setLogoMarcaUrl(config_site?.logo_topo_url || null);
    }
    if (config_site?.favicon_url) {
      setFavIconUrl(config_site?.favicon_url || null);
    }
    if (config_site?.logo_rodape_url) {
      setLogoRodapeUrl(config_site?.logo_rodape_url || null);
    }
  }, [
    config_site?.img_institucional_url,
    config_site?.logo_topo_url,
    config_site?.favicon_url,
    config_site?.logo_rodape_url
  ]);

  const initialValues = {
    id: config_site?.Id || "",
    Dominio: config_site?.Dominio || "",
    Tema: config_site?.Tema || "",
    Titulo: config_site?.Titulo || "",
    NomeContratante: config_site?.NomeContratante || "",
    TelefoneTopo: config_site?.TelefoneTopo || "",
    TelefoneRodape: config_site?.TelefoneRodape || "",
    WhatsApp: config_site?.WhatsApp || "",
    TopoSite: config_site?.TopoSite || "",
    EmailSite: config_site?.EmailSite || "",
    EmailReceptor: config_site?.EmailReceptor || "",
    EnderecoSite: config_site?.EnderecoSite || "",
    Atendimento: config_site?.Atendimento || "",
    Instagram: config_site?.Instagram || "",
    Facebook: config_site?.Facebook || "",
    YouTube: config_site?.YouTube || "",
    Linkedin: config_site?.Linkedin || "",
    Telegram: config_site?.Telegram || "",
    CRECI: config_site?.CRECI || "",
    LocalizacaoSite: config_site?.LocalizacaoSite || "",
    BgMenu: config_site?.BgMenu || "",
    BgRodape: config_site?.BgRodape || "",
    BgPrimaria: config_site?.BgPrimaria || "",
    BgSecundaria: config_site?.BgSecundaria || "",
    CorFontePrimaria: config_site?.CorFontePrimaria || "",
    CorFonteSecundaria: config_site?.CorFonteSecundaria || "",
    Institucional: config_site?.Institucional || "",
    AdmImoveis: config_site?.AdmImoveis || "",
    Servicos: config_site?.Servicos || "",
    MetaDescription: config_site?.MetaDescription || "",
    MetaKeyWords: config_site?.MetaKeyWords || "",

    // Imagens
    imgInstitucional: config_site?.imgInstitucional || "",
    FavIcon: config_site?.FavIcon || "",
    LogoTopo: config_site?.LogoTopo || "",
    LogoRodape: config_site?.LogoRodape || "",

    MetaTag: config_site?.MetaTag || "",
    BgButton: config_site?.BgButton || "",
    BgSubMenu: config_site?.BgSubMenu || "",
    CorFonte: config_site?.CorFonte || "",
    CorFonteSubTitulo: config_site?.CorFonteSubTitulo || "",
    CorFonteTitulo: config_site?.CorFonteTitulo || "",
    CorFonteTopo: config_site?.CorFonteTopo || "",
    CorFonteRodape: config_site?.CorFonteRodape || "",
    CorLink: config_site?.CorLink || "",
    CorLinkMenu: config_site?.CorLinkMenu || "",
    PlanoFundo: config_site?.PlanoFundo || "",
    DestaqueVenda: config_site?.DestaqueVenda || "0",
    DestaqueLocacao: config_site?.DestaqueLocacao || "0",
    DestaqueVenLoc: config_site?.DestaqueVenLoc || "0",
    DestaqueTemporada: config_site?.DestaqueTemporada || "0",
    QtdeBanner: config_site?.QtdeBanner || "0",
    QtdeOferta: config_site?.QtdeOferta || "0",
    QtdeBairro: config_site?.QtdeBairro || "0",
    QtdeDestaque: config_site?.QtdeDestaque || "0",
    QtdeRedeSocial: config_site?.QtdeRedeSocial || "0",

    PathImagens: config_site?.PathImagens || ""
  };

  const validationSchema = Yup.object().shape({});

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      setSubmitting(true);

      const {
        id,
        imgInstitucional,
        FavIcon,
        LogoTopo,
        LogoRodape,
        ...formData
      } = values;
      formData["_method"] = "PUT";

      if (
        values.imgInstitucional &&
        values.imgInstitucional instanceof File &&
        imgInstitucional
      ) {
        formData.imgInstitucional = imgInstitucional;
      }

      if (values.FavIcon && values.FavIcon instanceof File && FavIcon) {
        formData.FavIcon = FavIcon;
      }

      if (values.LogoTopo && values.LogoTopo instanceof File && LogoTopo) {
        formData.LogoTopo = LogoTopo;
      }

      if (
        values.LogoRodape &&
        values.LogoRodape instanceof File &&
        LogoRodape
      ) {
        formData.LogoRodape = LogoRodape;
      }

      await api.post(`/configsite/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      setStatus({ success: true });
      setSubmitting(false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleColor = ({ target }) => {
    if (target.value.length > 0 && target.value.length <= 7) {
      return maskHex(target.value);
    }
  };

  const handleImage = (files, field, setValues) => {
    if (files.length > 0) {
      setValues(field, files[0], false);
      const imgFileUrl = URL.createObjectURL(files[0]);

      // eslint-disable-next-line default-case
      switch (field) {
        case "imgInstitucional":
          setImgInstitucionalUrl(imgFileUrl);
          break;
        case "FavIcon":
          setFavIconUrl(imgFileUrl);
          break;
        case "LogoTopo":
          setLogoMarcaUrl(imgFileUrl);
          break;
        case "LogoRodape":
          setLogoRodapeUrl(imgFileUrl);
          break;
      }
    }
  };

  const removeImage = async (field, setValues) => {
    try {
      setValues(field, false, false);

      await api.put(`/configsite/${config_site?.Id}/update-image`, {
        [field]: null
      });

      getUser();

      enqueueSnackbar("Imagem removida com sucesso!", {
        variant: "success"
      });

      // eslint-disable-next-line default-case
      switch (field) {
        case "imgInstitucional":
          setImgInstitucionalUrl(null);
          break;
        case "FavIcon":
          setFavIconUrl(null);
          break;
        case "LogoTopo":
          setLogoMarcaUrl(null);
          break;
        case "LogoRodape":
          setLogoRodapeUrl(null);
          break;
      }
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    handleColor,
    handleImage,
    imgInstitucionalUrl,
    logoMarcaUrl,
    logoRodapeUrl,
    favIconUrl,
    removeImage
  };
}
