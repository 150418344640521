import { Box, Card } from "@mui/material";
import Header from "../../components/Header";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../../components/TableComponent";
import SimpleFilterComponent from "../../components/SimpleFilterComponent";
import { useMidia } from "../../hooks/pages/useMidia";
import useUserStore from "../../store/userStore";
import PaginationComponent from "../../components/PaginationComponent";

export default function MidiaList() {
  const { colors } = useThemeColor();
  const { permissions } = useUserStore(state => state);
  const { results, term, setTerm, handleSearch, getList, handleSend } =
    useMidia();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Midia")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="Midias" subtitle="Bem vindo a lista de midias" />
      <Card
        sx={{
          backgroundColor: colors.primary[400]
        }}
      >
        <SimpleFilterComponent
          onFilter={permission && permission?.Consultar ? handleSearch : false}
          search={term}
          setSearch={setTerm}
        />
      </Card>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results?.data || []}
          head={["ID", "NOME", "DOWNLOAD"]}
          columns={["Id", "portal.Nome", "xml_url"]}
          columnDonwload={"xml_url"}
          handleSend={
            permission && permission?.Atualizar
              ? item => handleSend(item)
              : false
          }
          titleSend={"Processar"}
        />

        {results?.total > 0 && (
          <PaginationComponent
            count={results.total}
            page={results.current_page}
            handleLimit={getList}
            handlePage={getList}
          />
        )}
      </Box>
    </Box>
  );
}
