import { useState } from "react";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";
import useLoadingStore from "../../store/loading";

export function usePhotoForm({ handleClose, getPhotos, imovelId }) {
  const { loadingUpload, setLoadingUpload } = useLoadingStore(state => state);
  const [progress, setProgress] = useState(0);

  const initialValues = {
    images: []
  };

  const validationSchema = Yup.object().shape({
    images: Yup.array()
  });

  const onSubmit = async () => {};

  const handleLogo = async (files, field, setValues) => {
    try {
      setLoadingUpload(true);

      const config = {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: event => {
          let progress = Math.round((event.loaded * 100) / event.total);

          setProgress(progress);
        }
      };

      let formData = new FormData();

      for (const key of Object.keys(files)) {
        formData.append("fotos[]", files[key]);
      }

      await api.post(`/imoveis/fotos/${imovelId}/save-fotos`, formData, config);

      setLoadingUpload(false);

      enqueueSnackbar("Fotos salvas com sucesso!", {
        variant: "success"
      });

      handleClose();
      getPhotos();
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingUpload(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    handleLogo,
    loadingUpload,
    progress
  };
}
