import React from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { Box, Typography, Avatar } from "@mui/material";
import imageFile from "./imageFile.svg";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../TableComponent";

const FilesDropzone = ({
  className,
  title,
  subtitle,
  description,
  files,
  field,
  setFieldValue,
  setFiles,
  multiple,
  avatar,
  img,
  preview,
  onRemoveSingleFile,
  ...rest
}) => {
  const { colors } = useThemeColor();
  const handleRemoveSingle = file => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles ? setFiles(newFiles) : setFieldValue(field, newFiles);
    onRemoveSingleFile && onRemoveSingleFile();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles ? setFiles(acceptedFiles) : setFieldValue(field, acceptedFiles);
    },
    multiple
  });

  return (
    <div {...rest}>
      <div
        style={{
          border: "2px dashed #888",
          padding: "20px",
          outline: "none",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          backgroundColor: colors.grey[600],
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            cursor: "pointer"
          }
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {avatar ? (
          img ? (
            <Avatar style={{ width: "120px", height: "120px" }} src={img} />
          ) : (
            <Avatar style={{ width: "120px", height: "120px" }} />
          )
        ) : img ? (
          <img
            alt="Select file"
            style={{
              width: "250px",
              marginRight: "10px",
              marginBottom: "15px"
            }}
            src={img}
          />
        ) : (
          <img
            alt="Select file"
            style={{
              width: "100px",
              marginRight: "10px",
              marginBottom: "15px"
            }}
            src={imageFile}
          />
        )}
        <div style={{ textAlign: "center" }}>
          <Typography gutterBottom variant="h3">
            {title}
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              {subtitle}
            </Typography>
          </Box>
        </div>
      </div>

      {description && (
        <Typography color="textPrimary" variant="subtitle1">
          {description}
        </Typography>
      )}
      {preview && files.length > 0 && (
        <>
          <Box mt={2}>
            <TableComponent
              list={files || []}
              head={["IMAGEM", "NOME"]}
              columns={["image_url", "name"]}
              imageColumn="image_url"
              handleDelete={item => handleRemoveSingle(item)}
            />
          </Box>
        </>
      )}
    </div>
  );
};

FilesDropzone.defaultProps = {
  img: false,
  preview: false,
  files: [],
  field: "",
  title: "",
  subtitle: "",
  avatar: false,
  multiple: false,
  description: null
};

FilesDropzone.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  files: PropTypes.array,
  field: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
  setFiles: PropTypes.func,
  multiple: PropTypes.bool,
  avatar: PropTypes.bool,
  img: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  preview: PropTypes.bool,
  onRemoveSingleFile: PropTypes.func
};

export default FilesDropzone;
