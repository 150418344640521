import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import moment from "moment";
import { errorsMessage } from "../../helpers";
import { api } from "../../services/api";

export function usePeopleForm(props) {
  const navigate = useNavigate();
  const [people, setPeople] = useState({});
  const [estados, setEstados] = useState([]);
  const id = props?.id || null;

  const getItem = async id => {
    try {
      const { data } = await api.get(`/pessoas/${id}`);

      setPeople(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getEstados = async () => {
    try {
      const { data } = await api.get("/estados", {
        params: {
          paginate: 0
        }
      });

      setEstados(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getEstados();
  }, []);

  useEffect(() => {
    if (id) {
      getItem(id);
    }
  }, [id]);

  const initialValues = {
    id: people?.Id || "",
    IdPessoa: people?.IdPessoa || "",
    Nome: people?.Nome || "",
    CNPJ: people?.CNPJ || "",
    IE: people?.IE || "",
    IdEstado: people?.IdEstado || "",
    TipoEndereco: people?.TipoEndereco || "",
    Endereco: people?.Endereco || "",
    Numero: people?.Numero || "",
    Complemento: people?.Complemento || "",
    Bairro: people?.Bairro || "",
    Cidade: people?.Cidade || "",
    Cep: people?.Cep || "",
    Contato: people?.Contato || "",
    Email: people?.Email || "",
    TelRes: people?.TelRes || "",
    TelCom: people?.TelCom || "",
    Celular: people?.Celular || "",
    Profissao: people?.Profissao || "",
    Observacao: people?.Observacao || "",
    DataNascimento: people?.DataNascimento
      ? moment(people?.DataNascimento).format("YYYY-MM-DD")
      : "",
    IdSituacao: people?.IdSituacao || "1"
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Este campo é obrigatório"),
    IdSituacao: Yup.string().required("Este campo é obrigatório"),
    Celular: Yup.string().required("Este campo é obrigatório")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id ? `/pessoas/${id}` : `/pessoas`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      navigate(`/pessoas`);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleCep = async (values, setValues) => {
    try {
      const cep = values.Cep.replace(/\D/g, "");

      if (cep.length < 8) {
        enqueueSnackbar("Por favor informe o cep corretamente", {
          variant: "error"
        });
        return;
      }

      const { data } = await api.get(`/endereco/cep?cep=${cep}`);

      setValues("Endereco", data?.logradouro, false);
      setValues("Cidade", data?.localidade, false);
      setValues("Bairro", data?.bairro, false);
      setValues("TipoEndereco", data?.type, false);
      setValues("IdEstado", data?.IdEstado, false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    estados,
    handleCep
  };
}
