import {
  Button,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Box
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useThemeColor } from "../../../hooks/useThemeColor";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function UserFilter({
  search,
  setSearch,
  onFilter,
  filiais,
  perfis,
  onAdd,
  IdFilial,
  setIdFilial,
  IdPerfil,
  setIdPerfil
}) {
  const { colors } = useThemeColor();

  const handleChange = event => {
    event.persist();
    setSearch(event.target.value);
  };

  return (
    <Grid container spacing={3} style={{ padding: 16 }}>
      <Grid item md={6} sm={12}>
        <TextField
          value={search}
          onChange={handleChange}
          placeholder={"Pesquisar"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onKeyUp={event => {
            if (event.key === "Enter") {
              onFilter();
            }
          }}
        />
      </Grid>
      {filiais?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Filial"
            labelId={"IdFilial"}
            name={"IdFilial"}
            value={IdFilial}
            onChange={event => setIdFilial(event.target.value)}
            options={filiais}
            optionTitle="NomeFantasia"
            classHelp={{ paddingTop: 0 }}
          />
        </Grid>
      )}
      {perfis?.length > 0 && (
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Perfil"
            labelId={"IdPerfil"}
            name={"IdPerfil"}
            value={IdPerfil}
            onChange={event => setIdPerfil(event.target.value)}
            options={perfis}
            optionTitle="Nome"
            classHelp={{ paddingTop: 0 }}
          />
        </Grid>
      )}
      <Grid item md={12} sm={12}>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          {onFilter && (
            <Button
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={onFilter}
            >
              Pesquisar
            </Button>
          )}

          {onAdd && (
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={onAdd}
            >
              Adicionar
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
