import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function usePortalForm(props) {
  const navigate = useNavigate();
  const [portal, setPortal] = useState({});
  const id = props?.id || null;

  const getItem = async id => {
    try {
      const { data } = await api.get(`/portais/${id}`);

      setPortal(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    if (id) {
      getItem(id);
    }
  }, [id]);

  const initialValues = {
    id: portal?.Id || "",
    Nome: portal?.Nome || "",
    HomePage: portal?.HomePage || "",
    emailComercial: portal?.emailComercial || "",
    emailSuporte: portal?.emailSuporte || "",
    Contato: portal?.Contato || "",
    telefone: portal?.telefone || "",
    Mensagem: portal?.Mensagem || "",
    Observacao: portal?.Observacao || "",
    IdSituacao: portal?.IdSituacao || "1",
    Banner: portal?.Banner || false,
    Oferta: portal?.Oferta || false,
    Destaque: portal?.Destaque || false,
    SuperDestaque: portal?.SuperDestaque || false,
    Site: portal?.Site || false
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Este campo é obrigatório"),
    HomePage: Yup.string().required("Este campo é obrigatório"),
    IdSituacao: Yup.string().required("Este campo é obrigatório"),
    Banner: Yup.bool(),
    Oferta: Yup.bool(),
    Destaque: Yup.bool(),
    SuperDestaque: Yup.bool(),
    Site: Yup.bool()
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      const path = id ? `/portais/${id}` : `/portais`;
      const method = id ? "put" : "post";

      await api[method](path, formData);

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      navigate("/portais");
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
