import { useEffect, useState } from "react";
import { mockContracts } from "../../data/mocks/data";
import { sleep } from "../../helpers";
import useLoadingStore from "../../store/loading";

export function useContract() {
  const [results, setResults] = useState();
  const [term, setTerm] = useState("");
  const { setLoadingTable } = useLoadingStore(state => state);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async () => {
    try {
      setLoadingTable(true);

      await sleep(500);

      if (term?.length > 0) {
        setResults(mockContracts.filter(i => i.Nome.includes(term)));
      } else {
        setResults(mockContracts);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingTable(false);
    }
  };

  const handleSearch = () => {
    getList();
  };

  return {
    results,
    term,
    setTerm,
    handleSearch
  };
}
