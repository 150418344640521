import { Box, FormHelperText, IconButton, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import ModalComponent from "../../../components/ModalComponent";
import { useOwnerForm } from "../../../hooks/pages/useOwnerForm";
import ReactSelectAsync from "../../../components/ReactSelectAsync";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function OwnerCreate({
  open,
  setOpen,
  owner,
  imovelId,
  getList
}) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    onChangeSpeaker,
    handleSelectSpeaker,
    selected
  } = useOwnerForm({
    owner,
    imovelId,
    getProprietarios: getList,
    handleClose: setOpen
  });

  console.log(owner);

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form id="form-owner">
              <ModalComponent
                open={open}
                title="Dados do Proprietário"
                maxWidth={"sm"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-owner"
              >
                {selected?.length === 0 && (
                  <>
                    <ReactSelectAsync
                      handleChange={item =>
                        handleSelectSpeaker(item, setFieldValue)
                      }
                      handleInputChange={onChangeSpeaker}
                      placeholder={"Pesquisar proprietário"}
                      value={selected}
                    />
                    {errors?.IdPessoa && (
                      <FormHelperText style={{ color: "red" }}>
                        {errors?.IdPessoa}
                      </FormHelperText>
                    )}
                  </>
                )}

                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Nome Proprietário"
                  name="Nome"
                  value={values.Nome}
                  onBlur={handleBlur}
                  disabled={selected?.length > 0}
                  onChange={handleChange}
                  helperText={touched.Nome && errors.Nome}
                  error={Boolean(touched.Nome && errors.Nome)}
                  InputProps={{
                    endAdornment: (
                      <>
                        {selected?.length > 0 && !owner?.Id ? (
                          <IconButton
                            onClick={() =>
                              handleSelectSpeaker(null, setFieldValue)
                            }
                          >
                            <CloseIcon
                              sx={{
                                cursor: "pointer"
                              }}
                            />
                          </IconButton>
                        ) : null}
                      </>
                    )
                  }}
                />

                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Contato"
                  name="Contato"
                  value={values.Contato}
                  onBlur={handleBlur}
                  disabled={selected?.length > 0}
                  onChange={handleChange}
                  helperText={touched.Contato && errors.Contato}
                  error={Boolean(touched.Contato && errors.Contato)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Tel. Residêncial"
                  name="TelRes"
                  value={values.TelRes}
                  onBlur={handleBlur}
                  disabled={selected?.length > 0}
                  onChange={handleChange}
                  helperText={touched.TelRes && errors.TelRes}
                  error={Boolean(touched.TelRes && errors.TelRes)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Tel. Comercial"
                  name="TelCom"
                  value={values.TelCom}
                  onBlur={handleBlur}
                  disabled={selected?.length > 0}
                  onChange={handleChange}
                  helperText={touched.TelCom && errors.TelCom}
                  error={Boolean(touched.TelCom && errors.TelCom)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Celular"
                  name="Celular"
                  value={values.Celular}
                  onBlur={handleBlur}
                  disabled={selected?.length > 0}
                  onChange={handleChange}
                  helperText={touched.Celular && errors.Celular}
                  error={Boolean(touched.Celular && errors.Celular)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="E-mail"
                  name="Email"
                  type="email"
                  value={values.Email}
                  onBlur={handleBlur}
                  disabled={selected?.length > 0}
                  onChange={handleChange}
                  helperText={touched.Email && errors.Email}
                  error={Boolean(touched.Email && errors.Email)}
                />
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Percentual"
                  name="Percentual"
                  type="numeric"
                  value={values.Percentual}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Percentual && errors.Percentual}
                  error={Boolean(touched.Percentual && errors.Percentual)}
                />
                <SelectInputSimple
                  label="Participação"
                  labelId={"Responsabilidade"}
                  name={"Responsabilidade"}
                  value={values.Responsabilidade}
                  onChange={handleChange}
                  helperText={
                    touched.Responsabilidade && errors.Responsabilidade
                  }
                  error={Boolean(
                    touched.Responsabilidade && errors.Responsabilidade
                  )}
                  options={[
                    { Id: "1", Nome: "Titular" },
                    { Id: "2", Nome: "Proprietario" }
                  ]}
                />
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
