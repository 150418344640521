import { create } from "zustand";

const useSidebarStore = create(set => ({
  visible: true,
  setVisible: status => {
    set({ visible: status });
  }
}));

export default useSidebarStore;
