import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useEnterpriseForm } from "../../hooks/pages/useEnterpriseForm";
import EnterpriseFormData from "./_partials/EnterpriseFormData";
import useUserStore from "../../store/userStore";

export default function EnterpriseCreate() {
  const { permissions } = useUserStore(state => state);
  const {
    initialValues,
    validationSchema,
    onSubmit,
    handleValueChange,
    estados,
    handleCep
  } = useEnterpriseForm();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Empreendimento")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Empreendimento"
          subtitle="Bem vindo ao criar empreendimento"
        />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <EnterpriseFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                handleValueChange={handleValueChange}
                permission={permission}
                estados={estados}
                handleCep={handleCep}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
