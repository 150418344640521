import { Form } from "formik";
import { Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useThemeColor } from "../../../hooks/useThemeColor";
import FilesDropzone from "../../../components/FilesDropezone";
import SelectInputSimple from "../../../components/SelectInputSimple";

export default function ConfigSiteFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldValue,
  handleColor,
  handleImage,
  imgInstitucionalUrl,
  logoMarcaUrl,
  logoRodapeUrl,
  favIconUrl,
  removeImage
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-config-site">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          <Button
            sx={{
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px"
            }}
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Domínio"
            name="Dominio"
            value={values.Dominio}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Explicação: O nome que seu cliente irá digitar para encontrar seu site Exemplo: suaimobiliaria.com.br => Não digitar www e não pode haver espaço ou caractéres * () -"
            }
            error={Boolean(touched.Dominio && errors.Dominio)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectInputSimple
            label="Tema"
            labelId={"Tema"}
            name={"Tema"}
            value={values.Tema}
            onChange={handleChange}
            helperText={touched.Tema && errors.Tema}
            error={Boolean(touched.Tema && errors.Tema)}
            options={[
              { Id: "01", Nome: "Tema 1" },
              { Id: "02", Nome: "Tema 2" },
              { Id: "03", Nome: "Tema 3" },
              { Id: "04", Nome: "Tema 4" }
            ]}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Título"
            name="Titulo"
            value={values.Titulo}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Exemplo: Quando acessar o seu site aparecerá no navegador: Sua Imobiliária"
            }
            error={Boolean(touched.Titulo && errors.Titulo)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Nome Imobiliária"
            name="NomeContratante"
            value={values.NomeContratante}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Exemplo: Aparecerá nos formulários de emails enviados através do web site: Sua Imobiliária"
            }
            error={Boolean(touched.NomeContratante && errors.NomeContratante)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone Topo Web Site"
            name="TelefoneTopo"
            value={values.TelefoneTopo}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={"Exemplo: 11 2951-3203 | 11 2949-9161"}
            error={Boolean(touched.TelefoneTopo && errors.TelefoneTopo)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone Rodapé Web site"
            name="TelefoneRodape"
            value={values.TelefoneRodape}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={"Exemplo: 11 2951-3203 <br> 11 2949-9161"}
            error={Boolean(touched.TelefoneRodape && errors.TelefoneRodape)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="WhatsApp"
            name="WhatsApp"
            value={values.WhatsApp}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Exemplo: 5511295132031 => Não pode haver espaço ou caractéres () -"
            }
            error={Boolean(touched.WhatsApp && errors.WhatsApp)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Topo Site"
            name="TopoSite"
            value={values.TopoSite}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Exemplo: suporte@sgimais.com.br => Não pode haver espaço ou caractéres () -"
            }
            error={Boolean(touched.TopoSite && errors.TopoSite)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            type="email"
            label="E-mail"
            name="EmailSite"
            value={values.EmailSite}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Exemplo: suporte@sgimais.com.br => Não pode haver espaço ou caractéres () -"
            }
            error={Boolean(touched.EmailSite && errors.EmailSite)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            type="email"
            label="Email Receptor:"
            name="EmailReceptor"
            value={values.EmailReceptor}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Explicação: Email que receberá seus contatos recebidos atraves do web site"
            }
            error={Boolean(touched.EmailReceptor && errors.EmailReceptor)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Endereço Rodapé"
            name="EnderecoSite"
            value={values.EnderecoSite}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Exemplo: Av. Alberto Byington, 1239 <br> Vila Maria, São Paulo - SP <br> 02127-001"
            }
            error={Boolean(touched.EnderecoSite && errors.EnderecoSite)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Horário de atendimento"
            name="Atendimento"
            value={values.Atendimento}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Exemplo: Segunda a sexta-feira das 9:00 às 18:00, aos sábados com hora marcada."
            }
            error={Boolean(touched.Atendimento && errors.Atendimento)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Instagram"
            name="Instagram"
            value={values.Instagram}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={"Exemplo: https://www.instagram.com/sgimais/"}
            error={Boolean(touched.Instagram && errors.Instagram)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Facebook"
            name="Facebook"
            value={values.Facebook}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={"Exemplo: https://www.facebook.com/SGIMAIS/"}
            error={Boolean(touched.Facebook && errors.Facebook)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="YouTube"
            name="YouTube"
            value={values.YouTube}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={"Exemplo: https://www.youtube.com/seucanal"}
            error={Boolean(touched.YouTube && errors.YouTube)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Linkedin"
            name="Linkedin"
            value={values.Linkedin}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={"Exemplo: https://br.linkedin.com/seuperfil"}
            error={Boolean(touched.Linkedin && errors.Linkedin)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Telegram"
            name="Telegram"
            value={values.Telegram}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={"Exemplo: https://br.linkedin.com/seuperfil"}
            error={Boolean(touched.Telegram && errors.Telegram)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="CRECI"
            name="CRECI"
            value={values.CRECI}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.CRECI && errors.CRECI}
            error={Boolean(touched.CRECI && errors.CRECI)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            label="Localização"
            name="LocalizacaoSite"
            value={values.LocalizacaoSite}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={"Exemplo: Veja o passo a passo em nossos tutoriais."}
            error={Boolean(touched.LocalizacaoSite && errors.LocalizacaoSite)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel style={{ color: colors.grey[100] }} id={"Institucional"}>
            Institucional
          </InputLabel>
          <CKEditor
            id="Institucional"
            name="Institucional"
            editor={ClassicEditor}
            data={values.Institucional}
            onReady={editor => editor.setData(values.Institucional)}
            onChange={(event, editor) =>
              setFieldValue("Institucional", editor.getData())
            }
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="h5">Imagem Institucional</Typography>
          <FilesDropzone
            field={"imgInstitucional"}
            img={imgInstitucionalUrl || false}
            setFiles={event =>
              handleImage(event, "imgInstitucional", setFieldValue)
            }
          />
          {values.imgInstitucional && (
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={() => removeImage("imgInstitucional", setFieldValue)}
            >
              Remover Imagem
            </Button>
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel style={{ color: colors.grey[100] }} id={"AdmImoveis"}>
            Administração
          </InputLabel>
          <CKEditor
            id="AdmImoveis"
            name="AdmImoveis"
            editor={ClassicEditor}
            data={values.AdmImoveis}
            onReady={editor => editor.setData(values.AdmImoveis)}
            onChange={(event, editor) =>
              setFieldValue("AdmImoveis", editor.getData())
            }
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel style={{ color: colors.grey[100] }} id={"Servicos"}>
            Serviços
          </InputLabel>
          <CKEditor
            id="Servicos"
            name="Servicos"
            editor={ClassicEditor}
            data={values.Servicos}
            onReady={editor => editor.setData(values.Servicos)}
            onChange={(event, editor) =>
              setFieldValue("Servicos", editor.getData())
            }
          />
        </Grid>
        <Grid item md={12}>
          <Typography variant="h4">CEO Orgânico</Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Meta Description"
            name="MetaDescription"
            value={values.MetaDescription}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Explicação: Breve descrição ou diferencial da sua imobiliária que trabalharão ao seu favor em buscas orgânicas no Google Exemplo: Imobiliária com imóveis de alto padrão em Grande São Paulo => Não pode haver espaço ou caractéres * () -"
            }
            error={Boolean(touched.MetaDescription && errors.MetaDescription)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Meta KeyWords"
            name="MetaKeyWords"
            value={values.MetaKeyWords}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Explicação: palavras chaves que trabalharão ao seu favor em buscas orgânicas no Google Exemplo: casas de alto padrão em são paulo, imóveis residenciais para venda mooca => Não pode haver espaço ou caractéres * () -"
            }
            error={Boolean(touched.MetaKeyWords && errors.MetaKeyWords)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="h5">FavIcon</Typography>
          <FilesDropzone
            field={"FavIcon"}
            img={favIconUrl || false}
            setFiles={event => handleImage(event, "FavIcon", setFieldValue)}
          />
          {values.FavIcon && (
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={() => removeImage("FavIcon", setFieldValue)}
            >
              Remover Imagem
            </Button>
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="h5">Logomarca Topo</Typography>
          <FilesDropzone
            field={"LogoTopo"}
            img={logoMarcaUrl || false}
            setFiles={event => handleImage(event, "LogoTopo", setFieldValue)}
          />
          {values.LogoTopo && (
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={() => removeImage("LogoTopo", setFieldValue)}
            >
              Remover Imagem
            </Button>
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="h5">Logomarca Rodapé</Typography>
          <FilesDropzone
            field={"LogoRodape"}
            img={logoRodapeUrl || false}
            setFiles={event => handleImage(event, "LogoRodape", setFieldValue)}
          />
          {values.LogoRodape && (
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px"
              }}
              onClick={() => removeImage("LogoRodape", setFieldValue)}
            >
              Remover Imagem
            </Button>
          )}
        </Grid>
        <Grid item md={12}>
          <Typography variant="h4">Cores</Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cor de Fundo Primária"
            name="BgPrimaria"
            value={values.BgPrimaria}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={e => setFieldValue("BgPrimaria", handleColor(e))}
            helperText={touched.BgPrimaria && errors.BgPrimaria}
            error={Boolean(touched.BgPrimaria && errors.BgPrimaria)}
            InputProps={{
              readOnly: true
            }}
          />
          <input
            type="color"
            value={values.BgPrimaria}
            onChange={e => setFieldValue("BgPrimaria", handleColor(e))}
            style={{
              width: "66px",
              height: "56px",
              background: "#fff"
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cor de Fundo Secundária"
            name="BgSecundaria"
            value={values.BgSecundaria}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={e => setFieldValue("BgSecundaria", handleColor(e))}
            helperText={touched.BgSecundaria && errors.BgSecundaria}
            error={Boolean(touched.BgSecundaria && errors.BgSecundaria)}
            InputProps={{
              readOnly: true
            }}
          />
          <input
            type="color"
            value={values.BgSecundaria}
            onChange={e => setFieldValue("BgSecundaria", handleColor(e))}
            style={{
              width: "66px",
              height: "56px",
              background: "#fff"
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cor de Fonte Primária"
            name="CorFontePrimaria"
            value={values.CorFontePrimaria}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={e => setFieldValue("CorFontePrimaria", handleColor(e))}
            helperText={touched.CorFontePrimaria && errors.CorFontePrimaria}
            error={Boolean(touched.CorFontePrimaria && errors.CorFontePrimaria)}
            InputProps={{
              readOnly: true
            }}
          />
          <input
            type="color"
            value={values.CorFontePrimaria}
            onChange={e => setFieldValue("CorFontePrimaria", handleColor(e))}
            style={{
              width: "66px",
              height: "56px",
              background: "#fff"
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Cor de Fonte Secundária"
            name="CorFonteSecundaria"
            value={values.CorFonteSecundaria}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={e => setFieldValue("CorFonteSecundaria", handleColor(e))}
            helperText={touched.CorFonteSecundaria && errors.CorFonteSecundaria}
            error={Boolean(
              touched.CorFonteSecundaria && errors.CorFonteSecundaria
            )}
            InputProps={{
              readOnly: true
            }}
          />
          <input
            type="color"
            value={values.CorFonteSecundaria}
            onChange={e => setFieldValue("CorFonteSecundaria", handleColor(e))}
            style={{
              width: "66px",
              height: "56px",
              background: "#fff"
            }}
          />
        </Grid>
        <Grid item md={12}>
          <Typography variant="h4">Avisos</Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Título do Aviso"
            name="CorFonteSubTitulo"
            value={values.CorFonteSubTitulo}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Explicação: Informe o título do aviso que ficará na home do site"
            }
            error={Boolean(
              touched.CorFonteSubTitulo && errors.CorFonteSubTitulo
            )}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Texto do Aviso"
            name="CorFonteTitulo"
            value={values.CorFonteTitulo}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={
              "Explicação: Informe o texto do aviso que ficará na home do site"
            }
            error={Boolean(touched.CorFonteTitulo && errors.CorFonteTitulo)}
          />
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          <Button
            sx={{
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px"
            }}
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
