import { useNavigate } from "react-router-dom";
import { Box, Card, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../../components/TableComponent";
import SimpleFilterComponent from "../../components/SimpleFilterComponent";
import { usePortal } from "../../hooks/pages/usePortal";
import PaginationComponent from "../../components/PaginationComponent";
import ModalComponent from "../../components/ModalComponent";
import useUserStore from "../../store/userStore";

export default function PortalList() {
  const { colors } = useThemeColor();
  const { permissions } = useUserStore(state => state);
  const navigate = useNavigate();
  const {
    results,
    term,
    setTerm,
    handleSearch,
    handleShow,
    modalOpen,
    handleClose,
    handleDelete,
    getList
  } = usePortal();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Portal")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="Portais" subtitle="Bem vindo a lista de portais" />
      <Card
        sx={{
          backgroundColor: colors.primary[400]
        }}
      >
        <SimpleFilterComponent
          onFilter={permission && permission?.Consultar ? handleSearch : false}
          search={term}
          setSearch={setTerm}
          onAdd={
            permission && permission?.Inserir
              ? () => navigate("/portais/create")
              : false
          }
        />
      </Card>
      <Box backgroundColor={colors.primary[400]} m="40px 0 0 0" height="75vh">
        <TableComponent
          list={results?.data || []}
          head={["ID", "NOME", "SUPORTE", "TELEFONE"]}
          columns={["Id", "Nome", "emailSuporte", "telefone"]}
          handleDelete={
            permission && permission?.Excluir ? item => handleShow(item) : false
          }
          handleEdit={
            permission && permission?.Atualizar
              ? ({ Id }) => navigate(`/portais/edit/${Id}`)
              : false
          }
          handleShow={({ Id }) => navigate(`/portais/edit/${Id}`)}
        />

        {results?.total > 0 && (
          <PaginationComponent
            count={results.total}
            page={results.current_page}
            handleLimit={getList}
            handlePage={getList}
          />
        )}
      </Box>

      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          title="Deletar Registro"
          maxWidth={"sm"}
          handleOpen={handleClose}
          handleClick={handleDelete}
          buttonOkColor="redAccent"
        >
          <Typography color="textPrimary" variant="h4">
            Tem certeza que deseja excluir? Esta ação não poderá ser revertida.
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
}
