import { Box, LinearProgress, Typography } from "@mui/material";

function LinearProgressWithLabel(props) {
  return (
    <>
      <Box textAlign={"center"}>
        <Typography variant="h3" color="secondary">
          {props.title || ""}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", marginRight: 1 }}>
          <LinearProgress color="secondary" variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    </>
  );
}

export default LinearProgressWithLabel;
