import { useEffect } from "react";
import { useFormikContext } from "formik";
import { getFieldErrorNames } from "../../helpers";

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" }
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    element.scrollIntoView(scrollBehavior);
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
