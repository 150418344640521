import { Form } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField
} from "@mui/material";
import { useThemeColor } from "../../../hooks/useThemeColor";
import SelectInputSimple from "../../../components/SelectInputSimple";
import TextFieldMask from "../../../components/TextFieldMask";

export default function PortalFormData({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  isSubmitting,
  permission,
  id
}) {
  const { colors } = useThemeColor();

  return (
    <Form style={{ paddingBottom: "5rem" }} id="form-portal">
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Box display={"flex"} gap={2}>
            <FormControl component="fieldset">
              <FormGroup style={{ flexDirection: "row" }}>
                <FormControlLabel
                  label={"Banner"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Banner"
                      onChange={handleChange}
                      checked={Boolean(values.Banner)}
                    />
                  }
                />
                <FormControlLabel
                  label={"Oferta"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Oferta"
                      onChange={handleChange}
                      checked={Boolean(values.Oferta)}
                    />
                  }
                />
                <FormControlLabel
                  label={"Destaque"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Destaque"
                      onChange={handleChange}
                      checked={Boolean(values.Destaque)}
                    />
                  }
                />
                <FormControlLabel
                  label={"Super Destaque"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="SuperDestaque"
                      onChange={handleChange}
                      checked={Boolean(values.SuperDestaque)}
                    />
                  }
                />
                <FormControlLabel
                  label={"Site de Imobiliária"}
                  control={
                    <Checkbox
                      style={{ color: colors.grey[100] }}
                      name="Site"
                      onChange={handleChange}
                      checked={Boolean(values.Site)}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Nome"
            name="Nome"
            value={values.Nome}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Nome && errors.Nome}
            error={Boolean(touched.Nome && errors.Nome)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Website"
            name="HomePage"
            value={values.HomePage}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.HomePage && errors.HomePage}
            error={Boolean(touched.HomePage && errors.HomePage)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="E-mail comercial"
            name="emailComercial"
            type="email"
            value={values.emailComercial}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.emailComercial && errors.emailComercial}
            error={Boolean(touched.emailComercial && errors.emailComercial)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="E-mail suporte"
            name="emailSuporte"
            type="email"
            value={values.emailSuporte}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.emailSuporte && errors.emailSuporte}
            error={Boolean(touched.emailSuporte && errors.emailSuporte)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            fullWidth
            label="Contato"
            name="Contato"
            value={values.Contato}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Contato && errors.Contato}
            error={Boolean(touched.Contato && errors.Contato)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 9999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone"
            name="telefone"
            value={values.telefone}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.telefone && errors.telefone}
            error={Boolean(touched.telefone && errors.telefone)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextFieldMask
            mask={"(99) 99999-9999"}
            margin="normal"
            variant="filled"
            fullWidth
            label="Telefone Mensagem"
            name="Mensagem"
            value={values.Mensagem}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.Mensagem && errors.Mensagem}
            error={Boolean(touched.Mensagem && errors.Mensagem)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SelectInputSimple
            label="Situação"
            labelId={"IdSituacao"}
            name={"IdSituacao"}
            value={values.IdSituacao}
            onChange={handleChange}
            helperText={touched.IdSituacao && errors.IdSituacao}
            error={Boolean(touched.IdSituacao && errors.IdSituacao)}
            options={[
              { Id: "1", Nome: "Ativo" },
              { Id: "2", Nome: "Inativo" },
              { Id: "3", Nome: "Suspenso" }
            ]}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            margin="normal"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            label="Observação"
            name="Observacao"
            value={values.Observacao}
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            helperText={touched.Observacao && errors.Observacao}
            error={Boolean(touched.Observacao && errors.Observacao)}
          />
        </Grid>
        <Grid container item md={12} xs={12} justifyContent="flex-end">
          {!id ? (
            <>
              {permission && permission?.Inserir === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          ) : (
            <>
              {permission && permission?.Atualizar === 1 && (
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px"
                  }}
                  type="submit"
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
