import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { Box, Button, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useEnterpriseForm } from "../../hooks/pages/useEnterpriseForm";
import EnterpriseFormData from "./_partials/EnterpriseFormData";
import useUserStore from "../../store/userStore";
import UnitCreate from "../Units/UnitCreate";
import { useThemeColor } from "../../hooks/useThemeColor";
import TableComponent from "../../components/TableComponent";
import { useUnit } from "../../hooks/pages/useUnit";
import ModalComponent from "../../components/ModalComponent";

export default function EnterpriseEdit() {
  const { permissions } = useUserStore(state => state);
  const { colors } = useThemeColor();
  const { id } = useParams();
  const {
    enterprise,
    initialValues,
    validationSchema,
    onSubmit,
    handleValueChange,
    estados,
    handleCep,
    getItem
  } = useEnterpriseForm({
    id
  });

  const {
    unit,
    open,
    handleOpen,
    handleShow,
    handleEdit,
    modalOpen,
    handleClose,
    handleDelete
  } = useUnit();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Empreendimento")[0] ?? null;

  const permissionUnit =
    permissions?.filter(p => p.pagina?.Nome === "Unidade")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Editar empreendimento"
          subtitle="Bem vindo ao editar empreendimento"
        />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <Grid container spacing={0}>
                <Grid item md={12}>
                  <EnterpriseFormData
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    handleValueChange={handleValueChange}
                    permission={permission}
                    id={id}
                    estados={estados}
                    handleCep={handleCep}
                  />
                </Grid>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Header title="Unidades do empreendimento" />
                </Box>
                <Grid item md={12}>
                  {permissionUnit && permissionUnit?.Inserir && (
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        marginBottom: "20px"
                      }}
                      onClick={() => handleOpen(true)}
                    >
                      Adicionar
                    </Button>
                  )}

                  {open && (
                    <UnitCreate
                      open={open}
                      unit={
                        Object.keys(unit).length > 0
                          ? unit
                          : { IdEmpreendimento: id }
                      }
                      setOpen={() => handleOpen(false)}
                      getList={() => getItem(id)}
                    />
                  )}

                  {permissionUnit && permissionUnit?.Consultar && (
                    <TableComponent
                      list={enterprise?.unidades || []}
                      head={["ID", "NOME"]}
                      columns={["Id", "Nome"]}
                      handleEdit={
                        permissionUnit && permissionUnit?.Atualizar
                          ? item => handleEdit(item)
                          : false
                      }
                      handleShow={item => handleEdit(item)}
                      handleDelete={
                        permissionUnit && permissionUnit?.Excluir
                          ? item => handleShow(item)
                          : false
                      }
                    />
                  )}

                  {modalOpen && (
                    <ModalComponent
                      open={modalOpen}
                      title="Deletar Registro"
                      maxWidth={"sm"}
                      handleOpen={handleClose}
                      handleClick={() => {
                        handleDelete();
                        setTimeout(() => getItem(id), 400);
                      }}
                      buttonOkColor="redAccent"
                    >
                      <Typography color="textPrimary" variant="h4">
                        Tem certeza que deseja excluir? Esta ação não poderá ser
                        revertida.
                      </Typography>
                    </ModalComponent>
                  )}
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
