import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import moment from "moment";
import { errorsMessage, formatCurrency, parseValue } from "../../helpers";
import { api } from "../../services/api";

export function useEnterpriseForm(props) {
  const navigate = useNavigate();
  const [enterprise, setEnterprise] = useState({});
  const [estados, setEstados] = useState([]);
  const id = props?.id || null;

  const getItem = async id => {
    try {
      const { data } = await api.get(`/empreendimentos/${id}`);

      setEnterprise(data?.data || {});
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  const getEstados = async () => {
    try {
      const { data } = await api.get("/estados", {
        params: {
          paginate: 0
        }
      });

      setEstados(data?.data || []);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  useEffect(() => {
    getEstados();
  }, []);

  useEffect(() => {
    if (id) {
      getItem(id);
    }
  }, [id]);

  const initialValues = {
    id: enterprise?.Id || "",
    Nome: enterprise?.Nome || "",
    Administradora: enterprise?.Administradora || "",
    Tipo: enterprise?.Tipo || "",
    Plantao: enterprise?.Plantao || false,
    Publicar: enterprise?.Publicar || false,
    Andares: enterprise?.Andares || "",
    AnoConstrucao: enterprise?.AnoConstrucao || "",
    UnidadesAndar: enterprise?.UnidadesAndar || "",
    Paisagismo: enterprise?.Paisagismo || "",
    Arquitetura: enterprise?.Arquitetura || "",
    Site: enterprise?.Site || "",
    ValorMin: enterprise?.ValorMin
      ? formatCurrency(enterprise?.ValorMin || 0)
      : "",
    ValorMax: enterprise?.ValorMax
      ? formatCurrency(enterprise?.ValorMax || 0)
      : "",
    IdFase: enterprise?.IdFase || "",
    Construtora: enterprise?.Construtora || "",
    Incorporadora: enterprise?.Incorporadora || "",
    IdEstado: enterprise?.IdEstado || "",
    TipoEndereco: enterprise?.TipoEndereco || "",
    Endereco: enterprise?.Endereco || "",
    Numero: enterprise?.Numero || "",
    Complemento: enterprise?.Complemento || "",
    Bairro: enterprise?.Bairro || "",
    Cidade: enterprise?.Cidade || "",
    Cep: enterprise?.Cep || "",
    Contato: enterprise?.Contato || "",
    Email: enterprise?.Email || "",
    Telefone: enterprise?.Telefone || "",
    Mensagem: enterprise?.Mensagem || "",
    Observacao: enterprise?.Observacao || "",
    Profissao: enterprise?.Profissao || "",
    DataEntrega: enterprise?.DataEntrega
      ? moment(enterprise?.DataEntrega).format("YYYY-MM-DD")
      : "",
    IdStatus: enterprise?.IdStatus || "1"
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Este campo é obrigatório"),
    Administradora: Yup.string(),
    Tipo: Yup.string(),
    Plantao: Yup.string(),
    Publicar: Yup.string(),
    Andares: Yup.string(),
    AnoConstrucao: Yup.string(),
    UnidadesAndar: Yup.string(),
    Paisagismo: Yup.string(),
    Arquitetura: Yup.string(),
    Site: Yup.string(),
    ValorMin: Yup.string(),
    ValorMax: Yup.string(),
    IdFase: Yup.string(),
    Construtora: Yup.string(),
    Incorporadora: Yup.string(),
    IdEstado: Yup.string(),
    TipoEndereco: Yup.string(),
    Endereco: Yup.string(),
    Numero: Yup.string(),
    Bairro: Yup.string(),
    Cidade: Yup.string(),
    Cep: Yup.string(),
    Contato: Yup.string(),
    Email: Yup.string(),
    Telefone: Yup.string(),
    Mensagem: Yup.string(),
    Observacao: Yup.string(),
    Profissao: Yup.string(),
    DataEntrega: Yup.date(),
    IdStatus: Yup.string()
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;
      formData.ValorMin = parseValue(formData.ValorMin);
      formData.ValorMax = parseValue(formData.ValorMax);

      const path = id ? `/empreendimentos/${id}` : `/empreendimentos`;
      const method = id ? "put" : "post";

      const { data } = await api[method](path, formData);

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);

      navigate("/empreendimentos/edit/" + data?.data?.Id);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleValueChange = (formattedValue, field, setValues) => {
    setValues(field, formattedValue, false);
  };

  const handleCep = async (values, setValues) => {
    try {
      const cep = values.Cep.replace(/\D/g, "");

      if (cep.length < 8) {
        enqueueSnackbar("Por favor informe o cep corretamente", {
          variant: "error"
        });
        return;
      }

      const { data } = await api.get(`/endereco/cep?cep=${cep}`);

      setValues("Endereco", data?.logradouro, false);
      setValues("Cidade", data?.localidade, false);
      setValues("Bairro", data?.bairro, false);
      setValues("TipoEndereco", data?.type, false);
      setValues("IdEstado", data?.IdEstado, false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    handleValueChange,
    handleCep,
    estados,
    enterprise,
    getItem
  };
}
