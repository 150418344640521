import { Navigate } from "react-router-dom";
import DefaultLayout from "../Layouts/DefaultLayout";
import { UserContextProvider } from "../contexts/UserContext";

export function PrivateRoute({ children }) {
  const authUser = JSON.parse(sessionStorage.getItem("authUser"));

  if (!authUser?.token) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <UserContextProvider>
      <DefaultLayout>{children}</DefaultLayout>
    </UserContextProvider>
  );
}
