import { create } from "zustand";

const initialState = {
  modalOpen: false
};

const useModalStore = create(set => ({
  ...initialState,
  showModal: () => {
    set({ modalOpen: true });
  },
  hideModal: () => {
    set({ modalOpen: false });
  },
  resetModal: () => {
    set({ ...initialState });
  }
}));

export default useModalStore;
