import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import ImovelFormData from "./Tabs/ImovelFormData";
import PublicityList from "./Tabs/PublicityList";
import PhotosList from "./Tabs/PhotosList";
import OwnerList from "./Tabs/OwnerList";
import StepperContainer from "./StepperContainer";
import useUserStore from "../../store/userStore";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function ImovelEdit() {
  const { permissions } = useUserStore(state => state);
  const [value, setValue] = React.useState(0);
  const { id } = useParams();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Imovel")[0] ?? null;

  const handleStep = newValue => {
    setValue(newValue);
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Editar imóvel" subtitle="Bem vindo ao editar imóvel" />
      </Box>
      <Box sx={{ width: "100%" }}>
        <StepperContainer value={value} handleStep={handleStep} imovelId={id} />
        <TabPanel value={value} index={0}>
          <ImovelFormData
            handleStep={handleStep}
            imovelId={id}
            permission={permission}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PublicityList imovelId={id} permission={permission} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OwnerList imovelId={id} permission={permission} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PhotosList imovelId={id} permission={permission} />
        </TabPanel>
      </Box>
    </Box>
  );
}
