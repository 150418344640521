import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";
import { useUserProfile } from "../../hooks/useUserProfile";

export function useUserProfileForm(props) {
  const { user } = useUserProfile();
  const [photo_url, setPhotoUrl] = useState(null);

  const initialValues = {
    id: user?.Id || "",
    Nome: user?.Nome || "",
    email: user?.email || "",
    current_password: "",
    password: "",
    password_confirmation: "",
    Foto: user?.Foto || false
  };

  const validationSchema = Yup.object().shape({
    Nome: Yup.string().required("Este campo é obrigatório"),
    email: Yup.string().required("Este campo é obrigatório"),
    current_password: Yup.string().min(6, "Informe ao menos 6 caracteres"),
    password: Yup.string().min(6, "Informe ao menos 6 caracteres").max(255),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "A confirmação da nova senha deve ser igual a nova senha"
    )
  });

  useEffect(() => {
    if (user?.foto_url) {
      setPhotoUrl(user?.foto_url);
    }
  }, [user?.foto_url]);

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const {
        id,
        Foto,
        password,
        current_password,
        password_confirmation,
        ...formData
      } = values;

      if (values.Foto && values.Foto instanceof File && Foto) {
        formData.Foto = Foto;
      }

      if (values.current_password.length > 0) {
        formData.current_password = current_password;
      }

      if (values.password.length > 0) {
        formData.password = password;
      }

      if (values.password_confirmation.length > 0) {
        formData.password_confirmation = password_confirmation;
      }

      await api.post(`/profile/update`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const handleImage = (files, field, setValues) => {
    if (files.length > 0) {
      setValues(field, files[0], false);
      setPhotoUrl(URL.createObjectURL(files[0]));
    }
  };

  const removeImage = (field, setValues) => {
    setValues(field, false, false);
    setPhotoUrl(null);
  };

  return {
    onSubmit,
    initialValues,
    validationSchema,
    handleImage,
    removeImage,
    photo_url
  };
}
