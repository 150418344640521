import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import moment from "moment";
import { sleep } from "../../helpers";
import { mockContracts } from "../../data/mocks/data";

export function useContractForm(props) {
  const [contract, setContract] = useState({});
  const id = props?.id || null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getItem = async id => {
    await sleep(500);

    // eslint-disable-next-line eqeqeq
    const data = mockContracts.find(i => i.Id == id);

    if (data) {
      setContract(data);
    }
  };

  useEffect(() => {
    if (id) {
      getItem(id);
    }
  }, [getItem, id]);

  const initialValues = {
    id: contract?.Id || "",
    idestado: contract?.IdEstado || "",
    tipoendereco: contract?.TipoEndereco || "",
    endereco: contract?.Endereco || "",
    numero: contract?.Numero || "",
    complemento: contract?.Complemento || "",
    bairro: contract?.Bairro || "",
    cidade: contract?.Cidade || "",
    cep: contract?.Cep || "",
    idsituacao: contract?.IdSituacao || "",
    datacadastro: contract?.DataCadastro
      ? moment(contract?.DataCadastro).format("YYYY-MM-DD")
      : "",
    datainicial: contract?.DataInicial
      ? moment(contract?.DataInicial).format("YYYY-MM-DD")
      : "",
    datafinal: contract?.DataFinal
      ? moment(contract?.DataFinal).format("YYYY-MM-DD")
      : "",
    dataultimoreajuste: contract?.DataUltimoReajuste
      ? moment(contract?.DataUltimoReajuste).format("YYYY-MM-DD")
      : "",
    dataentrada: contract?.DataEntrada
      ? moment(contract?.DataEntrada).format("YYYY-MM-DD")
      : "",
    dataassinatura: contract?.DataAssinatura
      ? moment(contract?.DataAssinatura).format("YYYY-MM-DD")
      : "",
    datadevolucaochaves: contract?.DataDevolucaoChaves
      ? moment(contract?.DataDevolucaoChaves).format("YYYY-MM-DD")
      : ""
  };

  const validationSchema = Yup.object().shape({
    idestado: Yup.string().required("Este campo é obrigatório"),
    tipoendereco: Yup.string().required("Este campo é obrigatório"),
    endereco: Yup.string().required("Este campo é obrigatório"),
    numero: Yup.string().required("Este campo é obrigatório"),
    bairro: Yup.string().required("Este campo é obrigatório"),
    cidade: Yup.string().required("Este campo é obrigatório"),
    cep: Yup.string().required("Este campo é obrigatório"),
    idsituacao: Yup.string().required("Este campo é obrigatório"),
    datacadastro: Yup.string().required("Este campo é obrigatório"),
    datainicial: Yup.string().required("Este campo é obrigatório"),
    datafinal: Yup.string().required("Este campo é obrigatório"),
    dataultimoreajuste: Yup.string().required("Este campo é obrigatório"),
    dataentrada: Yup.string().required("Este campo é obrigatório"),
    dataassinatura: Yup.string().required("Este campo é obrigatório"),
    datadevolucaochaves: Yup.string().required("Este campo é obrigatório")
  });

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      setSubmitting(true);

      const { id, ...formData } = values;

      console.log(id, formData);

      enqueueSnackbar("Dados salvos com sucesso!", {
        variant: "success"
      });
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
