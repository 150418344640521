import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { api } from "../../services/api";
import { errorsMessage } from "../../helpers";

export function useForgot() {
  const initialValues = {
    email: ""
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Informe seu e-mail")
  });

  const onSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    try {
      setSubmitting(true);

      const {
        data: { error, message }
      } = await api.post("/auth/forgot-password", { email: values.email });

      enqueueSnackbar(message, {
        variant: error ? "error" : "success"
      });
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    initialValues,
    validationSchema
  };
}
