import { Formik } from "formik";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useUserForm } from "../../hooks/pages/useUserForm";
import UserFormData from "./_partials/UserFormData";
import useUserStore from "../../store/userStore";

export default function UserCreate() {
  const { permissions } = useUserStore(state => state);
  const {
    initialValues,
    validationSchema,
    onSubmit,
    handleImage,
    removeImage,
    photo_url,
    perfis,
    filiais
  } = useUserForm();

  const permission =
    permissions?.filter(p => p.pagina?.Nome === "Usuario")[0] ?? null;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Usuário" subtitle="Bem vindo ao criar usuário" />
      </Box>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <UserFormData
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                handleImage={handleImage}
                removeImage={removeImage}
                setFieldValue={setFieldValue}
                photo_url={photo_url}
                perfis={perfis}
                filiais={filiais}
                permission={permission}
              />
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
