import { create } from "zustand";

const initialState = {
  permissions: []
};

const useUserStore = create(set => ({
  ...initialState,
  setPermissions: permissions => {
    set({ permissions });
  }
}));

export default useUserStore;
