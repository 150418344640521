import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { errorsMessage } from "../../helpers";
import useLoadingStore from "../../store/loading";
import useModalStore from "../../store/modalStore";
import { api } from "../../services/api";

export function useOwner(props) {
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState();
  const [percent, setPercent] = useState(0);
  const [owner, setOwner] = useState({});
  const { setLoadingTable } = useLoadingStore(state => state);
  const { modalOpen, showModal, hideModal } = useModalStore(state => state);
  const id = props?.imovelId || null;

  useEffect(() => {
    if (id) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getList = async () => {
    try {
      setLoadingTable(true);

      const { data } = await api.get(`/imoveis/${id}`);

      let imovelproprietarios = data?.data?.imovelproprietarios || [];

      setResults(imovelproprietarios);
      setPercent(
        imovelproprietarios?.reduce(
          (accumulator, currentValue) =>
            (accumulator += currentValue?.Percentual
              ? Number(currentValue?.Percentual)
              : 0),
          0
        )
      );
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    } finally {
      setLoadingTable(false);
    }
  };

  const handleOpen = (status = true) => {
    setOpen(status);
    if (!status) setOwner({});
  };

  const handleEdit = item => {
    setOwner(item);
    handleOpen();
  };

  const handleShow = item => {
    setOwner(item);
    showModal();
  };

  const handleClose = () => {
    setOwner(null);
    hideModal();
  };

  const handleDelete = async () => {
    try {
      await api.delete(
        `/imoveis/proprietarios/${id}/destroy-proprietario/${owner.Id}`
      );
      handleClose();
      getList();
      enqueueSnackbar("Registro deletado com sucesso", {
        variant: "success"
      });
    } catch (err) {
      errorsMessage(err, enqueueSnackbar);
    }
  };

  return {
    results,
    open,
    handleOpen,
    owner,
    handleEdit,
    getList,
    handleShow,
    modalOpen,
    hideModal,
    handleClose,
    handleDelete,
    percent
  };
}
