import { Navigate } from "react-router-dom";
import AuthLayout from "../Layouts/AuthLayout";

export function PublicRoute({ children }) {
  const authUser = JSON.parse(sessionStorage.getItem("authUser"));

  if (authUser && authUser?.token) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  return <AuthLayout>{children}</AuthLayout>;
}
