import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField
} from "@mui/material";
import { Form, Formik } from "formik";
import ModalComponent from "../../components/ModalComponent";
import { useProfileForm } from "../../hooks/pages/useProfileForm";
import { useThemeColor } from "../../hooks/useThemeColor";

export default function ProfileCreate({ open, setOpen, profile, getList }) {
  const { colors } = useThemeColor();
  const { initialValues, validationSchema, onSubmit, handleCheckbox, pages } =
    useProfileForm({
      profile,
      getProfiles: getList,
      handleClose: setOpen
    });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form id="form-profile">
              <ModalComponent
                open={open}
                title="Dados do Perfil"
                maxWidth={"lg"}
                handleOpen={setOpen}
                buttonOkType={"submit"}
                disabledClick={isSubmitting}
                buttonForm="form-profile"
              >
                <TextField
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label="Nome"
                  name="Nome"
                  value={values.Nome}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  helperText={touched.Nome && errors.Nome}
                  error={Boolean(touched.Nome && errors.Nome)}
                />

                <Box>
                  <FormControl component="fieldset">
                    <FormGroup style={{ flexDirection: "row" }}>
                      <FormControlLabel
                        label={"Admin"}
                        control={
                          <Checkbox
                            style={{ color: colors.grey[100] }}
                            name="Admin"
                            onChange={handleChange}
                            checked={Boolean(values.Admin)}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Box>

                <Grid container spacing={2}>
                  {pages.map(item => {
                    return (
                      <Grid item md={6} key={item.Id}>
                        <Card sx={{ padding: 0.5 }}>
                          <CardHeader title={`${item.Id} - ${item.Nome}`} />
                          <FormControl component="fieldset">
                            <FormGroup style={{ flexDirection: "row" }}>
                              <FormControlLabel
                                label={"Inserir"}
                                control={
                                  <Checkbox
                                    style={{ color: colors.grey[100] }}
                                    name="inserir"
                                    checked={values.profilePages.includes(
                                      String(`${item.Id}_inserir`)
                                    )}
                                    onChange={event =>
                                      handleCheckbox(
                                        event,
                                        values,
                                        "profilePages",
                                        setFieldValue
                                      )
                                    }
                                    value={`${item.Id}_inserir`}
                                  />
                                }
                              />
                              <FormControlLabel
                                label={"Atualizar"}
                                control={
                                  <Checkbox
                                    style={{ color: colors.grey[100] }}
                                    name="atualizar"
                                    checked={values.profilePages.includes(
                                      String(`${item.Id}_atualizar`)
                                    )}
                                    onChange={event =>
                                      handleCheckbox(
                                        event,
                                        values,
                                        "profilePages",
                                        setFieldValue
                                      )
                                    }
                                    value={`${item.Id}_atualizar`}
                                  />
                                }
                              />
                              <FormControlLabel
                                label={"Excluir"}
                                control={
                                  <Checkbox
                                    style={{ color: colors.grey[100] }}
                                    name="excluir"
                                    checked={values.profilePages.includes(
                                      String(`${item.Id}_excluir`)
                                    )}
                                    onChange={event =>
                                      handleCheckbox(
                                        event,
                                        values,
                                        "profilePages",
                                        setFieldValue
                                      )
                                    }
                                    value={`${item.Id}_excluir`}
                                  />
                                }
                              />
                              <FormControlLabel
                                label={"Consultar"}
                                control={
                                  <Checkbox
                                    style={{ color: colors.grey[100] }}
                                    name="consultar"
                                    checked={values.profilePages.includes(
                                      String(`${item.Id}_consultar`)
                                    )}
                                    onChange={event =>
                                      handleCheckbox(
                                        event,
                                        values,
                                        "profilePages",
                                        setFieldValue
                                      )
                                    }
                                    value={`${item.Id}_consultar`}
                                  />
                                }
                              />
                              <FormControlLabel
                                label={"Imprimir"}
                                control={
                                  <Checkbox
                                    style={{ color: colors.grey[100] }}
                                    name="imprimir"
                                    checked={values.profilePages.includes(
                                      String(`${item.Id}_imprimir`)
                                    )}
                                    onChange={event =>
                                      handleCheckbox(
                                        event,
                                        values,
                                        "profilePages",
                                        setFieldValue
                                      )
                                    }
                                    value={`${item.Id}_imprimir`}
                                  />
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </ModalComponent>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
